export default {
	pages: [
		{
			type: "page",
			subtitle: "We want to get to know you.",
			title: "What's your cooking skill level?",
			multiselect: false,
			buttons: [
				{ id: 0, label: "I'm a beginner", value: 1 },
				{ id: 1, label: "I have some experience", value: 3 },
				{ id: 2, label: "I'm a pro", value: 5 },
			],
		},
		{
			type: "page",
			subtitle: "We want to get to know you.",
			title: "How often do you cook?",
			multiselect: false,
			buttons: [
				{ id: 0, label: "Rarely or never", value: 0 },
				{ id: 1, label: "A few times a month", value: 0 },
				{ id: 2, label: "A few times a week", value: 0 },
			],
		},
		{
			type: "page",
			subtitle: "We want to get to know you.",
			title: "What type of Lodge cookware do you own?",
			description: "(Select all that apply.)",
			multiselect: true,
			buttons: [
				{ id: 0, label: "Cast Iron", value: 0 },
				{ id: 1, label: "Carbon Steel", value: 0 },
				{ id: 2, label: "Stoneware", value: 0 },
				{ id: 3, label: "Enameled Cast Iron", value: 0 },
				{ id: 4, label: "Heat-Treated Serveware", value: 0 },
				{ id: 5, label: "I don't own Lodge cookware", value: 0 },
			],
		},
		{
			type: "result",
			title: "Thank you for your responses!",
			description: "We recommend: ",
			data: {
				"result-1": [
					{
						type: "recipe",
						title: "Grilled Margherita Pizza",
						href: "/recipe/grilled-margherita-pizza",
						all: "/discover/recipes",
						image: "/themes/custom/tombras/images/quiz/pizza.jpg",
					},
					{
						type: "product",
						title: '12" Cast Iron Skillet',
						href: "/round-cast-iron-classic-skillet?sku=L10SK3",
						all: "/shop",
						image: "/themes/custom/tombras/images/quiz/12-skillet.jpg",
					},
				],
				"result-3": [
					{
						type: "recipe",
						title: "Roast Chicken with Spring Veggies",
						href: "/recipe/splayed-roast-chicken-with-spring-vegetables",
						all: "/discover/recipes",
						image: "/themes/custom/tombras/images/quiz/chicken.jpg",
					},
					{
						type: "product",
						title: "Chef Collection",
						href: "/shop?Collection=Chef%20Collection",
						all: "/shop",
						image: "/themes/custom/tombras/images/quiz/chef.jpg",
					},
				],
				"result-5": [
					{
						type: "recipe",
						title: "Winter Root Vegetable Chili",
						href: "/recipe/winter-root-vegetable-chili",
						all: "/discover/recipes",
						image: "/themes/custom/tombras/images/quiz/chili.jpg",
					},
					{
						type: "product",
						title: "Enameled Dutch Oven",
						href: "/enameled-dutch-oven",
						all: "/shop",
						image: "/themes/custom/tombras/images/quiz/dutch-oven.jpg",
					},
				],
			},
		},
	],
};

<template>
	<button-page :flexCard="false">
		<template slot="header">
			<span class="subtitle--line secondary">Cooking Type</span>
			<h1 class="h2">What can we help you find today?</h1>
			<p
				v-if="error"
				class="error"
			>Please select one of the choices.</p>
		</template>
		<template slot="buttons">
			<input
				type="radio"
				id="beginner"
				name="skill"
				v-model="value"
				value="basic"
			/>
			<label for="beginner">
				<strong>I'm in need of the basics.</strong>
				<p>
					My go-to pots and pans are looking a little worse for wear; it's time
					for some new everyday cookware.
				</p>
			</label>
			<input
				type="radio"
				id="intermediate"
				name="skill"
				v-model="value"
				value="intermediate"
			/>
			<label for="intermediate">
				<strong>I want to grow my collection.</strong>
				<p>
					I have a few pieces of cookware I love, and I'm ready to add more.
				</p>
			</label>
			<input
				type="radio"
				id="professional"
				name="skill"
				v-model="value"
				value="professional"
			/>
			<label for="professional">
				<strong>I want unique cookware items.</strong>
				<p>
					I have a well-stocked collection of cookware, but I'm always looking
					for new pieces.
				</p>
			</label>
		</template>
		<template slot="nextButton">
			<a
				:disabled="buttonDisabled"
				href="#product-wizard/flavor"
				@click="validate"
				class="btn btn--border btn--arrow btn--primary borderless"
			>
				Next
			</a>
		</template>
	</button-page>
</template>



<script>
import ButtonPage from "../ButtonPage.vue";
import { mapMutations, mapGetters, mapState } from "vuex";
import { keyboardAccessibility } from "../utils";
export default {
	components: {
		ButtonPage,
	},
	props: ["pageData", "pageNumber", "handleFocus", "usesKeyboard"],
	data() {
		return {
			error: false,
		};
	},
	computed: {
		...mapState("wizard", ["collection", "skill"]),
		// ...mapGetters({ getCollection: "wizard/getCollection" }),
		value: {
			set(collection) {
				if (collection) this.error = false;
				this.setCollection({ collection });
			},
			get() {
				return this.collection;
			},
		},
		buttonDisabled() {
			return !this.value;
		},
	},
	watch: {
		value(newVal, oldVal) {
			if (newVal !== oldVal) {
				console.log("Clear flavors");
				this.setFlavors({ flavors: [], type: "flavors" });
				this.setFlavors({ flavors: [], type: "flavorsB" });
				this.setFlavors({ flavors: [], type: "flavorsC" });
			} else {
				console.log("Don't clear flavors");
			}
		},
	},
	mounted() {
		if (!this.skill) {
			window.location.hash = "#product-wizard/skill";
		}
		this.setBackButtonHash({ hash: "#product-wizard/skill" });
		if (this.usesKeyboard) {
			this.handleFocus();
		}
	},
	methods: {
		...mapMutations({
			setBackButtonHash: "wizard/setBackButtonHash",
			setCollection: "wizard/setCollection",
			setFlavors: "wizard/setFlavors",
		}),
		validate($event) {
			if (!this.value) {
				$event.preventDefault();
				this.error = true;
				return;
			}
			this.error = false;
			keyboardAccessibility($event, this);
			// this.$emit("nextQuestion", { analytics: this.value });
		},
	},
};
</script>

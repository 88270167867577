import Vue from "vue";
import { createVariantKey } from "../../utils";

export default {
	setRecommendations(state, { data }) {
		Vue.set(state, "recommendations", data);
	},
	setAssociatedProducts(state, { sku, data }) {
		Vue.set(state.associatedProducts, sku, data);
	},
	setFreeProducts(state,  { data }) {
		console.log(data);
		Vue.set(state.freeProducts, "data" ,data);
	},
	setOptions(state, { options, type, colors }) {
		state.options = options;
		state.type = type;
		state.colors = colors;
	},
	setQuantity(state, { quantity }) {
		const q = parseInt(quantity, 10);
		if (!isNaN(q)) {
			state.quantity = q;
		}
	},
	setCurrentVariant(state, { sku }) {
		state.sku = sku;
	},
	setVariants(state, { products }) {
		state.products = products;
	},
	setSpecificOption(state, options = {}) {
		let selected = JSON.parse(JSON.stringify(state.selected));
		const keys = Object.keys(options);
		keys.forEach(key => {
			selected[key] = options[key];
		});

		const key = createVariantKey(selected);
		const doesItExist = state.products.filter(i => i.vue_key === key);
		if (!doesItExist.length) {
			// the option is something they're trying to select that doesn't exist,
			// so now we're going to loop through and find the first product that fits their match
			const partialKey = createVariantKey(options);
			// this _has_ to exist because otherwise where did the filter button come from...
			const product = state.products.filter(
				i => i.vue_key.indexOf(partialKey) >= 0,
			)[0];
			selected = product.attributes;
		}
		state.selected = selected;
	},
	setMasterInfo(state, masterInfo) {
		state.masterInfo = masterInfo;
	},
};

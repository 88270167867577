<template>
  <div class="multi-node">
    <div class="frow gutters justify-start"
         v-for="(w, i) in Math.ceil(nodes.length/nodesPerRow)"
    >
      <div class="node col-sm-1-2 mb-4"
           v-for="(nd, index) in nodes.slice(nodesPerRow * (w-1), nodesPerRow*(w-1)+nodesPerRow)">
        <component
            :key="nd.key"
            :step="steps[index + (i*nodesPerRow)]"
            :node="nd"
            :is="nd.type"
            :answerOnly="true"
            :required="nd.meta.required"
            :noFocus="i !== 0 || index !== 0"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import dataModel from "./productHelpDataModel";
import mixins from './mixins';
import { mapMutations, mapGetters, mapState } from 'vuex';
import Question from "./Question";
import DateInput from "./DateInput";
import TextInput from "./TextInput";

export default {
  name: "MultiNode",
  components: {
    Question,
    DateInput,
    TextInput,
  },
  mixins: [mixins],
  data() {
    return {
      dataModel,
      nodesPerRow: 2,
    };
  },
  props: [
    "step",
    "node",
  ],
  computed: {
    ...mapGetters({
      currentStep: "productHelp/getCurrentStep",
      answers: "productHelp/getAnswers",
    }),
    steps() {
      return this.nodes && this.nodes.map(n => {
        return n.key;
      });
    },
    nodes() {
      return this.node && this.node.nodes
          && this.node.nodes.map(n => {
            // handle conditional node
            if (typeof n === "object") {
              if (Object.keys(n.filters).every(key => {
                let answer = typeof this.answers[key] === "string"
                    ? this.answers[key]
                    : this.answers[key].value || this.answers[key].id;
                return n.filters[key].includes(answer);
              })) {
                return Object.assign(this.dataModel.nodes[n.node], {
                  key: n.node,
                });
              } else {
                return false;
              }
            } else {
              return Object.assign(this.dataModel.nodes[n], {
                key: n,
              });
            }
          })
              .filter(n => n)
              .sort((a, b) => {
                return a.meta.required === b.meta.required
                    ? 0
                    : a.meta.required
                        ? -1
                        : 1;
              });
    },
  },
  watch: {
    answers(answer) {
      // set the following step once all the questions have been answered
      console.log("currentAnswer updated", answer);
      let allAnswered = this.nodes
          .filter(node => node.meta.required)
          .every(node => {
            let id = node.key;
            return this.answers[id] && (this.answers[id].value || this.answers[id].text);
          });
      if (allAnswered) {
        console.log("multi-node done");
        this.setFollowingStep();
      } else {
        console.log("multi-node not done");
        this.unsetNextStep();
      }
    },
  },
  methods: {
    ...mapMutations({
      setFollowingStep: "productHelp/setFollowingStep",
      unsetNextStep: "productHelp/unsetNextStep",
    }),
  },
  created() {
  },
  mounted() {

  },
};
</script>

<style scoped>

</style>

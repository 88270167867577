<template>
	<button-page :flexCard="false">
		<template slot="header">
			<span class="subtitle--line secondary">Leftovers</span>
			<h1 class="h2">When you cook a meal, do you prefer to have leftovers?</h1>
			<p
				v-if="error"
				class="error"
			>
				Please select one of the options for leftovers.
			</p>
		</template>
		<template slot="buttons">
			<input
				type="radio"
				id="meal"
				name="meal"
				v-model="value"
				value="meal"
			/>
			<label for="meal">
				<strong>No</strong>
				<p>I prefer cooking just enough for one meal.</p>
			</label>
			<input
				type="radio"
				id="leftovers"
				name="leftovers"
				v-model="value"
				value="leftovers"
			/>
			<label for="leftovers">
				<strong>I like some leftovers</strong>
				<p>
					I prefer cooking a little extra to have leftovers for the next day.
				</p>
			</label>
			<input
				type="radio"
				id="prepping"
				name="prepping"
				v-model="value"
				value="prepping"
			/>
			<label for="prepping">
				<strong>I like meal prepping</strong>
				<p>
					I prefer cooking in large portions to have leftovers for several days
				</p>
			</label>
		</template>
		<template slot="nextButton">
			<a
				:disabled="disabledButton"
				href="#product-wizard/results"
				@click="validate"
				class="btn btn--border btn--arrow btn--primary borderless"
			>
				Next
			</a>
		</template>
	</button-page>
</template>

<script>
import ButtonPage from "../ButtonPage.vue";
import { mapMutations, mapGetters, mapState } from "vuex";
import { keyboardAccessibility } from "../utils";
export default {
	props: ["pageNumber", "handleFocus", "usesKeyboard"],
	components: {
		ButtonPage,
	},
	data() {
		return {
			error: false,
		};
	},
	computed: {
		...mapState("wizard", ["servings"]),
		...mapGetters({
			getLeftovers: "wizard/getLeftovers",
		}),
		value: {
			set(leftovers) {
				if (leftovers) this.error = false;
				this.setLeftovers({ leftovers });
			},
			get() {
				return this.getLeftovers;
			},
		},
		disabledButton() {
			return !this.value;
		},
	},
	methods: {
		...mapMutations({
			setLeftovers: "wizard/setLeftovers",
			setBackButtonHash: "wizard/setBackButtonHash",
		}),
		validate($event) {
			this.error = false;
			if (!this.value) {
				$event.preventDefault();
				this.error = true;
				return;
			}
			keyboardAccessibility($event, this);
			// this.$emit("nextQuestion", { analytics: this.value });
		},
	},
	mounted() {
		if (!this.servings) {
			window.location.hash = "#product-wizard/servings";
		}
		this.setBackButtonHash({ hash: "#product-wizard/servings" });
		if (this.usesKeyboard) {
			this.handleFocus();
		}
	},
};
</script>

<template>
  <div class="question">
    <node-wrap :node="node" :required="required">
      <div class="frow centered gutters col-xs-1-1">
        <div class="col-xs-1-1"
             :class="optionWidth"
             v-for="(option, index) in node.options"
             :key="`question-option-${index}`"
        >
          <div
              tabindex="0"
              :ref="`option-${index + 1}`"
              class="frow justify-start centered option"
              :class="{active: chosenIndex === index, 'mt-3': optionWidth === 'col-xs-1-1' && index !== 0}"
              @click="setChoice(option, index)"
              @keyup.enter.prevent="setChoice(option, index)"
          >
            <div v-if="option.description" class=" frow row-start">
              <div class="col-sm-1-3"><strong>{{ option.text }}</strong></div>
              <div class="col-sm-2-3"><p class="mb-0">{{ option.description }}</p></div>
            </div>
            <div v-else class="frow centered">
              <strong>{{ option.text }}</strong>
            </div>
          </div>
        </div>
      </div>
    </node-wrap>
  </div>
</template>

<script>
import dataModel from "./productHelpDataModel";
import mixins from './mixins';
import { mapMutations, mapGetters } from 'vuex';
import NodeWrap from './NodeWrap';

export default {
  name: "Question",
  components: {
    NodeWrap
  },
  mixins: [mixins],
  data() {
    return {
      dataModel,
      choice: {},
      chosenIndex: -1,
    };
  },
  props: [
    "step",
    "node",
    "answerOnly",
    "required",
    "noFocus"
  ],
  computed: {
    ...mapGetters({
    }),
    optionWidth() {
      if (this.node.options.length === 2 && !this.node.options.some(o => o.description)) {
        return "col-xs-1-2";
      } else {
        return "col-xs-1-1";
      }
    },
  },
  watch: {
    choice(answer) {
      if (Object.keys(answer).length === 0) {
        return;
      }
      if (this.node.meta && this.node.meta.addToNotes) {
        answer.addToNotes = this.node.meta.addToNotes;
      }
      // update gift status based on purchased from location
      if (this.step === "purchased-from") {
        this.setGift({
          gift: answer.value === "gift"
        });
      }
      this.setAnswer({
        id: this.step,
        answer,
      });

      if (this.node.meta && this.node.meta.remedy) {
        this.setRemedy({
          remedy: answer.value !== "no" ? answer.value : ""
        });
        this.setAnswer({
          id: "remedy",
          answer
        })
      }

      if (!this.answerOnly) {
        if (this.node.meta && this.node.meta["input-only"]) {
          this.setFollowingStep();
        } else {
          this.setNextStep({
            step: answer.value,
          });
        }
      }

    },
    node() {
      console.log("new node")
      this.choice = {};
      this.chosenIndex = -1;
    }
  },
  methods: {
    ...mapMutations({
      setAnswer: "productHelp/setAnswer",
      setRemedy: "productHelp/setRemedy",
      setGift: "productHelp/setGift",
      setNextStep: "productHelp/setNextStep",
      setFollowingStep: "productHelp/setFollowingStep",
    }),
    setChoice(option, index) {
      this.choice = option;
      this.chosenIndex = index;
    }
  },
  created() {
  },
  mounted() {
    if (!this.noFocus) {
      this.$refs["option-1"][0].focus();
    }
  },
};
</script>

<style scoped>

</style>

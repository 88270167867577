import Vue from "vue";
import Vuex from "vuex";
import VuexUndoRedo from './vuex-undo-redo';

// import likes from "./likes";
// import productDetail from "./product-detail/index.js";
// import productListing from "./product-listing/index.js";

Vue.use(Vuex);
Vue.use(VuexUndoRedo, {
	module: "productHelp",
	undoDelimiter: "setUndoPoint"
})
// export const store = new Vuex.Store({
// 	modules: {
// 		likes,
// 		productDetail,
// 		productListing,
// 	},
// });

import s from "../../../lodge-vue/src/store/index.ts";
export const store = s;
console.log("JS STORE");

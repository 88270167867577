<template>
  <div class="frow justify-between">
    <div class="label col-xs-1-1 col-sm-5-12">
      <p v-if="node.subtitle" class="subtitle--line brown">{{ node.subtitle }}</p>
      <h1 class="h2 mb-0" :class="{'form-required': required}">{{ node.label }}</h1>
      <p v-if="node.copy" v-html="node.copy"></p>
    </div>
    <div class="node-wrap col-xs-1-1 col-sm-6-12 frow">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "NodeWrap",
  props: [
    "node",
    "required",
  ],
};
</script>

<style scoped>

</style>

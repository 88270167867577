import BaseLodgeIntent from "../BaseLodgeIntent";
import { CLASSIFIER_TYPES } from "../classifierUtils";

class UnconfidentUser extends BaseLodgeIntent {
	constructor(options) {
		super(options);

		this.N_VIEWED_PRODUCTS = 5;

		this.viewedProductsCount = false;
		this.madePurchase = false;
		this.interactedWithCart = false;
	}

	classify(data) {
		// this intent can be reclassified over and over
		// so we don't want to assume anything
		console.log(data.interactedWithCart);
		this.viewedProductsCount =
			data.viewedProducts.length >= this.N_VIEWED_PRODUCTS;
		this.interactedWithCart = data.interactedWithCart;
		this.madePurchase = data.purchases.length > 0;
	}

	isMatch() {
		console.log(this);
		return (
			!this.interactedWithCart && !this.madePurchase && this.viewedProductsCount
		);
	}
}

export default new UnconfidentUser({
	prettyName: "UnconfidentUser",
	intentName: "USER_INTENT_4",
	subscribesTo: [
		CLASSIFIER_TYPES.PRODUCT_VIEW,
		CLASSIFIER_TYPES.ECOM_PURCHASE,
		CLASSIFIER_TYPES.ECOM_ADD_TO_CART,
	],
	negatedBy: [],
});

export default {
	setActivePage({ commit }, data) {
		commit("setActivePage", data);
	},
	setQuestionWithAnswers({ commit }, data) {
		commit("setQuestionWithAnswers", data);
	},
	setCollectedAnswers({ commit }, data) {
		commit("setCollectedAnswers", data);
	},
	setAnswerGroup({ commit }, data) {
		commit("setAnswerGroup", data);
	},
	setBackButton({ commit }, data) {
		commit("setBackButton", data);
	},
	restart({ commit }) {
		commit("restart");
	},
}
<template>
  <div class="frow reason">
<!--    <select ref="select" v-model="choice" :name="step" :id="step">-->
<!--      <option :value="{}">Please select one</option>-->
<!--      <option v-for="option in options"-->
<!--              :key="`step-${option.value}`"-->
<!--              :value="option">{{ option.text }}-->
<!--      </option>-->
<!--    </select>-->
    <div class="frow justify-start gutters">
      <div class="col-xs-1-1 col-sm-1-2"
           :class="{'mt-3': index !== 0 || index !== 1}"
           v-for="(choice, index) in options">
        <div class="option"
             :class="{active: chosenIndex === index}"
             @click="setChoice(choice, index)"
        >
          <div class="">
            <strong>{{ choice.text }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataModel from "./productHelpDataModel";
import mixins from './mixins';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: "Reason",
  mixins: [mixins],
  data() {
    return {
      dataModel,
      choice: {},
      chosenIndex: -1,
    };
  },
  props: [
    "step",
    "node",
  ],
  computed: {
    ...mapGetters({
      getAnswer: "productHelp/getAnswer",
      product: "productHelp/getIssueProduct",
    }),
    options() {
      return this.node.options.filter(o => {
        if (o.value === "parts-missing" && this.product.parts) {
          return true;
        } else if (o.value === "parts-missing") {
          return false;
        } else {
          return true;
        }
      });
    },
  },
  watch: {
    // choice(answer) {
    //   this.setAnswer({
    //     id: this.step,
    //     answer,
    //   });
    //   // TODO is this right? I think this is no longer used...
    //   this.setRemedy({
    //     remedy: "refund",
    //   });
    //   this.setAnswer({
    //     id: "remedy",
    //     answer: "refund",
    //   });
    //   this.setNextStep({
    //     step: answer.value,
    //   });
    // },
    // node() {
    //   console.log("new node");
    //   this.choice = {};
    // },
  },
  methods: {
    ...mapMutations({
      setAnswer: "productHelp/setAnswer",
      setRemedy: "productHelp/setRemedy",
      setNextStep: "productHelp/setNextStep",
      setFollowingStep: "productHelp/setFollowingStep",
    }),
    setChoice(choice, index) {
      this.choice = choice;
      this.chosenIndex = index;
    }
  },
  mounted() {
      this.choice = this.answers[this.step] || {};
      this.chosenIndex = -1;

  }
};
</script>

<style scoped>

</style>

<template>
	<div class="product-wizard--viewer">
		<div class="imagery">
			<span
				class="product-wizard--viewer--oos bg--primary font-family-roboto-slab"
				v-if="!available"
				>Currently out of stock</span
			>
			<img
				v-for="(image, idx) in images"
				:key="idx"
				:src="image"
				v-show="idx === active"
        :alt="`Image #${idx}`"
			/>
		</div>
		<div class="product-wizard--viewer-holder">
			<span
				v-for="(image, idx) in images"
				class="product-wizard--viewer-dot"
				:class="{ active: idx === active }"
				@click="updateActive(idx)"
				:key="idx"
			>
				<span class="circle"></span>
				<span class="sr-only">Switch to product image {{ idx }}</span>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	props: ["images", "available"],
	data() {
		return {
			active: 0,
		};
	},
	methods: {
		updateActive(idx) {
			console.log("update active", idx);
			this.active = idx;
		},
	},
};
</script>

import { SaveableModule } from "@/store/types";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const cookieDomainMatch = /(\w+\.\w+)$/g.exec(window.location.hostname);
const cookieDomain =
	cookieDomainMatch && cookieDomainMatch[1]
		? `.${cookieDomainMatch[1]}`
		: ".lodgecastiron.com";

console.log("cookie domain", cookieDomain);

interface PersistOptions {
	storage: any;
	key: string;

	[propName: string]: any;
}

class PersistentData {
	private options: PersistOptions;
	private storage: any;
	private key: string;

	constructor(options?: PersistOptions) {
		this.options = options || ({} as PersistOptions);
		this.storage = this.options.storage || (window && window.localStorage);
		this.key = this.options.key || "vuex";
		if (!this.canWriteStorage(this.storage)) {
			throw new Error("Invalid storage instance given");
		}
	}

	canWriteStorage(storage: any) {
		try {
			cookies.set("@@", 1, {
				secure: true
			});
			cookies.remove("@@",{
				secure: true
			});
			// storage.setItem('@@', 1);
			// storage.removeItem('@@');
			return true;
		} catch (e) {
			console.log("could not read/write to cookies");
		}

		return false;
	}

	/**
	 * Uses LZString to decompress cookies
	 * @param module
	 * @param key
	 * @param storage
	 * @param value
	 */
	getState(
		module: SaveableModule,
		key: string,
		storage: any,
		value?: string | undefined,
	) {
		const obj: { [propName: string]: any } = module.toObject();
		console.log("cookies getting", key, obj, Object.keys(obj));

		try {
			return Object.keys(obj).reduce(
				(o: { [propName: string]: any }, prop: string): object => {
					o[prop] = cookies.get(prop) || obj[prop];
					return o;
				},
				{} as { [propName: string]: any },
			);
		} catch (err) {
			console.log("cookies get Error getting state or parsing JSON of state");
		}
		return {};
	}

	/**
	 * Uses LZString to compress object strings and stores them to cookies
	 * @param module
	 * @param key
	 * @param storage
	 */
	setState(
		module: SaveableModule,
		key: string = this.key,
		storage: any = this.storage,
	) {
		console.log("writing cookies", key, module.toObject());
		const obj: { [propName: string]: any } = module.toObject();
		for (const prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				const value: string =
					typeof obj[prop] === "object" ? JSON.stringify(obj[prop]) : obj[prop];
				cookies.set(prop, value, {
					path: "/",
					domain: cookieDomain,
					expires: new Date(Date.now() + 60 * 60 * 24 * 365 * 10 * 1000), // now + 10 years
					secure: true
				});
			}
		}
		return true;
	}

	hydrate(module: SaveableModule) {
		const state = this.getState(module, this.key, this.storage);
		console.log("Hydrating cookies getting", state);

		// Overwrite parts of module's that
		// we saved
		const keys = Object.keys(state);
		keys.forEach(key => {
			(module as any)[key] = state[key];
		});
	}
}

export const persistentData = new PersistentData();

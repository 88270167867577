export default {
	// treewithlocationandconcernsfirst
	"tree": {
		common: {
			"submit": {
				node: "submit",
				children: {
					"no": {
						node: "start",
						end: true,
					},
				},
			},
			"text-photo": {
				node: "text-photo",
				children: "contact-address",
			},
			"contact-address": {
				node: "contact-address",
				children: "submit",
			},
			"credit-exchange": {
				node: "credit-exchange",
				children: {
					"credit": "text-photo",
					"exchange": "text-photo",
				},
			},
			"replace": {
				node: "replace",
				children: {
					"replace": "text-photo",
					"no": "text-photo",
				},
			},
			"refund": {
				node: "refund",
				children: {
					"refund": "text-photo",
					"no": "text-photo",
				},
			},
			"replace-refund": {
				node: "replace-refund",
				children: {
					"refund": "text-photo",
					"replacement": "text-photo",
				},
			},
			"replace-return": {
				node: "replace-return",
				children: {
					"refund": "text-photo",
					"replacement-parts": "text-photo",
				},
			},
			"lodge-reason": {
				node: "reason",
				children: {
					"wrong-product": "replace-refund",
					"not-wanted": "contact-address",
					"not-expected": {
						node: "how-so",
						children: "text-photo",
					},
					"parts-missing": {
						node: "shipping-box-damaged",
						children: {
							// NEW
							// LODGE same flow for both but need photos if box damaged
							// Amazon/Retailer
							// don't care about shipping box damage
							// submit - send them back to source
							"yes": {
								node: "parts-missing",
								children: "replace-return",
							},
							"no": {
								node: "parts-missing",
								children: {
									node: "replace-return",
									children: {
										"refund": "contact-address",
										"replacement-parts": "contact-address",
									},
								},
							},
						},
					},
					"missing-order": "replace-refund",
					"not-received": "text-photo",
					"noticeable-imperfection": "refund",
					"other": "text-photo",
				},
			},
			"retailer-reason": {
				node: "reason",
				children: {
					"wrong-product": "submit",

					// need to handle shipping box damage so we can show the right verbiage
					// Concern spreadsheet updated to show verbiage for
					// Amazon with damaged shipping box vs non
					// and retailer with damaged shipping box vs non
					"parts-missing": {
						node: "shipping-box-damaged",
						children: {
							"yes": "submit",
							"no": {
								node: "parts-missing",
								children: "contact-address"
							}
						}
					},
					"not-wanted": "submit",
					"not-expected": {
						node: "how-so",
						children: "text-photo",
					},
					"not-received": "submit",
					"missing-order": "submit",
					"noticeable-imperfection": "text-photo",
					"other": "text-photo"
				},
			},
			"damaged": {
				node: "damaged",
				children: {
					"yes": {
						node: "used",
						children: {
							"yes": {
								node: "dropped",
								children: {
									"yes": "submit",
									"no": {
										node: "used-follow-up-questions",
										children: "text-photo",
									},
								},
							},
							"no": {
								node: "product-order",
								children: {
									"which-product": {
										node: "purchased-from",
										children: {
											"website": {
												node: "shipping-box-damaged",
												children: {
													"yes": "replace-refund",
													"no": "replace-refund",
												},
											},
											"gift": "submit",
											"amazon": "submit",
											"retailer": "submit",
										},
									},
									"which-order": "replace-refund",
								},
							},
						},
					},
					"no": {
						node: "used",
						children: {
							// USED
							// LODGE/AMAZON/RETAILER - you have to buy it - submit
							// IF can't find part then send to CC for help
							// If can find part then give link to go buy part
							"yes": {
								node: "reason",
								children: {
									"parts-missing": {
										node: "parts-missing",
										children: "replace-return",
									},
									"other": {
										node: "used-follow-up-questions",
										children: "text-photo",
									},
								},
							},
							"no": {
								node: "purchased-from",
								children: {
									"website": "lodge-reason",
									// this needs to be handled with reason
									"gift": "retailer-reason",
									"amazon": "retailer-reason",
									"retailer": "retailer-reason",
								},
							},
						},
					},
				},
			},
			"select-concern": {
				node: "select-concern",
				children: {
					"still-need-help": {
						node: "still-need-help",
						children: {
							"yes": "damaged",
							"no": "submit",
						},
					},
					"missing-order": {
						node: "replace-refund",
						children: {
							"refund": "contact-address",
							"replace": "contact-address",
						},
					},
					"need-purchase-location": "purchased-from",
				},
			},
			"purchased-from": {
				node: "purchased-from",
				children: {
					"website": "damaged",
					"gift": "damaged",
					"amazon": "damaged",
					"retailer": "damaged",
				},
			},
			"request-parts": {
				node: "request-parts",
				children: {
					"yes": {
						node: "shipping-box-damaged",
						children: {
							"yes": "text-photo",
							"no": {
								node: "parts-missing",
								children: "contact-address",
							},
						},
					},
					"no": "submit",
				},
			},
		},
		children: {
			"start": {
				node: "start",
				children: {
					"product-order": {
						node: "product-order",
						children: {
							"which-product": {
								node: "which-product",
								children: {
									"used": {
										node: "used",
										children: {
											"yes": "select-concern",
											"no": "select-concern",
										},
									},
								},
							},
							"which-order": {
								node: "which-order",
								children: {
									"used": {
										node: "used",
										children: {
											"yes": "select-concern",
											"no": "select-concern",
										},
									},
								},
							},
						},
					},
				},
			},
		},
	},

	"nodes": {
		"start": {
			label: "Let's get started",
			type: "start",
		},
		"end-flow": {
			label: "Glad we could help!",
			type: "end",
		},
		"product-order": {
			subtitle: "Choose a path",
			label: "Do you have an issue with or need to return a product or order?",
			type: "question",
			meta: {
				"addToNotes": true,
				"autoContinue": true,
			},
			options: [
				{ value: "which-product", text: "Product", description: "Search for a product by name or SKU" },
				{ value: "which-order", text: "Order", description: "Choose a product from a previous order" },
			],
		},
		"which-product": {
			subtitle: "Find a product",
			label: "Which product are you having a problem with?",
			type: "product-finder",
			options: [],
		},
		"which-order": {
			subtitle: "Find an order",
			label: "Which order are you have a problem with?",
			type: "order-finder",
			options: [],
		},
		"purchased-from": {
			subtitle: "Choose retailer",
			label: "Where was the item purchased?",
			copy: "Note that we can't refund/replace items if they were not purchased from Lodge",
			type: "question",
			meta: {
				"addToNotes": false,
				"autoContinue": true,
			},
			options: [
				{ value: "website", text: "Lodge website" },
				{ value: "gift", text: "Gift or I don't know" },
				{ value: "amazon", text: "Amazon" },
				{ value: "retailer", text: "Another Retailer" },
			],
		},
		"request-parts": {
			label: "Would you like parts?",
			copy: "This screen will be dependent on whether parts are available for the selected product once we have that data in Commerce Cloud",
			type: "question",
			meta: {
				"autoContinue": true,
			},
			options: [
				{ value: "yes", text: "Yes" },
				{ value: "no", text: "No" },
			],
		},
		"text-photo": {
			label: "Please enter text and/or upload a photo",
			type: "photo-uploader",
			options: [],
		},
		"photo-uploader": {
			label: "Please enter text and/or upload a photo",
			type: "photo-uploader",
			options: [],
		},
		"damaged": {
			"label": "Is the product damaged?",
			"type": "question",
			"meta": {
				"autoContinue": true,
			},
			"options": [
				{ value: "yes", text: "Yes" },
				{ value: "no", text: "No" },
			],
		},
		"used": {
			"label": "Has the item been used?",
			"type": "question",
			"meta": {
				"autoContinue": true,
			},
			"options": [
				{ value: "yes", text: "Yes" },
				{ value: "no", text: "No" },
			],
		},
		"damage-follow-up-questions": {
			"label": "We need a bit more info:",
			"type": "multi-node",
			"nodes": [
				"when-purchased",
				"type-of-stove",
				"what-heat",
				"what-preheat",
			],
		},
		"used-follow-up-questions": {
			"label": "We need a bit more info:",
			"type": "multi-node",
			"meta": {
				filter: "material",
			},
			"nodes": [
				{
					node: "type-of-stove",
					filters: {
						"material": [
							"Seasoned Cast Iron",
							"Carbon Steel",
							"Enameled Cast Iron",
						],
					},
				},
				{
					node: "heat-source",
					meta: {
						required: true,
					},
					filters: {
						"material": [
							"Accessories",
						],
					},
				},
				// conditional node
				{
					node: "what-heat",
					required: true,
					filters: {
						"material": [
							"Seasoned Cast Iron",
							"Carbon Steel",
							"Enameled Cast Iron",
						],
					},
				},
				// conditional node
				{
					node: "what-heat-accessory",
					filters: {
						"material": [
							"Accessories",
						],
					},
				},

				// not required?
				{
					node: "what-issue-accessory",
					filters: {
						"material": [
							"Accessories",
						],
					},
				},
				// not required?
				{
					node: "how-used-accessory",
					filters: {
						"material": [
							"Accessories",
						],
					},
				},
				{
					node: "what-preheat",
					filters: {
						"material": [
							"Seasoned Cast Iron",
							"Carbon Steel",
							"Enameled Cast Iron",
						],
					},
				},
				"how-cleaned",
				"how-damaged",
			],
		},
		"when-purchased": {
			"label": "When was the item purchased",
			"type": "date-input",
			meta: {
				"input-only": true,
				"addToNotes": true,
				required: true,
			},
		},
		"heat-source": {
			"label": "What heat source is the item being used on?",
			"type": "question",
			meta: {
				"input-only": true,
				"addToNotes": true,
				required: true,
			},
			"options": [
				{ value: "gas", text: "Gas" },
				{ value: "electric", text: "Electric" },
				{ value: "induction", text: "Induction" },
				{ value: "charcoal", text: "Charcoal" },
			],
		},
		"type-of-stove": {
			"label": "What type of stove do you use?",
			"type": "question",
			meta: {
				"input-only": true,
				"addToNotes": true,
				required: true,
			},
			"options": [
				{ value: "gas", text: "Gas" },
				{ value: "electric", text: "Electric" },
				{ value: "induction", text: "Induction" },
			],
		},
		"what-heat": {
			"label": "Do you start on low, medium, or high when cooking on the stove top?",
			"type": "question",
			meta: {
				"input-only": true,
				"addToNotes": true,
				required: true,
			},
			"options": [
				{ value: "low", text: "Low" },
				{ value: "medium", text: "Medium" },
				{ value: "high", text: "High" },
			],
		},
		"what-heat-accessory": {
			"label": "What temperature setting do you cook with if using the accessory on the stovetop or in the oven?",
			"type": "text-input",
			meta: {
				"input-only": true,
				"addToNotes": true,
				required: true,
			},
		},
		"what-issue-accessory": {
			"label": "What issue are you having with the item?",
			"type": "text-input",
			meta: {
				"input-only": true,
				"addToNotes": true,
				required: false,
			},
		},
		"how-used-accessory": {
			"label": "How was the item used?",
			"type": "text-input",
			meta: {
				"input-only": true,
				"addToNotes": true,
				required: false,
			},
		},
		"what-preheat": {
			"label": "What do you typically put in the cookware to preheat it?",
			"type": "text-input",
			meta: {
				"input-only": true,
				"addToNotes": true,
				required: true,
			},
		},
		"how-cleaned": {
			"label": "How is the item cleaned and cared for?",
			"type": "text-input",
			meta: {
				"input-only": true,
				"addToNotes": true,
				required: true,
			},
		},
		"how-damaged": {
			"label": "How did the damage happen?",
			"type": "text-input",
			meta: {
				"input-only": true,
				"addToNotes": true,
				required: true,
			},
		},
		"dropped": {
			"label": "Was the item dropped?",
			"type": "question",
			meta: {
				// "input-only": true,
				"addToNotes": true,
				"autoContinue": true,
				filters: {
					"select-concern": [
						"Chipped",
						"Cracked",
						"Handle Broken",
						"Handle Broke",
					],
				},
				filteredAnswer: { value: "no", text: "No" },
			},
			"options": [
				{ value: "yes", text: "Yes" },
				{ value: "no", text: "No" },
			],
		},
		"gift": {
			"label": "Did you receive this item as a gift?",
			"type": "question",
			meta: {
				"autoContinue": true,
			},
			"options": [
				{ value: "yes", text: "Yes" },
				{ value: "no", text: "No" },
			],
		},
		"still-need-help": {
			"label": "Still need help?",
			"type": "question",
			"options": [
				{ value: "yes", text: "Yes" },
				{ value: "no", text: "No" },
			],
		},
		"want-cc-help": {
			"label": "Want customer care help or would like to return the item?",
			"type": "question",
			meta: {
				"autoContinue": true,
			},
			"options": [
				{ value: "yes", text: "Customer Care Help" },
				{ value: "no", text: "Return" },
			],
		},
		"common-concerns": {
			"label": "Common Concerns",
			"type": "information",
			"information": `<p>some <strong>html</strong> with common concerns</p>`,
		},
		"select-concern": {
			"subtitle": "Select an issue",
			"label": "Which issue are you experiencing?",
			"type": "select-concern",
			meta: {
				"input-only": true,
				"data": "material",
			},
		},
		"reason": {
			"label": "Reason for a refund",
			"type": "reason",
			"options": [
				{ value: "wrong-product", text: "I was shipped the wrong product" },
				{ value: "missing-order", text: "Missing Order" },
				{ value: "not-wanted", text: "Item no longer wanted" },
				{ value: "not-expected", text: "Item not as expected" },
				{ value: "parts-missing", text: "Product parts missing" },
				{ value: "not-received", text: "Item not received" },
				{ value: "noticeable-imperfection", text: "Noticeable Imperfection" },
				{ value: "other", text: "Other" },
			],
		},
		"shipping-box-damaged": {
			"label": "Was the shipping box damaged?",
			"type": "question",
			meta: {
				"autoContinue": true,
			},
			"options": [
				{ value: "yes", text: "Yes" },
				{ value: "no", text: "No" },
			],
		},
		"credit-exchange": {
			"label": "Would you like a credit or an exchange?",
			"type": "question",
			"meta": {
				"remedy": true,
				"autoContinue": true,
			},
			"options": [
				{ value: "credit", text: "Credit" },
				{ value: "exchange", text: "Exchange" },
			],
		},
		"replace": {
			"label": "Would you like a replacement?",
			"type": "question",
			"meta": {
				"remedy": true,
				"autoContinue": true,
			},
			"options": [
				{ value: "replacement", text: "Yes" },
				{ value: "no", text: "No" },
			],
		},
		"refund": {
			"label": "Would you like a refund?",
			"type": "question",
			"meta": {
				"remedy": true,
				"autoContinue": true,
			},
			"options": [
				{ value: "refund", text: "Yes" },
				{ value: "no", text: "No" },
			],
		},
		"replace-refund": {
			"label": "Would you like a replacement or a refund?",
			"type": "question",
			"meta": {
				"remedy": true,
				"autoContinue": true,
			},
			"options": [
				{ value: "replacement", text: "Replace" },
				{ value: "refund", text: "Refund" },
			],
		},
		"replace-return": {
			"label": "Would you like to replace the missing part or return the item?",
			"type": "question",
			"meta": {
				"remedy": true,
				"autoContinue": true,
			},
			"options": [
				{ value: "replacement-parts", text: "Replace the missing part" },
				{ value: "refund", text: "Return the entire item" },
			],
		},
		"how-so": {
			"label": "How so?",
			"type": "text-input",
			"meta": {
				// "input-only": true,
				"addToNotes": true,
			},
		},
		"parts-missing": {
			"label": "Which parts are missing?",
			"type": "parts-missing",
			"meta": {},
		},
		"contact-address": {
			"subtitle": "Shipping and Contact",
			"label": "Review your information",
			"copy": {
				"product-order": {
					"which-order": "This information is pulled directly from your order, so please make any necessary updates.",
					"which-product": "Please provide contact information so we can reach out to you.<br>You may also find the relevant order to fill the contact information from your order.",
				}
			},
			"type": "contact-information",
		},
		"continue": {
			"label": "Do you need help with another product",
			"type": "question",
			"meta": {
				"autoContinue": true,
			},
			"options": [
				{ value: "yes", text: "Yes" },
				{ value: "no", text: "No" },
			],
		},
		"submit": {
			"label": "We'll be in touch soon!",
			"copy": "You'll receive a confirmation email from our customer care team shortly.",
			"type": "submit",
		},
	},

	"concerns": [
		{"id": "Bubble","label": "Bubble","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Bubble","cause": "Seasoning","response": "The black coating is vegetable oil. When cooking oil is applied to the surface, then heated, the oil turns black. This is the seasoning process.<br> The coating is a functional application and not a cosmetic application. It is normal to have areas where the seasoning has bubbled and flaked off or not seasoned as well as other areas; underneath, the seasoning can be a lighter colored spot. No need to worry - it is not rust. Whenever you see a seasoned spot that is dark brown, it means it's the first stage of seasoning. It will darken up over time with proper use and care.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/bubble.jpg","meta": {"used": "no","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Bubble",}},
		{"id": "Drip","label": "Drip","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Bubble","cause": "Seasoning","response": "At Lodge, cast iron and carbon steel cookware is seasoned with 100% soybean oil baked onto the surface at a high temperature. During this process, the cookware hangs from a rack as it moves from the seasoning chamber to the oven. That means some new pieces may experience a \"bubble\" on the cookware - visibly known as the drip- where the oil hasn't fully adhered to the cookware. The bubble can chip away and reveal a light brown color. It's perfectly safe, as this is just the initial layers of seasoning. It will disappear with regular use and care.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/drip.jpg","meta": {"used": "no","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Drip",}},
		{"id": "Black Residue","label": "Black Residue","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Black Residue","cause": "Seasoning","response": "There can be residue from the seasoning that may come off your seasoned cookware. The residue is not harmful in any way and will decrease as the cookware is used over time. It can also appear when cooking liquids, boiling water, using soap on newer cookware, or cooking acidic and alkaline foods such as beans and tomatoes.<br> <br> The Fix:<br> Continue to use and care for your cookware, and you will see a reduction in black residue as the seasoning improves.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/residue.jpg","meta": {"used": "no","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Black Residue",}},
		{"id": "Uneven Seasoning","label": "Uneven Seasoning","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Seasoning","cause": "Seasoning","response": "Some cookware may have slight variations in the seasoning finish. These variations do not affect cooking performance and typically even out with use.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/unevenseasoning.jpg","meta": {"used": "no","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Uneven Seasoning",}},
		{"id": "Rough Surface","label": "Rough Surface","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Roughness","cause": "Product Information","response": "The texture is a result of the sand-casting process Lodge uses. It creates a surface texture that allows the seasoning to adhere to it. As you use your cookware over time and continue to season it, the pan will become smoother. Unlike other types of cookware, Lodge Cast Iron only gets better with age.<br> <br>Some people prefer to smooth out the roughness, and it is okay to do so using fine grade sandpaper. Be sure to re-season the item promptly after doing so.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/roughsurface.jpg","meta": {"used": "no","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Rough Surface",}},
		{"id": "Noticeable Imperfection","label": "Noticeable Imperfection","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","image": "/themes/custom/tombras/images/product-help/castiron/imperfection.jpg","meta": {"used": "no","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "reason": ["noticeable-imperfection"],"purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Noticeable Imperfection",}},
		{"id": "Grind Marks","label": "Grind Marks","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Grind Marks","cause": "Manufacturing/Casting Defect","response": "After the cast iron is poured into a sand mold, there can be excess iron around the cookware's edges. The excess iron is removed, but there are lines left behind. This is normal and will not affect the performance of your new piece of cast iron cookware.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/grind_marks.jpg","meta": {"used": "no","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Grind Marks",}},
		{"id": "Other","label": "Other","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Other","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Other",}},

		{"id": "Black Residue","label": "Black Residue","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Black Residue","cause": "Seasoning","response": "There can be residue from the seasoning that may come off your seasoned cookware. The residue is not harmful in any way and will decrease as the cookware is used over time. Continue to use and care for your cookware, and you will see a reduction in black residue as the seasoning improves.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/residue.jpg","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Black Residue",}},
		{"id": "Food Turned Dark During Cooking","label": "Food Turned Dark During Cooking","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Black Residue","cause": "Seasoning ","response": "The black coating on the cookware is cooking oil. Here at the foundry, we apply cooking oil to the surface of each piece, then heat it; this turns the cooking oil black. This is what we refer to as the seasoning process. Seasoning is a natural process and not harmful in any way. The seasoning layers can build up over time or break down.<br>Foods that are very acidic (i.e., beans, tomatoes, citrus juices, etc.) or that contain a lot of liquid should not be cooked in seasoned cast iron until the cookware is highly seasoned. These foods break down the seasoning and result in discoloration and metallic-tasting food. Wait until cast iron is better seasoned to cook these types of foods. Lodge Enameled Cast Iron is not affected by acidity and can be used with all foods.<br>For cookware with seasoning that has been impacted by acidic foods or excess liquids, it must be re-seasoned in the oven following these simple instructions: https://www.lodgecastiron.com/cleaning-and-care/cast-iron/science-cast-iron-seasoning","hasResponse": "yes","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Food Turned Dark During Cooking",}},
		{"id": "Flaking","label": "Flaking","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Flaking","cause": "Seasoning","response": "The black flakes are part of the seasoning layer (the coating of oil which is baked onto the iron at a high temperature). Occasionally, the seasoning may break down and flake off, but this is not harmful in any way. To remove any loose flakes, scour the cookware with an SOS or Brillo pad, then re-season the cookware following these simple instructions:  https://www.lodgecastiron.com/cleaning-and-care/cast-iron/science-cast-iron-seasoning","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/flakeyseasoning.jpg","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Flaking",}},
		{"id": "Rust","label": "Rust","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Rust","cause": "User Error Lack of Info","response": "Rust forms when the cookware is exposed to moisture for extended periods and is not harmful in any way. To clean the pan after cooking, rinse with warm water, hand-dry, and promptly rub with cooking oil. If left out to air dry, it will rust. Be sure to store the cookware in a cool, dry place, keeping it away from humidity around a sink or dishwasher.<br> <br> You can restore your cookware with the steps below:<br> 1. Scour the surface with hot, soapy water and an SOS or Brillo pad. It is okay to use soap this time because you are preparing to re-season the cookware.<br> 2. Rinse and hand-dry thoroughly.<br> 3. Apply a very thin, even coating of vegetable or canola oil to the cookware (inside and out). If you use too much oil, your pan may become sticky.<br> 4. Place the cookware in the oven upside-down on the top rack.<br> 5. Place a large baking sheet or aluminum foil on the bottom rack to catch any excess oil that may drip off the cookware.<br> 6. Bake at 450 - 500 F for 1 hour.<br> 7. Allow to cool in the oven, and repeat steps 3-6 as necessary to achieve the classic black patina.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/rust.jpg","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Rust",}},
		{"id": "Food Sticking","label": "Food Sticking","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Sticking","cause": "User Error Lack of Info","response": "Lodge seasoned cookware is not non-stick to start with; over time, with proper use and care, the seasoning layers will build up to a naturally non-stick surface, but this takes time. Below are some tips for cooking on the stovetop that will help when cooking.<br> *Add extra oil to the surface before cooking<br> *Start on the lowest setting you have allowed to heat for 3-5 minutes then gradually increase the temperature<br> *Cook on a lower temperature, medium is enough heat to sear a steak, most foods can be cooked halfway between low and medium. <br> *Allow food to sit out while the skillet is heating up.  When added to a hot skillet, cold food tends to stick more than room temperature food.<br>*Avoid cooking highly acidic foods for long periods of time until the cookware has been used 8-10 times. ","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/stuck_food.jpg","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Food Sticking",}},
		{"id": "Sticky Surface","label": "Sticky Surface","caseRecordType": "General Information","type": "Use & Care, Seasoning, & Cooking","subType": "How Do I Keep My Cookware from Being Sticky (Excess Oil)","cause": "Use and Care Information","response": "The sticky residue is typically the result of using too much oil. You can first try giving your cookware a bath with warm, soapy water. This may do the trick. If you still have sticky residue, we recommend the following:<br> <br> 1. Place the cookware in the oven upside-down on the top rack.<br> 2. Place a large baking sheet or aluminum foil on the bottom rack to catch any excess oil that may drip off the cookware.<br> 3. Bake at 400 - 450 °F for 1 hour.<br> 4. Allow to cool and repeat if necessary.<br> <br> This process will help evenly distribute the excess oil so that it adheres to the cookware, reducing the sticky residue.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/sticky.jpg","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Sticky Surface",}},
		{"id": "Odors","label": "Odors","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Odors","cause": "User Error Lack of Info","response": "To eliminate the unwanted odor, simply bake your cast iron pan in the oven at 400 degrees F for 15 minutes. This easy, odor-eliminating method won't damage the seasoning on your cookware. A traditional method calls for you to sprinkle a layer of regular table salt on the cooking surface of your cookware, leave it on overnight, and rinse it off in the morning. This will also eliminate any lingering odors. If smells persist, you may need to scour and re-season your cookware.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/odor.jpg","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Odors",}},
		{"id": "Stuck on Food","label": "Stuck on Food","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Sticking","cause": "Use and Care Information","response": "After cooking, allow the cookware to cool, then use a pan scraper to remove stuck-on food, scrub with a nylon brush or non-scratch pad, hand dry, and add a layer of oil. If that does not remove the stuck on food try boiling water and a small amount of soap in the cookware for 3-5 minutes, then clean the surface after the pan has cooled. If the boiling water does not remove the stuck-on residue, then scour the surface with hot, soapy water and an SOS or Brillo pad, then re-season in the oven.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/castiron/stuck_food.jpg","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Stuck on Food",}},
		{"id": "Cracked","label": "Cracked","caseRecordType": "Returns, Exceptions, & Goodwill","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Cracked","cause": {"default": "TBD", "dropped": "User Error Lack of Info"},"response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": {"dropped": "Unfortunately, there is a notion out there that cast iron is indestructible; however, just like other metals, it can break, especially when dropped or handled roughly. Cast iron is strong but brittle (especially when cold), so it will break before it ever bends. This has nothing to do with the quality; it is just the nature of cast iron.While there is an infinite number of events/situations that could cause cast iron to break, I can tell you that the most common we see are from cookware being dropped onto various surfaces, having objects dropped onto the cast iron, or switching cast iron between temperature extremes. Lodge does not replace cookware damaged from being dropped.", "default": ""},"image": "/themes/custom/tombras/images/product-help/castiron/cracked.jpg","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Cracked",}},
		{"id": "Warped","label": "Warped","caseRecordType": "Returns, Exceptions, & Goodwill","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Warped","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Warped",}},
		{"id": "Uneven Heating","label": "Uneven Heating","caseRecordType": "Returns, Exceptions, & Goodwill","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Warped","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Uneven Heating",}},
		{"id": "Handle Broken","label": "Handle Broken","caseRecordType": "Returns, Exceptions, & Goodwill","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Handle Broke","cause": {"default": "TBD", "dropped": "User Error Lack of Info"},"response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": {"dropped": "Unfortunately, there is a notion out there that cast iron is indestructible; however, just like other metals, it can break, especially when dropped or handled roughly. Cast iron is strong but brittle (especially when cold), so it will break before it ever bends. This has nothing to do with the quality; it is just the nature of cast iron.While there is an infinite number of events/situations that could cause cast iron to break, I can tell you that the most common we see are from cookware being dropped onto various surfaces, having objects dropped onto the cast iron, or switching cast iron between temperature extremes. Lodge does not replace cookware damaged from being dropped.", "default": ""},"image": "/themes/custom/tombras/images/product-help/castiron/brokenhandle.jpg","meta": {"used": "yes","material": ["Seasoned Cast Iron",],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Handle Broken",}},
		{"id": "Other","label": "Other","caseRecordType": "Issues & Concerns","type": {"Seasoned Cast Iron": "Seasoned Cast Iron Issue","Carbon Steel": "Carbon Steel Issue",},"subType": "Other","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "yes","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Other",}},

		{"id": "Chipped","label": "Chipped","caseRecordType": "Returns, Exceptions, & Goodwill","type": {"Enameled Cast Iron": "Enamel Cast Iron"},"subType": "Chipped","cause": {"default": "TBD", "dropped": "User Error Lack of Info"},"response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": {"dropped": "Unfortunately, there is a notion out there that enameled cast iron is indestructible; however, just like other metals, it can break, especially when dropped or handled roughly. Cast iron is strong but brittle (especially when cold), so it will break before it ever bends. This has nothing to do with the quality; it is just the nature of cast iron.While there is an infinite number of events/situations that could cause cast iron to break, I can tell you that the most common we see are from cookware being dropped onto various surfaces, having objects dropped onto the cast iron, or switching cast iron between temperature extremes. Lodge does not replace cookware damaged from being dropped.", "default": ""},"image": "/themes/custom/tombras/images/product-help/enamel/chipped.jpg","meta": {"used": "yes","material": ["Enameled Cast Iron"],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Chipped",}},
		{"id": "Cracked","label": "Cracked","caseRecordType": "Returns, Exceptions, & Goodwill","type": {"Enameled Cast Iron": "Enamel Cast Iron"},"subType": "Cracked","cause": {"default": "TBD", "dropped": "User Error Lack of Info"},"response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": {"dropped": "Unfortunately, there is a notion out there that cast iron is indestructible; however, just like other metals, it can break, especially when dropped or handled roughly. Cast iron is strong but brittle (especially when cold), so it will break before it ever bends. This has nothing to do with the quality; it is just the nature of cast iron.While there is an infinite number of events/situations that could cause cast iron to break, I can tell you that the most common we see are from cookware being dropped onto various surfaces, having objects dropped onto the cast iron, or switching cast iron between temperature extremes. Lodge does not replace cookware damaged from being dropped.", "default": ""},"image": "/themes/custom/tombras/images/product-help/enamel/chipped.jpg","meta": {"used": "yes","material": ["Enameled Cast Iron"],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Cracked",}},
		{"id": "Powdery or Rough Surface","label": "Powdery or Rough Surface","caseRecordType": "Issues & Concerns","type": {"Enameled Cast Iron": "Enamel Cast Iron Issue"},"subType": "Powdery or rough surface","cause": "User Error Lack of Info","response": "If the surface of your enamel cookware is powdery or rough, citrus juices and citrus-based cleaners (including some dish detergents) may be to blame. These items can dull the exterior gloss, which is not harmful and will not impair cooking performance, but it will alter the finish of your cookware. Keep in mind the use of these products is not covered by our warranty.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/enamel/powdery_surface.jpg","meta": {"used": "yes","material": ["Enameled Cast Iron"],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Powdery or Rough Surface",}},
		{"id": "Staining","label": "Staining","caseRecordType": "Issues & Concerns","type": {"Enameled Cast Iron": "Enamel Cast Iron Issue"},"subType": "Staining","cause": "User Error Lack of Info","response": "Stains are to be expected when you use enamel cookware and does not affect performance.<br> <br> To remove slight stains:<br> <br> Follow the steps above to clean your cookware.<br> Rub with a dampened cloth and Lodge Enamel Cleaner or another ceramic cleaner according to directions on the bottle.<br> <br> For persistent stains:<br> Follow the steps above to clean and remove slight stains.<br> Soak the interior of the cookware for 2-3 hours with a mixture of 3 tablespoons of household bleach per quart of water.<br> To remove stubborn, baked-on food, boil 2 cups of water and 4 tablespoons of baking soda. Boil for a few minutes, then use a pan scraper to loosen the food.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/enamel/stain_and_stuck_food.jpg","meta": {"used": "yes","material": ["Enameled Cast Iron"],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Staining",}},
		{"id": "Rim Rusting","label": "Rim Rusting","caseRecordType": "Issues & Concerns","type": {"Enameled Cast Iron": "Enamel Cast Iron Issue"},"subType": "Rust","cause": "User Error Lack of Info","response": "If rust develops around the rim, it may be that the pan has been in a damp area. Leaving pans on a draining board to drip dry or cleaning in the dishwasher can cause a rust deposit. Rust can also form if the rim of the pan is not dried sufficiently after being washed. Should rust develop, we recommend cleaning with a nylon sponge and dish detergent and dry thoroughly. We recommend rubbing a small amount of cooking oil around the rim to create a seal and prevent rust from re-appearing. We recommend rubbing with oil periodically to prevent rusting.","hasResponse": "yes","image": "/themes/custom/tombras/images/product-help/enamel/rust.jpg","meta": {"used": "yes","material": ["Enameled Cast Iron"],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Rim Rusting",}},
		{"id": "Handle Broke","label": "Handle Broke","caseRecordType": "Issues & Concerns","type": {"Enameled Cast Iron": "Enamel Cast Iron Issue"},"subType": "Handle Broke","cause": {"default": "TBD", "dropped": "User Error Lack of Info"},"response": "If a handle on your enameled cast iron cookware has broken, it is likely the result of being dropped. We do not replace cookware that has been dropped.","hasResponse": "yes","finalResponse": {"dropped": "Unfortunately, there is a notion out there that cast iron is indestructible; however, just like other metals, it can break, especially when dropped or handled roughly. Cast iron is strong but brittle (especially when cold), so it will break before it ever bends. This has nothing to do with the quality; it is just the nature of cast iron.While there is an infinite number of events/situations that could cause cast iron to break, I can tell you that the most common we see are from cookware being dropped onto various surfaces, having objects dropped onto the cast iron, or switching cast iron between temperature extremes. Lodge does not replace cookware damaged from being dropped.", "default": ""},"image": "/themes/custom/tombras/images/product-help/enamel/handle_broke.jpg","meta": {"used": "yes","material": ["Enameled Cast Iron"],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Handle Broke",}},
		{"id": "Other","label": "Other","caseRecordType": "Issues & Concerns","type": {"Enameled Cast Iron": "Enamel Cast Iron Issue"},"subType": "Other","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","image": "/themes/custom/tombras/images/product-help/enamel/other.jpg","meta": {"used": ["yes", "no"],"material": ["Enameled Cast Iron"],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Other",}},

		{"id": "Broken","label": "Broken","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Accessories","subType": "Cracked","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "yes","material": ["Accessories"],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Broken",}},
		{"id": "Silicone Melted","label": "Silicone Melted","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Accessories","subType": "Melted","cause": "User Error Lack of Info","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","image": "/themes/custom/tombras/images/product-help/accessories/silicone_melted.jpg","meta": {"used": "yes","material": ["Accessories"],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Silicone Melted",}},
		{"id": "Handle Fit","label": "Handle Fit","caseRecordType": "Issues & Concerns","type": "Accessories Issue","subType": "Handle Fit","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","image": "/themes/custom/tombras/images/product-help/accessories/handlefit.jpg","meta": {"used": "yes","material": ["Accessories"],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Handle Fit",}},
		{"id": "Handle Broke","label": "Handle Broke","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Accessories","subType": "Handle Broke","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "yes","material": ["Accessories"],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Handle Broke",}},
		{"id": "Lid Fit","label": "Lid Fit","caseRecordType": "Issues & Concerns","type": "Accessories Issue","subType": "Lid Fit","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","image": "/themes/custom/tombras/images/product-help/accessories/lidfit.jpg","meta": {"used": "yes","material": ["Accessories"],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Lid Fit",}},
		{"id": "Torn","label": "Torn","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Accessories","subType": "Torn","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "yes","material": ["Accessories"],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Torn",}},
		{"id": "Zipper","label": "Zipper","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Accessories","subType": "Zipper","cause": "TBD ","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","image": "/themes/custom/tombras/images/product-help/accessories/brokenzipper.jpg","meta": {"used": "yes","material": ["Accessories"],"damaged": "yes", "still-need-help": "yes","purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Zipper",}},
		{"id": "Other","label": "Other","caseRecordType": "Issues & Concerns","type": "Accessories Issue","subType": "Other","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": ["yes", "no"],"material": ["Accessories"],"damaged": "no", "purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Other",}},

		{"id": "Product Arrived Broken","label": "Product Arrived Broken","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Order And Shipment Error","subType": "Product Arrived Damaged","cause": "Carrier","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Any"],"damaged": "yes", "still-need-help": "yes","reason": ["arrived-broken"],"purchased-from": ["website"],"select-concern": "Product Arrived Broken",}},
		{"id": "Product Arrived Broken","label": "Product Arrived Broken","caseRecordType": "Issues & Concerns","type": "Order And Shipment Error","subType": "Product Arrived Damaged","cause": "Carrier","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Any"],"damaged": "yes", "still-need-help": "yes","reason": ["arrived-broken"],"purchased-from": ["gift"],"select-concern": "Product Arrived Broken",}},
		{"id": "Missing Part","label": "Missing Part","caseRecordType": "Returns, Exceptions, & Goodwill - Placeholder for the missing parts scenarios","type": "","subType": "","cause": "","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["parts-missing"],"purchased-from": ["website", "amazon", "gift", "retailer"],"select-concern": "Missing Part",}},
		{"id": "Missing Part - Box Damaged","label": "Missing Part - Box Damaged","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Order And Shipment Error","subType": "Missing or Replacement Parts","cause": "Carrier","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["parts-missing"],"shipping-box-damaged": "yes","purchased-from": ["website"],"select-concern": "Missing Part - Box Damaged",}},
		{"id": "Missing Part - Box Not Damaged","label": "Missing Part - Box Not Damaged","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Order And Shipment Error","subType": "Missing or Replacement Parts","cause": "Packing Error","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["parts-missing"],"shipping-box-damaged": "no","purchased-from": ["website"],"select-concern": "Missing Part - Box Not Damaged",}},
		{"id": "Wrong Item","label": "Wrong Item","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Order And Shipment Error","subType": "Received Incorrect Product","cause": "TBD","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["wrong-product"],"purchased-from": ["website"],"select-concern": "Wrong Item",}},
		{"id": "Missing Order","label": "Missing Order","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Order And Shipment Error","subType": "Missing Order That Has Shipped","cause": "Carrier","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["missing-order"],"purchased-from": ["website"],"select-concern": "Missing Order",}},
		{"id": "No Longer Needed/Wanted","label": "No Longer Needed/Wanted","caseRecordType": "Returns, Exceptions, & Goodwill","type": {"Enameled Cast Iron": "Enamel Cast Iron"},"subType": "Product Not Needed Or Wanted","cause": "Not Wanted/Needed","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","RAResponse": "The item may be returned to us; once received, a refund for the product will only be issued to the card used to purchase. You are responsible for the shipping charges. A new order can be placed at any time on our website. <br>*Items are only accepted if they are new/unused and in the original packaging.<br><br>Please include the return authorization number in the box with the cookware. <br><br>Return Address:<br>Lodge Cast Iron<br>Attn: Returns Department<br>205 East 5th Street<br>South Pittsburg, TN 37380","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["not-wanted"],"requestedRemedy": "refund","purchased-from": ["website"],"select-concern": "No Longer Needed/Wanted",}},

		{"id": "Product Arrived Broken","label": "Product Arrived Broken","caseRecordType": "Issues & Concerns","type": "Order And Shipment Issue","subType": "Product Arrived Damaged","cause": "Other Retailer Damaged","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Since the item arrived damaged you would need to reach out to the place of purchase to have the item replaced or a refund issued.","meta": {"used": "no","material": ["Any"],"damaged": "yes", "still-need-help": "yes","reason": ["arrived-broken"],"requestedRemedy": "none","purchased-from": ["gift"],"select-concern": "Product Arrived Broken",}},
		{"id": "Product Arrived Broken","label": "Product Arrived Broken","caseRecordType": "Issues & Concerns","type": "Order And Shipment Issue","subType": "Product Arrived Damaged","cause": "Other Retailer Damaged","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Since this was ordered from Amazon instead of Lodge, it shipped from Amazon's facility. Please reach out to the Amazon Customer Care team for a resolution. You can reach them at (888) 280-4331.<br> <br>Occasionally when trying to submit a return on Amazon's website, they may direct you to Lodge; however, this is incorrect. If you call Amazon, they will process your return.","meta": {"used": "no","material": ["Any"],"damaged": "yes", "still-need-help": "yes","reason": ["arrived-broken"],"requestedRemedy": "none","purchased-from": ["amazon"],"select-concern": "Product Arrived Broken",}},
		{"id": "Missing Part - Box Damaged","label": "Missing Part - Box Damaged","caseRecordType": "Issues & Concerns","type": "Order And Shipment Issue","subType": "Missing or Replacement Parts","cause": "Other Retailer Damaged","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Since the item was not ordered or shipped from Lodge you would need to reach out to the place of purchase to have the missing part shipped to you.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["parts-missing"],"requestedRemedy": "none","shipping-box-damaged": "yes","purchased-from": ["gift"],"select-concern": "Missing Part - Box Damaged",}},
		{"id": "Missing Part - Box Damaged","label": "Missing Part - Box Damaged","caseRecordType": "Issues & Concerns","type": "Order And Shipment Issue","subType": "Missing or Replacement Parts","cause": "Other Retailer Damaged","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Since the item was not ordered or shipped from Lodge you would need to reach out to the place of purchase to have the missing part shipped to you.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["parts-missing"],"requestedRemedy": "none","shipping-box-damaged": "yes","purchased-from": ["retailer"],"select-concern": "Missing Part - Box Damaged",}},
		{"id": "Missing Part - Box Damaged","label": "Missing Part - Box Damaged","caseRecordType": "Issues & Concerns","type": "Order And Shipment Issue","subType": "Missing or Replacement Parts","cause": "Other Retailer Damaged","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Since this was ordered from Amazon instead of Lodge, it shipped from Amazon's facility. Please reach out to the Amazon Customer Care team for a resolution. You can reach them at (888) 280-4331.<br> <br>Occasionally when trying to submit a return on Amazon's website, they may direct you to Lodge; however, this is incorrect. If you call Amazon, they will process your return.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["parts-missing"],"requestedRemedy": "none","shipping-box-damaged": "yes","purchased-from": ["amazon"],"select-concern": "Missing Part - Box Damaged",}},
		{"id": "Missing Part - Box Not Damaged","label": "Missing Part - Box Not Damaged","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Seasoned Cast Iron","subType": "Missing or Replacement Parts","cause": "Packing Error","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Seasoned Cast Iron", "Carbon Steel",],"damaged": "no", "reason": ["parts-missing"],"shipping-box-damaged": "no","purchased-from": ["gift","amazon","retailer"],"select-concern": "Missing Part - Box Not Damaged",}},
		{"id": "Missing Part - Box Not Damaged","label": "Missing Part - Box Not Damaged","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Enamel Cast Iron","subType": "Missing or Replacement Parts","cause": "Packing Error","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Enameled Cast Iron"],"damaged": "no", "reason": ["parts-missing"],"shipping-box-damaged": "no","purchased-from": ["gift","amazon","retailer"],"select-concern": "Missing Part - Box Not Damaged",}},
		{"id": "Missing Part - Box Not Damaged","label": "Missing Part - Box Not Damaged","caseRecordType": "Returns, Exceptions, & Goodwill","type": "Accessories","subType": "Missing or Replacement Parts","cause": "Packing Error","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "no","meta": {"used": "no","material": ["Accessories"],"damaged": "no", "reason": ["parts-missing"],"shipping-box-damaged": "no","purchased-from": ["gift","amazon","retailer"],"select-concern": "Missing Part - Box Not Damaged",}},
		{"id": "Wrong Item","label": "Wrong Item","caseRecordType": "Issues & Concerns","type": "Order And Shipment Issue","subType": "Received Incorrect Product","cause": "Dealer Error","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Since the item was not ordered or shipped from Lodge you would need to reach out to the place of purchase to have the correct item shipped.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["wrong-product"],"requestedRemedy": "none","purchased-from": ["retailer"],"select-concern": "Wrong Item",}},
		{"id": "Wrong Item","label": "Wrong Item","caseRecordType": "Issues & Concerns","type": "Order And Shipment Issue","subType": "Product Arrived Damaged","cause": "Other Retailer Damaged","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "We do not sell on Amazon or ship for Amazon. Since it was ordered from Amazon, it would have been shipped from their facility. I recommend reaching out to the Amazon Customer Care team for a resolution. You can reach them at (888) 280-4331.<br> <br> If you try to do a return through the Amazon website, they will direct you to Lodge. But that information is wrong. We have asked Amazon to change that information. If you call Amazon, they will be able to help you with this.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["wrong-product"],"requestedRemedy": "none","purchased-from": ["amazon"],"select-concern": "Wrong Item",}},
		{"id": "Missing Order/Item","label": "Missing Order/Item","caseRecordType": "Issues & Concerns","type": "Order And Shipment Issue","subType": "Missing Order That Has Shipped","cause": "Dealer Error","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Please reach out to the place of purchase about the missing order since it was not ordered or shipped from Lodge directly.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["missing-order"],"requestedRemedy": "none","purchased-from": ["gift"],"select-concern": "Missing Order/Item",}},
		{"id": "Missing Order/Item","label": "Missing Order/Item","caseRecordType": "Issues & Concerns","type": "Order And Shipment Issue","subType": "Missing Order That Has Shipped","cause": "Dealer Error","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Please reach out to the place of purchase about the missing order since it was not ordered or shipped from Lodge directly.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["missing-order"],"requestedRemedy": "none","purchased-from": ["retailer"],"select-concern": "Missing Order/Item",}},
		{"id": "Missing Order/Item","label": "Missing Order/Item","caseRecordType": "Issues & Concerns","type": "Order And Shipment Issue","subType": "Missing Order That Has Shipped","cause": "Dealer Error","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Since this was ordered from Amazon instead of Lodge, it shipped from Amazon's facility. Please reach out to the Amazon Customer Care team for a resolution. You can reach them at (888) 280-4331.<br> <br>Occasionally when trying to submit a return on Amazon's website, they may direct you to Lodge; however, this is incorrect. If you call Amazon, they will process your return.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["missing-order"],"requestedRemedy": "none","purchased-from": ["amazon"],"select-concern": "Missing Order/Item",}},
		{"id": "No Longer Needed/Wanted","label": "No Longer Needed/Wanted","caseRecordType": "Issues & Concerns","type": {"Enameled Cast Iron": "Enamel Cast Iron"},"subType": "Product Not Needed Or Wanted","cause": "Not Wanted/Needed","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Since the item was not ordered or shipped from Lodge you would need to reach out to Amazon to return or exchange the item.  You can reach them at (888) 280-4331. If you try to do a return through the Amazon website, they will direct you to Lodge. But that information is wrong. We have asked Amazon to change that information. If you call Amazon, they will be able to help you with this.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["not-wanted"],"requestedRemedy": "none","purchased-from": ["amazon"],"select-concern": "No Longer Needed/Wanted",}},
		{"id": "No Longer Needed/Wanted","label": "No Longer Needed/Wanted","caseRecordType": "Issues & Concerns","type": {"Enameled Cast Iron": "Enamel Cast Iron"},"subType": "Product Not Needed Or Wanted","cause": "Not Wanted/Needed","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "Since the item was not ordered or shipped from Lodge you would need to reach out to the place of purchase to return or exchange the item.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["not-wanted"],"requestedRemedy": "none","purchased-from": ["retailer"],"select-concern": "No Longer Needed/Wanted",}},
		{"id": "No Longer Needed/Wanted","label": "No Longer Needed/Wanted","caseRecordType": "Issues & Concerns","type": {"Enameled Cast Iron": "Enamel Cast Iron"},"subType": "Product Not Needed Or Wanted","cause": "Not Wanted/Needed","response": "Just a couple more steps and we'll have all the info we need for Customer Care to review your request.","hasResponse": "yes","finalResponse": "The item should be returned or exchanged with the retailer it was purchased from. Because this was a gift, you would need to check with the person who bought it to see where it was purchased. We are unable to accept returns or exchange items bought from another retailer.","meta": {"used": "no","material": ["Any"],"damaged": "no", "reason": ["not-wanted"],"requestedRemedy": "none","purchased-from": ["gift"],"select-concern": "No Longer Needed/Wanted",}},
	]
};

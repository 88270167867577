/**
 *	@typedef {{
 *		id?: null | string,
 *		pages: Array,
 *		viewedProducts: Array,
 *		purchases: Array
 *		interactedWithCart: boolean
 *	}} ClassifierState
 */
export const baseState = {
	id: null,
	pages: [],
	viewedProducts: [],
	purchases: [],
	interactedWithCart: false,
};

/**
 *
 * @param {string|number} val
 * @return string
 */
export const stripMoneySign = (val) => {
	if (val) {
		return val.toString().replace("$", "");
	}
	return val;
};

/**
 * @typedef {{
 *	type: string,
 *	data?: object
 * }} StatePayload
 */

/**
 *
 * @param { StatePayload } payload
 * @param {ClassifierState} state
 * @return ClassifierState
 */
export const reducer = (payload, state) => {
	switch (payload.type) {
		case CLASSIFIER_TYPES.PAGE_VIEW:
			const alreadySeen = state.pages.filter((p) => {
				return p.path === payload.data.path;
			});
			return Object.assign({}, state, {
				pages: alreadySeen.length
					? [...state.pages]
					: [...state.pages, payload.data],
			});
		case CLASSIFIER_TYPES.PRODUCT_VIEW:
			const alreadySeenProduct =
				state.viewedProducts.indexOf(payload.data.salesforce_id) >= 0;
			return Object.assign({}, state, {
				viewedProducts: alreadySeenProduct
					? [...state.viewedProducts]
					: [...state.viewedProducts, payload.data.salesforce_id],
			});
		case CLASSIFIER_TYPES.ECOM_ADD_TO_CART:
			return Object.assign({}, state, {
				interactedWithCart: payload.data.interactedWithCart,
			});
		case CLASSIFIER_TYPES.ECOM_PURCHASE:
			console.log("product 40", payload);
			return Object.assign({}, state, {
				purchases: [
					...state.purchases,
					{
						units: payload.data.units,
						price: payload.data.price,
					},
				],
			});
		default:
			return Object.assign({}, state);
	}
};

export const CLASSIFIER_TYPES = {
	PAGE_VIEW: `PAGE_VIEW`,
	ECOM_ADD_TO_CART: `ECOM_ADD_TO_CART`,
	ECOM_PURCHASE: `ECOM_PURCHASE`,
	PRODUCT_VIEW: "PRODUCT_VIEW",
};

<template>
  <div class="shipping-information">
    <div class="mb-60">
      <p v-if="node.subtitle" class="subtitle--line brown">{{ node.subtitle }}</p>
      <h1 class="h2 mb-0">{{ node.label }}</h1>
      <p class="mt-30" v-if="subhead" v-html="subhead"></p>
    </div>
    <div class="frow justify-between">
      <div class="label col-xs-1-1 col-sm-5-12 mb-4">
        <order-chooser v-if="order && !order.orderNumber"/>
        <div class="frow column-start" v-if="order && order.orderNumber">
          <div class="secondary-highlight">Order <strong>{{ order.orderNumber }}</strong> |
            <button @click="removeOrder" class="btn--text-link">Check a different order</button>
          </div>
          <div class="products">
            <div
                :class="{'selected-product': product.id === issueProduct.id}"
                class="frow justify-start mb-4" v-for="product in order.products">
              <img :src="product.image" :alt="product.name">
              <div class="product-name">
                <strong>{{ product.name }}</strong><br>
                <span class="sku">{{ product.id }}{{product.name === issueProduct.name ? ` (${product.quantity})` : ''}}</span>
              </div>
            </div>
            <div v-if="!order.products.find(p => issueProduct.id === p.id)"
                class="frow justify-start mb-4 selected-product">
              <img :src="issueProduct.image.url || issueProduct.image.url" :alt="issueProduct.image.alt || issueProduct.name">
              <div class="product-name">
                <strong>{{ issueProduct.name }}</strong><br>
                <span class="sku">{{ issueProduct.id }} ({{issueProduct.quantity}})</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form class="col-xs-1-1 col-sm-6-12 frow column-start" aria-label="Contact Information">
        <div class="frow column-start mb-4">
          <label class="secondary-highlight">Contact Information</label>
          <div class="floating-label--wrap">
            <input ref="name" class="floating-label--field" v-model="name" type="text" id="name" placeholder="Name"/>
            <label class="floating-label" for="name">Name<span class="required" title="required">*</span></label>
            <div class="error" v-show="nameError && name">
              Please enter a name
            </div>
          </div>
          <div class="floating-label--wrap">
            <input class="floating-label--field" v-model="email" type="text" id="email" placeholder="Email"/>
            <label class="floating-label" for="email">Email<span class="required" title="required">*</span></label>
            <div class="error" v-show="emailError && email">
              Please enter a valid email address
            </div>
          </div>

          <div class="floating-label--wrap">
            <input class="floating-label--field" v-model="phone" type="text" id="phone" placeholder="Phone"/>
            <label class="floating-label" for="phone">Phone<span class="required" title="required">*</span></label>
            <div class="error" v-show="phoneError && phone">
              Please enter a valid phone number including area code
            </div>
            <div class="frow items-center justify-start">
              <input class="mb-0" v-model="intlPhone" type="checkbox" id="intl-phone"/>
              <span class="ml-2 intl-phone-note">Non-US #</span>
            </div>
          </div>

        </div>
        <div class="frow column-start">
          <label class="secondary-highlight">Shipping Address</label>
          <div class="floating-label--wrap">
            <input class="floating-label--field" v-model="address1" type="text" id="address1"
                   placeholder="Address Line 1"/>
            <label class="floating-label" for="address1">Address Line 1</label>
          </div>
          <div class="floating-label--wrap">
            <input class="floating-label--field" v-model="address2" type="text" id="address2"
                   placeholder="Address Line 2"/>
            <label class="floating-label" for="address2">Address Line 2</label>
          </div>
          <div class="floating-label--wrap">
            <input class="floating-label--field" v-model="city" type="text" id="city" placeholder="City"/>
            <label class="floating-label" for="city">City</label>
          </div>
          <div class="floating-label--wrap">
            <input class="floating-label--field" v-model="state" type="text" id="state" placeholder="State"/>
            <label class="floating-label" for="state">State</label>
          </div>
          <div class="floating-label--wrap">
            <input class="floating-label--field" v-model="zip" type="text" id="zip" placeholder="Zip Code"/>
            <label class="floating-label" for="zip">Zip Code</label>
          </div>
          <div class="floating-label--wrap">
            <input class="floating-label--field" v-model="country" type="text" id="country" placeholder="Country"/>
            <label class="floating-label" for="country">Country</label>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import mixins from './mixins';
import OrderChooser from './OrderChooser';
import NodeWrap from './NodeWrap';
import Information from './Information';

export default {
  name: "ContactInformation",
  components: { Information, OrderChooser, NodeWrap },
  mixins: [mixins],
  data() {
    return {
      name: "",
      nameError: true,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      email: "",
      emailError: true,
      phone: "",
      phoneError: true,
      intlPhone: false,
    };
  },
  props: [
    "step",
    "node",
  ],
  computed: {
    ...mapGetters({
      order: "productHelp/getOrder",
      request: "productHelp/getRequest",
      answers: "productHelp/getAnswers",
      issueProduct: "productHelp/getIssueProduct",
    }),
    updateContact() {
      // We are forcing this to false so that the Contact info is not updated in Salesforce
      // but leaving this here in case Lodge changes their mind
      return false;
      // this.name !== this.order.orderName ||
      //     this.address1 !== this.order.orderAddress1 ||
      //     this.address2 !== this.order.orderAddress2 ||
      //     this.city !== this.order.orderCity ||
      //     this.state !== this.order.orderState ||
      //     this.zip !== this.order.orderZip ||
      //     this.country !== this.order.orderCountry ||
      //     this.email !== this.order.orderEmail ||
      //     this.phone !== this.order.orderPhone;
    },
    contactInfo() {
      return (!this.nameError && !this.phoneError && !this.emailError)
          && {
            name: this.name,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            zip: this.zip,
            country: this.country,
            email: this.email,
            phone: this.phone,
            updateContact: this.updateContact,
          };
    },
    showAddressForm() {
      const remedies = /replace/gi;
      return this.order.orderNumber
          || (this.answers
              && this.answers.remedy
              && this.answers.remedy.value.match(remedies))
          || (this.answers
              && this.answers.damaged && this.answers.damaged.value == "yes"
              && this.answers.used && this.answers.used.value == "yes");
    },
    subhead() {
      if (this.node.copy) {
        if (typeof this.node.copy === "object") {
          let answer = Object.keys(this.node.copy)[0];
          return this.node.copy[answer][this.answers[answer].value];
        } else {
          return this.node.copy;
        }
      } else {
        return false;
      }
    }
  },
  watch: {
    contactInfo(c) {
      this.setContactInformation(c);
      if (this.name && !this.nameError
          && this.email && !this.emailError
          && this.phone && !this.phoneError) {
        this.setFollowingStep();
      } else {
        this.unsetNextStep();
      }
    },
    order: {
      handler(order) {
        this.name = order.orderName;
        this.address1 = order.orderAddress1;
        this.address2 = order.orderAddress2;
        this.city = order.orderCity;
        this.state = order.orderState;
        this.zip = order.orderZip;
        this.country = order.orderCountry;
        this.email = order.orderEmail;
        this.phone = order.orderPhone;
      },
      deep: true,
    },
    name(e) {
      let re = /^[a-zA-Z ]{2,30}$/;
      this.nameError = !this.validate(e, re);
    },
    email(e) {
      let re = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
      this.emailError = !this.validate(e, re);
    },
    intlPhone() {
      let re = this.intlPhone
          ? /[0-9+-\s()]/
          : /^\(?([2-9][0-8][0-9])\)?[-\s]?([2-9][0-9]{2})[-\s]?([0-9]{4})$/;
      this.phoneError = !this.validate(this.phone, re);
    },
    phone(p) {
      let re = this.intlPhone
          ? /[0-9+-\s()]/
          : /^[+1]*?\(?([2-9][0-8][0-9])\)?[-\s]?([2-9][0-9]{2})[-\s]?([0-9]{4})$/;
      this.phoneError = !this.validate(p, re);
    },
  },
  methods: {
    ...mapMutations({
      setFollowingStep: "productHelp/setFollowingStep",
      unsetNextStep: "productHelp/unsetNextStep",
      setContactInformation: "productHelp/setContactInformation",
      removeOrder: "productHelp/removeOrder",
    }),
    updateAddressDetails(order, request) {
      this.name = order.orderName || request.name;
      this.address1 = order.orderAddress1 || request.address1;
      this.address2 = order.orderAddress2 || request.address2;
      this.city = order.orderCity || request.city;
      this.state = order.orderState || request.state;
      this.zip = order.orderZip || request.zipcode;
      this.country = order.orderCountry || request.country;
      this.email = order.orderEmail || request.email;
      this.phone = order.orderPhone || request.phone;
    },
  },
  mounted() {
    this.updateAddressDetails(this.order, this.request);
    this.$refs["name"].focus();
  },
};
</script>

<style scoped>

</style>

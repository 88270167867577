<template>
  <div>
    <div class="frow column-center">
      <h4>Done</h4>
      <p>Glad we could help!</p>
      <p>more info...</p>
    </div>
  </div>
</template>

<script>
import dataModel from "./productHelpDataModel";
import mixins from './mixins';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "Information",
  mixins: [mixins],
  data() {
    return {
      dataModel,
    }
  },
  props: [
      "step",
      "node"
  ],
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapMutations({
      setFlowComplete: "productHelp/setFlowComplete"
    }),
  },
  mounted() {
    this.setFlowComplete();
  }
};
</script>

<style scoped>

</style>

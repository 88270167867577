import dataModel from '../../components/ProductHelp/productHelpDataModel';

export default {
	getAnswers(state) {
		return state.issues[state.currentIssueId] && state.issues[state.currentIssueId].answers;
	},
	getAnswer: (state) => (id) => {
		return state.issues[state.currentIssueId].answers[id];
	},
	getCurrentStep(state) {
		return state.currentStep;
	},
	getNextStep(state) {
		return state.nextStep;
	},
	getProducts(state) {
		return state.products;
	},
	getMaterialOptions(state) {
		return state.materialOptions;
	},
	getMaterial(state) {
		return state.issues[state.currentIssueId].product
			&& state.materialOptions.find(m => {
				return state.issues[state.currentIssueId].product.categories.includes(m);
			});
	},
	getProductsLoadingStatus(state) {
		return state.productsLoadingStatus;
	},
	getOrderLoadingStatus(state) {
		return state.orderLoadingStatus;
	},
	getIssuesResponseLoadingStatus(state) {
		return state.issuesResponseLoadingStatus;
	},
	getOrder(state) {
		return state.order;
	},
	getUploadsComplete(state) {
		return state.uploadsComplete;
	},
	getIssue(state) {
		return state.issues[state.currentIssueId];
	},
	getCurrentIssueId(state) {
		return state.currentIssueId;
	},
	getRequest(state) {
		return state.request;
	},
	getIssueProduct(state) {
		return state.issues[state.currentIssueId].product || {};
	},
	getMissingParts(state) {
		return state.issues[state.currentIssueId].missingParts;
	},
	getNotes(state, { text }) {
		return state.issues[state.currentIssueId].notes;
	},
	getPath(state) {
		return state.path;
	},
	getAutoContinue(state) {
		return state.currentStep
			&& dataModel.nodes[state.currentStep]
			&& dataModel.nodes[state.currentStep].meta
			&& dataModel.nodes[state.currentStep].meta.autoContinue;
	},
	getBranch(state) {
		return state.branch;
	},
	getTree(state, { tree }) {
		return state.tree;
	},
	getCSRFToken(state) {
		return state.csrfToken;
	},
	getIssuesResponse(state) {
		return state.issuesResponse;
	},
	getFlowComplete(state) {
		return state.flowComplete;
	},
	getNextFocus(state) {
		return state.nextFocus;
	},
	getPrevIssues(state) {
		// TODO fix going back from the start of a new round to the end of previous round
		// currentIssue Id is incrementing incorrectly
		const indices = state.currentIssueId - 2 >= 0 && Array.from(new Array(state.currentIssueId - 1));
		console.log("indices", indices, state.currentIssueId, state.request.issues);
		return indices
			? indices.map((v, i) => state.request.issues[i])
			: [];

	},
	getResolvedFlag(state) {
		return state.resolvedFlag;
	},
	getRequestedRemedies(state) {
		return state.request.issues.map(i => i.requestedRemedy);
	}
};

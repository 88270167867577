<template>
	<button-page :flexCard="false">
		<template slot="header">
			<span class="subtitle--line secondary">We want to get to know you.</span>
			<h1 class="h2">What's your cooking skill level?</h1>
			<p
				v-if="error"
				class="error"
			>Please select one of the skill levels.</p>
		</template>
		<template slot="buttons">
			<input
				type="radio"
				id="beginner"
				name="skill"
				v-model="value"
				value="adam"
			/>
			<label for="beginner">
				<strong>I'm a beginner.</strong>
				<p>I'm mostly new to cooking, and I'm eager to learn more.</p>
			</label>
			<input
				type="radio"
				id="intermediate"
				name="skill"
				v-model="value"
				value="megan"
			/>
			<label for="intermediate">
				<strong>I have some experience.</strong>
				<p>
					I'm comfortable cooking most recipes, and I'm ready to improve my
					craft.
				</p>
			</label>
			<input
				type="radio"
				id="professional"
				name="skill"
				v-model="value"
				value="kathy"
			/>
			<label for="professional">
				<strong>I'm a pro.</strong>
				<p>I find cooking easy, and I'm not intimidated by complex recipes.</p>
			</label>
		</template>
		<template slot="nextButton">
			<a
				:disabled="buttonDisabled"
				href="#product-wizard/collection"
				@click="validate"
				class="btn btn--border btn--arrow btn--primary borderless"
			>
				Next
			</a>
		</template>
	</button-page>
</template>

<script>
import ButtonPage from "../ButtonPage.vue";
import { mapMutations, mapGetters } from "vuex";
import { EVENTS, gtmCustomEventPush } from "../../../../analytics";
import { keyboardAccessibility } from "../utils";
export default {
	props: ["pageData", "pageNumber", "handleFocus", "usesKeyboard"],
	components: {
		ButtonPage,
	},
	data() {
		return {
			// value: "",
			error: false,
		};
	},
	computed: {
		...mapGetters({ getSkill: "wizard/getSkill" }),
		value: {
			set(skill) {
				if (skill) this.error = false;
				this.setSkill({ skill });
			},
			get() {
				return this.getSkill;
			},
		},
		buttonDisabled() {
			return !this.value;
		},
	},
	methods: {
		...mapMutations({
			setSkill: "wizard/setSkill",
			setBackButtonHash: "wizard/setBackButtonHash",
		}),
		validate($event) {
			console.log($event);
			this.error = false;
			if (!this.value) {
				$event.preventDefault();
				this.error = true;
				return;
			}

			keyboardAccessibility($event, this);
			gtmCustomEventPush({
				event: EVENTS.LIFT_DATA,
				category: "Interaction",
				label: "Product Wizard Persona",
				value: this.value,
			});
		},
	},
	mounted() {
		this.setBackButtonHash({ hash: "#product-wizard/close" });
		this.handleFocus();
	},
};
</script>

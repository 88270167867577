























import { Component, Prop, Vue } from "vue-property-decorator";
import { accountModule } from "./store/account";
import { AddAsyncUpdates } from "./mixins/helpers";

@Component({})
export default class AddToCart extends Vue {
	// public
	@Prop(String) private freeProd!: string;
	@Prop(String) private sku!: string;
	@Prop(String) private name!: string;
	@Prop({ default: 1 }) private quantity!: number;
	@Prop({ default: "" }) private buttonOverride!: string;
	@Prop({ default: "btn btn--border borderless btn--black" }) private classes!: string;
  @Prop({ default: false }) private disabled!: boolean;
	updating: boolean = false;

	@AddAsyncUpdates("updating")
	private async addToCart() {
		let productsAdded = await accountModule.addProductsToBasket([
			{
				product_id: this.sku,
				quantity: this.quantity,
			},
		]);
		this.showCartAfterUpdate();
	}

	@AddAsyncUpdates("updating")
	private async addGiftToCart() {
		let productsAdded = await accountModule.addProductsToBasket([{
				product_id: this.sku,
				quantity: this.quantity,
				gift:true,
			},]
			
		);
		this.showCartAfterUpdate();
	}

	private showCartAfterUpdate() {
		const cart = document.querySelector("#cart-app");
		if (cart) {
			this.$emit("resetQuantity");
			cart.classList.add("active");
			document.body.classList.add("prevent-scrolling");
			// @ts-ignore
			window.returnFocusTo = this.$refs.button;
			// @ts-ignore
			console.log("the returnFocusTo is", window.returnFocusTo);
			const closeBtn = cart.querySelector('button[title="Closes cart modal"]');
			if (closeBtn) {
				setTimeout(() => {
					(closeBtn as HTMLElement).focus();
				}, 200);
			}
		}
	}

	private get buttonText() {
		if (this.buttonOverride.length) return this.buttonOverride;
		return `Add To Cart`;
	}
}

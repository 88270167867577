<template>
	<button-page :flexCard="false">
		<template slot="header">
			<span class="subtitle--line secondary">Flavors</span>
			<h1 class="h2">Choose 3 more delicious looking meals.</h1>
			<p>{{ leftToChoose }}</p>
			<p
				v-if="error"
				class="error"
			>Please finish selecting options.</p>
		</template>
		<template slot="buttons">
			<div class="product-wizard--selectable tier-2">
				<image-button
					v-for="img in imagesToShow"
					:key="img.id"
					:tier="tier"
					:image="img.image"
					v-model="value"
					:initialValues="value"
					:id="img.id"
					:alt="img.alt"
				/>
			</div>
		</template>
		<template slot="nextButton">
			<a
				:disabled="disabledButton"
				:href="nextPageHash"
				@click="validate"
				class="btn btn--border btn--arrow btn--primary borderless"
			>
				Next
			</a>
		</template>
	</button-page>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import ImageButton from "../UI/ImageButton.vue";
import ButtonPage from "../ButtonPage.vue";
import { keyboardAccessibility } from "../utils";

export default {
	components: {
		ImageButton,
		ButtonPage,
	},
	props: ["pageData", "pageNumber", "handleFocus", "usesKeyboard"],
	data() {
		return {
			error: false,
			tier: 2,
		};
	},
	computed: {
		...mapState("wizard", ["collection"]),
		...mapGetters({
			getCollection: "wizard/getCollection",
			getFlavors: "wizard/getFlavorsB",
			getFlavorsByTier: "wizard/getFlavorsByTier",
			flavorValidation: "wizard/getFlavorValidation",
		}),
		disabledButton() {
			return this.value.length < 3;
		},
		nextPageHash() {
			return !this.flavorValidation
				? "#product-wizard/flavorC"
				: "#product-wizard/servings";
		},
		imagesToShow() {
			console.log(this.getCollection, this.tier);

			return this.getFlavorsByTier(this.getCollection, this.tier);
		},
		numberLeft() {
			// todo: make this work with multiple plans
			return 5 - this.value.length;
		},
		value: {
			get() {
				return this.getFlavors;
			},
			set(flavors) {
				if (flavors.length) this.error = false;
				this.setFlavors({ flavors, type: "flavorsB" });
			},
		},
		leftToChoose() {
			const numLeft = 3 - this.value.length;
			if (numLeft > 0) {
				return `Pick ${numLeft} more recipe${numLeft === 1 ? "" : "s"}`;
			}
			return `Great! You've selected your three.`;
		},
	},
	methods: {
		...mapMutations({
			setBackButtonHash: "wizard/setBackButtonHash",
			setFlavors: "wizard/setFlavors",
			setFlavorTier: "wizard/setFlavorTier",
		}),
		validate($event) {
			if (this.value.length < 3) {
				this.error = true;
				return;
			}
			this.error = false;
			keyboardAccessibility($event, this);
			// this.$emit("nextQuestion", { analytics: this.value.join("|") });
		},
	},
	created() {
		this.setFlavorTier({ tier: 2 });
		console.log(`values on creation`, this.values);
	},
	mounted() {
		if (!this.collection) {
			window.location.hash = "#product-wizard/collection";
		}
		this.setBackButtonHash({ hash: "#product-wizard/flavor" });
		if (this.usesKeyboard) {
			this.handleFocus();
		}
	},
};
</script>

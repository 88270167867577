const EMPTY_STATE = 'emptyState';

module.exports = {
	install(Vue, options = {}) {
		if (!Vue._installedPlugins.find(plugin => plugin.Store)) {
			throw new Error("VuexUndoRedo plugin must be installed after the Vuex plugin.")
		}
		Vue.mixin({
			data() {
				return {
					done: [],
					undone: [],
					newMutation: true,
					ignoreMutations: options.ignoreMutations|| [],
					replaying: false,
				};
			},
			created() {
				if (this.$store) {
					this.$store.subscribe(mutation => {
						if (this.newMutation
							&& mutation.type !== `${options.module ? `${options.module}/` : ''}${EMPTY_STATE}`
							&& (!options.module || mutation.type.includes(options.module))
							&& this.ignoreMutations.indexOf(mutation.type) === -1
						) {
							// reset the replay variable
							this.replaying = false;
							this.done.push(mutation);
						}
						if (this.newMutation) {
							this.undone = [];
						}
					});
				}
			},
			computed: {
				canRedo() {
					return this.undone.length;
				},
				canUndo() {
					return this.done.length;
				},
			},
			methods: {
				redo() {
					// TODO update this to incorporate the changes from undo if we need it
					// let commit = this.undone.pop();
					// this.newMutation = false;
					// switch (typeof commit.payload) {
					// 	case 'object':
					// 		this.$store.commit(`${commit.type}`, Object.assign({}, commit.payload));
					// 		break;
					// 	default:
					// 		this.$store.commit(`${commit.type}`, commit.payload);
					// }
					// this.newMutation = true;
				},
				undo() {
					// console.time("undo");
					// push all the mutations back to but not including the undoDelimiter
					// if there is one
					if (options.undoDelimiter) {
						let undoing = true;
						while (undoing) {
							undoing = !this.done[this.done.length - 1].type.includes(options.undoDelimiter);
							this.undone.push(this.done.pop());
						}
					} else {
						this.undone.push(this.done.pop());
					}
					this.replaying = true;
					this.newMutation = false;
					this.$store.commit(`${options.module ? `${options.module}/` : ''}${EMPTY_STATE}`);
					this.done.forEach(mutation => {
						switch (typeof mutation.payload) {
							case 'object':
								this.$store.commit(`${mutation.type}`, Object.assign({}, mutation.payload));
								break;
							default:
								this.$store.commit(`${mutation.type}`, mutation.payload);
						}
						// this.done.pop();
					});
					this.newMutation = true;
					// console.timeEnd("undo");
				}
			}
		});
	},
}

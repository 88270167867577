<template>
	<div class="quiz--result">
		<div class="frow-container">
			<div class="frow gutters card-container">
				<div class="card-container-inner">
					<div
						class="quiz--card-wrapper"
						v-for="(result, idx) in resultData"
						:key="idx"
					>
						<div class="card primary recipe-card card-normal card-quiz">
							<div class="image-holder">
								<img :src="result.image" :alt="`Image of ${result.title}`" />
							</div>
							<div class="card-title">
								<a class="primary-hover" :href="result.href">
									<h4 class="h7 title">{{ result.title }}</h4>
									<span class="link-text"> View this {{ result.type }} </span>
								</a>
							</div>
						</div>
						<a
							class="size-small btn btn--link color--secondary"
							:href="result.all"
							>View all {{ result.type }}s</a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["choices", "page"],
	data() {
		return {
			results: [
				{
					image: "https://placehold.it/360x200",
					title: "Result Page Title",
					type: "Recipe",
					href: "https://google.com",
					all: "https://duckduckgo.com",
				},
				{
					image: "https://placehold.it/360x200",
					title: "Result Page Title #2",
					type: "Story",
					href: "https://google.com",
					all: "https://duckduckgo.com",
				},
			],
		};
	},
	computed: {
		resultData() {
			if (this.page.data[`result-${this.choices}`]) {
				return this.page.data[`result-${this.choices}`];
			}
			return [];
		},
	},
};
</script>
//http://lodgemfgode3.prod.acquia-sites.com/api/lodge/salesforce/products
import { queryParams } from "../../../utils";


const Getters = {
	queryValues(state) {
		return `text=${state.filterText}&page=${state.page}${state.navigatedRecipe ? `&r=${state.navigatedRecipe}`: ''}`;
	},
	getColors(state) {
		return state.colors;
	},
	getQueryParams(state) {
		return queryParams();
	},
	getSelectedFilters(state) {
		return state.selectedFilters;
	},
	getPage(state) {
		return state.page;
	},
	getNavigatedRecipe(state) {
		return state.navigatedRecipe;
	},
	getRecipes(state) {
		return state.recipes;
	},
	getLoadingState(state) {
		return state.loadingState;
	},
	getFilterText(state) {
		return state.filterText;
	}
};

export default Getters;

<template>
	<div
		@click="onClick"
		@keypress.enter="onClick"
		@keypress.space="onClick"
		class="product-wizard--image-button"
		:class="{ checked }"
		tabindex="0"
    role="button"
	>
		<div>
			<div><img
					:src="image"
					:alt="alt"
				/></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		tier: {
			type: Number,
		},
		id: {
			type: String,
			default: "Unknown Recipe",
		},
		image: {
			type: String,
			default: "https://placehold.it/125x125",
		},
		alt: {
			type: String,
			default: "Unknown Recipe Alt Text",
		},
		productType: {
			type: String,
			default: "unknown",
		},
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			checked: false,
			initialSet: false,
			tierAmount: {
				1: 5,
				2: 3,
				3: 1,
			},
		};
	},
	created() {
		if (this.value.includes(this.id)) {
			console.log(`${this.id} is being set to true on creation`);
			this.checked = true;
		}
	},
	methods: {
		onClick(e) {
			const id = this.id;
			const arr = [].concat(this.value);
			const checked = arr.includes(id);

			if (checked || this.value.length < this.tierAmount[this.tier]) {
				this.checked = !this.checked;
				if (checked) {
					arr.splice(arr.indexOf(id), 1);
				} else {
					arr.push(id);
				}
				this.$emit("input", arr);
			}
		},
	},
};
</script>

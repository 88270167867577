//http://lodgemfgode3.prod.acquia-sites.com/api/lodge/salesforce/products

import { queryParams } from "../../../utils.js";
import { validProductProperties } from "../../../utils.js";
function validFilterKey(key) {
	return validProductProperties.includes(key);
}

/**
 * @type {ProductListingState}
 */
let qp = queryParams();
const State = {
	loadingState: "idle",
	products: [],
	colors: [],
	selectedFilters: Object.keys(qp)
		.filter(validFilterKey)
		.reduce((acc, key) => {
			return acc.concat(qp[key].split("|").map(val => `${key}:${val}`));
		}, []),
	querySeparator: "|",
	page: (qp && qp["page"] && parseInt(qp["page"], 10)) || 1,
	navigatedProduct: (qp && qp["p"]) || "",
};

export default State;

import Page1 from "./Pages/Page1.vue";
import Page2 from "./Pages/Page2.vue";
import Page3 from "./Pages/Page3.vue";
import Page3B from "./Pages/Page3B.vue";
import Page3C from "./Pages/Page3C.vue";
import Page4 from "./Pages/Page4.vue";
import Page5 from "./Pages/Page5.vue";
import Results from "./Pages/Results.vue";

export default {
	"#product-wizard/skill": Page1,
	"#product-wizard/collection": Page2,
	"#product-wizard/flavor": Page3,
	"#product-wizard/flavorB": Page3B,
	"#product-wizard/flavorC": Page3C,
	"#product-wizard/servings": Page4,
	"#product-wizard/leftovers": Page5,
	"#product-wizard/results": Results,
};

//http://lodgemfgode3.prod.acquia-sites.com/api/lodge/salesforce/products

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

/**
 * @type {import("vuex").Module}
 */
const Module = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

export default Module;

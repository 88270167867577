<template>
  <div class="order-finder">
    <form class="frow column-start" aria-label="Order Finder">
      <label class="secondary-highlight" for="search">Enter order information</label>
      <div class="floating-label--wrap">
        <input class="floating-label--field" id="order-number"
               ref="order-number"
               type="text"
               v-model.trim="orderNumber"
               placeholder="Order Number"
               @keyup.enter.prevent="findOrder">
        <label class="floating-label" for="order-number">Lodge Order Number</label>
      </div>
      <div class="floating-label--wrap">
        <input class="floating-label--field" id="order-email"
               type="text"
               v-model.trim="email"
               placeholder="Order Email"
               @keyup.enter.prevent="findOrder">
        <label class="floating-label" for="order-email">Order Email</label>
        <div class="error" v-show="emailError && email">
          Please enter a valid email address
        </div>
      </div>
      <div class="floating-label--wrap">
        <input class="floating-label--field" id="order-zip"
               type="text"
               v-model.trim="zip"
               placeholder="Order Zip Code"
               @keyup.enter.prevent="findOrder">
        <label class="floating-label" for="order-zip">Billing Order Zip Code</label>
        <div class="error" v-show="zipError && zip">
          Please enter a valid zip code
        </div>
      </div>

    </form>
    <div class="frow inline">
      <button :disabled="!findEnabled" class="btn btn-primary borderless px-4 mt-4"
              @click.enter="findOrder"
      >Find Order
        <div v-show="orderLoadingStatus === 'ORDER_LOADING'" class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </button>
    </div>
    <div class="frow justify-start mt-2">
      <div v-if="error" class="error">
        There was a problem getting the order...
        Please check that the order number is correct, check for Os instead of zeroes.
        Make sure you have the email and zip code used on the order.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import mixins from './mixins';

export default {
  name: "orderChooser",
  mixins: [mixins],
  data() {
    return {
      orderNumber: "",
      email: "",
      zip: "",
      error: false,
      emailError: false,
      zipError: false
    };
  },
  computed: {
    ...mapGetters({
      order: "productHelp/getOrder",
      orderLoadingStatus: "productHelp/getOrderLoadingStatus",
      currentIssueId: "productHelp/getCurrentIssueId",
      csrfToken: "productHelp/getCSRFToken",
    }),
    findEnabled() {
      return !this.emailError && !this.zipError && this.email && this.zip && this.orderNumber;
    }
  },
  methods: {
    ...mapActions({
      getOrder: "productHelp/getOrder",
    }),
    ...mapMutations({
    }),
    findOrder() {
      if (this.findEnabled) {
        this.getOrder({
          orderNumber: this.orderNumber,
          email: this.email,
          zip: this.zip,
          csrfToken: this.csrfToken,
        })
            .then(data => {
              if (data.error) {
                this.error = true;
              }
            })
            .catch(error => {
              console.log("Error getting the order", error);
            });
      }
    }
  },
  watch: {
    email(e) {
      let re = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
      this.emailError = !this.validate(e, re);
    },
    zip(e) {
      let re = /^\d{5}([\-\s]?\d{4})?$/;
      this.zipError = !this.validate(e, re);
    }
  },
  mounted() {
    if (this.order && this.order.orderNumber) {
      this.orderNumber = this.order.orderNumber;
      this.email = this.order.orderEmail;
      this.zip = this.order.orderZip;
    } else {
      this.$refs["order-number"].focus();
    }
  }
};
</script>

<style scoped>

</style>

const brand = "Lodge Cast Iron";

/**
 * A GTM object
 * @typedef {{
 * 	event: string,
 * 	category?: string,
 * 	label?: string,
 * 	value: string,
 * }} GTMObject
 */

/**
 * Enforces custom events being there
 * @param {GTMObject} data
 */
export const gtmCustomEventPush = (data) => {
	if (!data.event) console.error("No name given for Custom Event");
	gtmPush(data);
};

/**
 * Enforces custom events being there
 * @param {GTMObject} data
 */
export const gtmPush = (data) => {
	if (window.dataLayer) {
		console.log("GTM data was sent as follows:", data);
		window.dataLayer.push(data);
	} else {
		console.warn(
			"GTM does not exist or is not enabled on this environment. The following data would have been sent:",
		);
		console.log(data);
	}
};

export const massageProducts = (product, userOpt = null) => {
	const { variations } = product;

	// Set up basic option object
	let opt = {
		variant: false,
		showPosition: false,
		offset: 0,
	};

	// Merge options if required w/ the defaults being overwritten
	if (userOpt) {
		opt = Object.assign({}, opt, userOpt);
	}

	return variations.map((p, idx) => {
		if (!p.sku) console.warn("Product has no SKU for GTM");

		const obj = {
			id: p.sku || `No SKU for ${p.title}`,
			name: p.title || product.title,
			price: p.price,
			brand,
		};

		if (opt.variant) {
			obj.variant = [
				p.shape,
				p.color || (p.attributes && p.attributes.attribute_color),
				p.material,
				p.size || (p.attributes && p.attributes.attribute_size),
			]
				.filter((i) => i)
				.reduce((acc, curr) => {
					return (acc += `${acc.length === 0 ? "" : "|"}${curr}`);
				}, "")
				.toLowerCase();
		}

		if (opt.showPosition) {
			obj.position = opt.offset + idx;
		}

		if (p.category) {
			obj.category = p.category;
		}

		return obj;
	});
};

/**
 * Creates the impression data structure based off an array of products
 * @param {Product[]} products
 * @param {string} filterList
 */
export const massageImpressions = (products, filterList = "") => {
	console.log(filterList);
	return products.map((p, idx) => {
		const obj = {
			name: p.title,
			brand,
			position: idx,
			list: `Shop All - ${
				filterList ? decodeURIComponent(filterList) : "No Filters"
			}`,
		};

		if (p.variations && p.variations.length) {
			obj.price = p.variations[0].price;

			const category = p.variations.reduce((acc, curr) => {
				return !curr.category
					? acc
					: (acc += `${acc.length === 0 ? "" : ","}${curr.category}`);
			}, "");

			if (category) {
				obj.category = category;
			}
		}

		return obj;
	});
};

export const productClick = (product, el, variant = null, price = null) => {
	console.log(123, price);
	const massagedProduct = {
		name: product.title,
		brand,
		price: price ? price : product.variations[0].price,
		position: [...el.parentNode.children].findIndex((c) => c === el),
		variant: variant ? variant : "N/A",
	};

	gtmCustomEventPush({
		event: "ProductClick",
		ecommerce: {
			actionField: { list: "Shop All Page" },
			products: [massagedProduct],
		},
	});
};

export const gtmCartAdd = (product, quantity, productItem = {}) => {
	cartProduct(product, quantity, {
		event: "AddToCart",
		type: "add",
		productItem,
	});
};

export const gtmCartRemove = (product, quantity, productItem = {}) => {
	cartProduct(product, quantity, {
		event: "RemoveFromCart",
		type: "remove",
		productItem,
	});
};

export const cartProduct = (
	product,
	quantity,
	{ event, type, productItem = {} },
) => {
	gtmCustomEventPush({
		event,
		ecommerce: {
			[type]: {
				products: [
					{
						name: product.title || product.name || product.product_name,
						id: product.sku || product.id || product.product_id,
						brand,
						price: product.price || productItem.price,
						quantity,
					},
				],
			},
		},
	});
};
export const ecommerceDetail = (product) => {
	const products = massageProducts(
		{ variations: [product] },
		{ variant: true },
	);
	gtmCustomEventPush({
		event: "ProductDetailView",
		ecommerce: {
			detail: {
				actionField: {
					list: "Product Detail Page",
				},
				products,
			},
		},
	});
};

const sfraGetItemInfo = (el) => {
	const row = el.closest(".product-info");
	const productInfo = {};

	const title = row.querySelector(".item-attributes .h7");
	if (title) productInfo.title = title.innerText;

	const sku = row
		.querySelector(".item-attributes .size-mini")
		.innerText.split("SKU: ")[1];
	if (sku) productInfo.id = sku;

	const price = row.querySelector(".sales .value");
	if (price) productInfo.price = price.getAttribute("content");

	const quantity = row.querySelector(".quantity-form select");
	if (quantity) productInfo.quantity = quantity.value;

	return productInfo;
};

const sfraGetQuickAddInfo = (el) => {
	const row = el.closest(".recommendation.product-detail");
	const sku = row.getAttribute("data-pid");
	const title = row.querySelector(".name").innerText;
	const price = row.querySelector(".sales .value").getAttribute("content");

	return {
		id: sku,
		name: title,
		quantity: 1,
		price,
	};
};

export const sfraQuickAddCartEvents = () => {
	const buttons = document.querySelectorAll(
		".recommendation.product-detail .add-to-cart-global",
	);
	console.log("quick add", buttons);
	buttons.forEach((el) => {
		console.log("Quick Add", el);
		el.addEventListener("click", (_) => {
			const productItem = sfraGetQuickAddInfo(el);
			cartProduct(productItem, productItem.quantity, {
				event: "AddToCart",
				type: "add",
			});
		});
	});
};
export const sfraRemoveFromCartEvents = () => {
	const buttons = document.querySelectorAll(".remove-product.btn");
	console.log("remove buttons", buttons);
	buttons.forEach((el) => {
		el.addEventListener("click", (e) => {
			console.log("Remove From Cart", el);
			const productItem = sfraGetItemInfo(el);
			cartProduct(productItem, productItem.quantity, {
				event: "RemoveFromCart",
				type: "remove",
			});
		});
	});
};
export const sfraUpdateCartQuantity = () => {
	const selects = document.querySelectorAll("select.quantity.custom-select");
	console.log(selects);
	selects.forEach((el) => {
		const value = el.value;
		el.parentNode.setAttribute("gtm-product-count", value);
		el.addEventListener("change", (e) => {
			console.log("Changing select", el);
			const newValue = parseInt(el.value, 10);
			const oldValue = parseInt(
				el.parentNode.getAttribute("gtm-product-count"),
				10,
			);
			el.parentNode.setAttribute("gtm-product-count", newValue);
			const wasAnAddition = newValue - oldValue > 0;
			const productItem = sfraGetItemInfo(el);
			cartProduct(productItem, productItem.quantity, {
				event: wasAnAddition ? "AddToCart" : "RemoveFromCart",
				type: wasAnAddition ? "add" : "remove",
			});
		});
	});
};

export const sfraMutationObserverForPaypal = () => {
	const paypalCustomEvent = (e) => {
		if (e.data) {
			try {
				const _data = JSON.parse(e.data);
				const messageName =
					_data && _data.__postRobot__ && _data.__postRobot__.data
						? _data.__postRobot__.data.name
						: "UnknownStep";
				if (messageName === "onAuthorize") {
					gtmCustomEventPush({
						event: EVENTS.PAYPAL_AUTHORIZED,
					});
				}
			} catch (err) {
				// do nothing
			}
		}
	};
	window.addEventListener("message", paypalCustomEvent);
};

export const EVENTS = {
	BOTTOM_PROMO: "BOTTOM_PROMO",
	HERO_VIDEO: "HERO_VIDEO",
	HERO_VIDEO_TIMING: "HERO_VIDEO_TIMING",
	STANDALONE_CHAT_NOW: "STANDALONE_CHAT_NOW",
	CUSTOMER_RECOMMENDATION_FAILURE: "CustomerRecommendationFailure",
	ASSOCIATED_PRODUCT_FAILURE: "AssociatedProductFailure",
	PRODUCT_FAILURE: "ProductFailure",
	PAYPAL_AUTHORIZED: "PaypalAuthorized",
	BACK_IN_STOCK_NOTIFICATION: "BackInStockNotification",
	SEEN_OUT_OF_STOCK: "SeenOutOfStock",
	DISTRIBUTOR_SEARCH_FAILURE: "DistributorSearchFailure",
	DISTRIBUTOR_SEARCH_SELECT: "DistributorSearchSelect",
	DISTRIBUTOR_SEARCH_DEEPLINK: "DistributorSearchDeeplink",
	QUICK_ANSWER_OPEN: "QuickAnswerDeepOpen",
	QUICK_ANSWER_SEEN: "QuickAnswerSeen",
	WIZARD_PERSONA: "WizardPersona",
	WIZARD_PAGE_VIEW: "WizardPageView",
	WIZARD_RESULTS: "WizardResults",
	WIZARD_RECOMMENDATIONS: "WizardRecommendations",
	LIFT_DATA: "liftData",
};

export const ani = (...args) => Object.assign({}, ...args);
import { Power3, Power1 } from "gsap";

export const paused = true;
export const DEFAULT_TIME_SHORT = 0.2;
export const DEFAULT_TIME = 1;
export const DEFAULT_STAGGER = 0.1;
export const DEFAULT_EASE = { ease: Power1.easeOut };

// Delays
export const SUBTLE_DELAY = 0.4;
export const NOT_SUBTLE_DELAY = 0.6;
// Opacity
export const START_OPACITY = { opacity: 0 };
export const FINISH_OPACITY = { opacity: 1 };

// Offsets
export const START_Y_OFFSET = { y: 20 };
export const FINISH_Y_OFFSET = { y: 0 };

export const DEFAULT_OPTIONS = {
	DEFAULT_STAGGER,
};
export const COLORS = {
	brandPrimary: "#ffd846",
	brandWhite: "#f6f6f6",
	black: "#000",
	white: "#fff",
};
export const ANIMATION_DEFAULTS = {
	paused,
	DEFAULT_TIME,
	DEFAULT_TIME_SHORT,
	DEFAULT_STAGGER,
	COLORS,
};

export const ANIMATION_MOVEMENT = {
	OFFSET: {
		START_Y_OFFSET,
		FINISH_Y_OFFSET,
	},
	OPACITY: { START_OPACITY, FINISH_OPACITY },
};

export const SIMPLE_ANIMATION_START = ani(
	START_Y_OFFSET,
	START_OPACITY,
	DEFAULT_EASE,
);
export const SIMPLE_ANIMATION_FINISH = ani(
	FINISH_Y_OFFSET,
	FINISH_OPACITY,
	DEFAULT_EASE,
);

<template>
	<div
		v-show="loggedIn"
		tabindex="0"
		class="top-right-icon btn btn--round-icon"
		:class="{
			backgroundless: inSocialBar,
			toasted: toasterStatus,
			processing: processing,
		}"
		data-bookmarkable="true"
		data-bookmark-id="431431413"
		@click.stop="setStatus"
		@keydown.enter.stop="setStatus"
	>
		<span role="presentation" data-icon="bookmark" :class="['icon', iconStatus]"></span>
		<span class="sr-only">{{ screenreaderText }} {{ title }}</span>
		<div
			class="toaster frow row-center"
			:class="{ show: toasterStatus, active: isBookmarkedRecipe }"
		>
			<div v-if="processing" class="sr-only">Processing request</div>
			<div v-show="toasterStatus !== ''" class="toast">
				{{ toasterMessages[toasterStatus] }}
			</div>
		</div>
	</div>
</template>

<script>
import regeneratorRuntime from "regenerator-runtime";

import { accountModule } from "../../../lodge-vue/src/store/account";

export default {
	data() {
		return {
			status: false,
			processing: false,
			toasterStatus: "",
			toasterReset: false,
			accountLink:
				this.env === "sfcc"
					? "/account"
					: window.drupalSettings &&
					  window.drupalSettings.lodge &&
					  window.drupalSettings.lodge.sfcc &&
					  window.drupalSettings.lodge.sfcc + "/account",
			toasterMessages: {
				removed: "Removed from Cookbook!",
				added: "Saved to Cookbook!",
				login: "Log in to save to Cookbook.",
				error: "Sorry, there was a problem saving. Try again.",
				loggedout: "Sorry your session has expired. Please log in again.",
			},
		};
	},
	props: ["title", "id", "icon", "type", "socialBar", "env"],
	computed: {
		loggedIn() {
			return this.$store.state.likes.loggedIn;
		},
		inSocialBar() {
			return this.socialBar;
		},
		iconStatus() {
			const isInArray =
				this.type === "product"
					? this.$store.state.likes.likedIDs.indexOf(parseInt(this.id, 10)) >= 0
					: this.isBookmarkedRecipe;
			return `icon-${this.icon ? this.icon : "bookmark"}-${
				isInArray ? "full" : "empty"
			}`;
		},
		customerRecipes() {
			return this.$store.state.accountModule.customer.c_cookbookRecipes || [];
		},
		customerLoggedIn() {
			return this.env === "sfcc"
				? true
				: this.$store.state.accountModule.customerLoggedIn;
		},
		isBookmarkedRecipe() {
			return (
				this.customerRecipes &&
				this.customerRecipes.length > 0 &&
				this.customerRecipes.indexOf(this.id) >= 0
			);
		},
		screenreaderText() {
			switch (this.type) {
				case "product":
					return "Like";
				case "recipe":
					return "Save";
				default:
					return "Interact with";
			}
		},
	},
	methods: {
		resetToaster() {
			this.processing = false;
			this.toasterStatus = "";
			setTimeout(() => {
				this.$el.blur();
			}, 750);
		},
		async setStatus(e) {
			e.preventDefault();
			if (this.type === "product") {
				// TODO handle saving products
			} else {
				if (
					this.toasterStatus === "login" ||
					this.toasterStatus === "loggedout"
				) {
					window.location.href = this.accountLink;
					return;
				} else if (this.toasterStatus) {
					this.resetToaster();
					clearTimeout(this.toasterReset);
					return;
				} else {
					if (this.customerLoggedIn) {
						this.processing = true;
						try {
							let updatedRecipeList = this.isBookmarkedRecipe
								? this.customerRecipes.filter(r => r !== this.id)
								: this.customerRecipes.concat(this.id);
							let status = this.isBookmarkedRecipe ? "removed" : "added";
							let customer = await accountModule.updateCustomerRecipeList({
								recipeList: updatedRecipeList,
								env: this.env,
							});
							this.processing = false;
							this.toasterStatus = status;
							console.log("customer response from updating recipe list");
							console.table(customer);
						} catch (e) {
							if (
								e.response &&
								e.response.fault &&
								(e.response.fault.type === "CustomerNotFoundException" ||
									e.response.fault.type === "ExpiredTokenException")
							) {
								// handle the case where the user needs to login again
								this.toasterStatus = "loggedout";
							} else {
								this.toasterStatus = "error";
							}
							this.processing = false;
						}
					} else {
						this.toasterStatus = "login";
					}
					this.toasterReset = setTimeout(() => {
						this.resetToaster();
					}, 10000);
				}
			}
		},
	},
};
</script>

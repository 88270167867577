export default class BaseLodgeIntent {
	constructor({ intentName, subscribesTo, prettyName, negatedBy }) {
		this.intentName = intentName;
		this.prettyName = prettyName || intentName;
		this.subscribesTo = subscribesTo ? subscribesTo : [];
		this.negatedBy = negatedBy ? negatedBy : [];
	}

	classify() {
		console.warn(`Intent ${name} cannot classify.`);
		return false;
	}
}

<template>
	<div class="quiz--page frow-container">
		<div class="frow gutters">
			<div class="col-md-1-2">
				<TitleContent :page="page"></TitleContent>
			</div>
			<div class="col-md-1-2">
				<ButtonSelection
					@nextWithData="nextWithData"
					v-if="page.type === 'page'"
					:buttons="page.buttons"
					:multiselect="page.multiselect"
				></ButtonSelection>
				<Result :page="page" :choices="choicesResults" v-else></Result>
			</div>
		</div>
	</div>
</template>

<script>
import TitleContent from "./Title";
import ButtonSelection from "./ButtonSelection";
import Result from "./Result";

export default {
	components: {
		TitleContent,
		ButtonSelection,
		Result,
	},
	props: {
		page: {
			type: Object,
			default: () => ({}),
		},
		movement: {
			type: Object,
		},
		choices: {
			type: Array,
			default: () => [],
		},
		choicesResults: {
			type: Number,
			default: 0,
		},
	},
	computed: {},
	methods: {
		nextWithData(data) {
			console.log("Data at page level: ", data);
			this.movement.next(data);
		},
		next() {
			console.log("NEXT");
			this.movement.next();
		},
		prev() {
			this.movement.prev();
		},
	},
};
</script>
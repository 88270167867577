import UserClassifier from "./UserClassifier";
import { baseState, reducer, CLASSIFIER_TYPES as CT } from "./classifierUtils";
import RecipeChef from "./intents/RecipeChef";
import CaringOwner from "./intents/CaringOwner";
import PowerBuyer from "./intents/PowerBuyer";
import UnconfidentUser from "./intents/UnconfidentUser";
import InfluencedMogul from "./intents/InfluencedMogul";

const USER_CLASSIFIER_DEBUGGER = true;
const userClassifier = new UserClassifier({ state: baseState, reducer });
userClassifier.registerIntents(
	[RecipeChef, CaringOwner, PowerBuyer, UnconfidentUser, InfluencedMogul],
	{ debug: USER_CLASSIFIER_DEBUGGER },
);

userClassifier.addDataPoint({
	type: CT.PAGE_VIEW,
	data: {
		path: location.pathname,
		queryString: location.search,
	},
});

if (USER_CLASSIFIER_DEBUGGER) {
	window.lodge_user_classifier = userClassifier;
}
export const CLASSIFIER_TYPES = CT;
export default userClassifier;

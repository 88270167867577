export default {
	setSkill(state, { skill }) {
		state.skill = skill;
	},
	setCollection(state, { collection }) {
		state.collection = collection;
	},
	setFlavors(state, { flavors, type }) {
		state[type] = flavors;
	},
	setFlavorKey(state, { flavorKey }) {
		state.flavorKey = flavorKey;
	},
	setFlavorTier(state, { tier }) {
		state.flavorTier = tier;
	},
	setServings(state, { servings }) {
		state.servings = servings;
	},
	setLeftovers(state, { leftovers }) {
		state.leftovers = leftovers;
	},
	setActivePage(state, { page }) {
		state.activePage = page;
	},
	setAnswers(state, { answers, products }) {
		state.answers = answers;
		state.products = products;
	},
	setPictures(state, { pictures }) {
		state.flavorPictures = pictures;
		state.flavorPicturesLoaded = true;
	},
	setAnswersLoadingStatus(state, { status }) {
		state.answersLoadingStatus = status;
	},
	restart(state) {
		state.skill = "";
		state.sawResults = false;
		state.activePage = 0;
		state.collection = "";
		state.flavorTier = 1;
		state.flavorKey = "";
		state.flavors = [];
		state.flavorsB = [];
		state.flavorsC = [];
		state.servings = "";
		state.leftovers = "";
	},
	setBackButtonHash(state, { hash }) {
		state.backButtonHash = hash;
	},
};

<template>
  <div>
    <h1 class="h2">{{ node.label }}</h1>
    <div class="parts">
      <div class="products col-xs-1-1 col-md-8-12">
        <div v-for="(product, index) in parts"
             :key="product.id"
             :data-index="index"
             :class="{active: selectedPartsIndices[index + 1]}"
             class="frow justify-start pt-2 pb-4 mb-4 product">
          <div class="frow justify-between w-100 px-4 items-center">
            <div class="frow justify-start items-center product-detail">
              <img class="product-image mr-4" :src="product.image" :alt="product.name"/>
              <div class="name"><strong>{{ product.name }}</strong><br>
                <span class="sku">SKU: {{ product.id }}</span>
              </div>
            </div>
            <button
                :ref="`option-${index + 1}`"
                class="btn btn--primary btn--border borderless w-auto px-4"
                @click="handleMissingPart(product, index + 1)">
              {{ selectedPartsIndices[index + 1] ? "Deselect" : "Select" }}
            </button>
          </div>
        </div>
        <div class="frow justify-start items-center">
          <div><strong>Other:</strong> (if your part is not listed above write it in here)<br>
            <input ref="other-part" @keyup.prevent="handleOtherPart" type="text"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "../../../utils";
import { mapGetters, mapMutations } from 'vuex';
import mixins from './mixins';

export default {
  name: "PartsMissing",
  mixins: [mixins],
  data() {
    return {
      missingParts: [],
      selectedPartsIndices: [],
    };
  },
  props: [
    "step",
    "node",
  ],
  computed: {
    ...mapGetters({
      product: "productHelp/getIssueProduct",
    }),
    parts() {
      return this.product && this.product.parts;
    },
  },
  methods: {
    ...mapMutations({
      updateMissingParts: "productHelp/updateMissingParts",
      setFollowingStep: "productHelp/setFollowingStep",
      unsetNextStep: "productHelp/unsetNextStep",
    }),
    handleMissingPart(product, i) {
      console.log(product, i);
      if (this.missingParts[i]) {
        this.$set(this.missingParts, i, null);
        this.$set(this.selectedPartsIndices, i, false);
      } else {
        this.$set(this.missingParts, i, product);
        this.$set(this.selectedPartsIndices, i, true);
      }
    },
    handleOtherPart: debounce(function (e) {
      let name = e.target.value;
      if (name.length > 0) {
        this.$set(this.missingParts, 0, {
          name,
        });
      } else {
        this.$set(this.missingParts, 0, false);
      }
    }, 250, false),
  },
  watch: {
    missingParts(p) {
      console.log("watch missing parts", p);

      let parts = p.filter(p => p);
      if (parts.length > 0) {
        this.setFollowingStep();
      } else {
        this.unsetNextStep();
      }
      this.updateMissingParts({ parts });
    },
  },
  mounted() {
    if (this.product.parts && this.$refs["option-1"]) {
      this.$refs["option-1"][0].focus();
    } else {
      this.$refs["other-part"].focus();
    }
  },
};
</script>

<style scoped>

</style>

<template>
	<div class="product-wizard--content">
		<div class="product-wizard--question wizard-gutters pattern--base">
			<slot name="header"></slot>
		</div>
		<div class="product-wizard--card">
			<div
				class="product-wizard--card-choices wizard-gutters"
				:class="{ 'flex-card': flexCard }"
			>
				<slot name="buttons"></slot>
			</div>
			<div class="product-wizard--card-next wizard-gutters pattern--black">
				<slot name="nextButton">
					<a
						href="#product-wizard"
						@click.prevent="validate(hash)"
						@keydown.enter.prevent="validate(hash)"
						class="btn btn--border btn--arrow btn--primary borderless"
					>
						Next
					</a>
				</slot>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	props: ["flexCard"],
	computed: {
		...mapGetters({
			activePage: "wizard/getActivePage",
		}),
		formLabel() {
			return `Product Wizard - Page ${this.activePage + 1}`;
		},
	},
	methods: {
		validate() {
			this.$emit("validate", { hash: this.hash || "#404" });
		},
		tabToStart(evt) {
			const nextFocusEl = this.$el.querySelector("input, a, [tabindex]");
			nextFocusEl.focus();
		},
	},
};
</script>
<template>
	<div class="product-wizard--top-choice-content controlled-width animatable">
		<!-- <div class="top-info wizard-gutters">
			<strong class="h3 color--white"
				><span class="color--primary">We recommend:</span> the skillet</strong
			>
			<p>
				Use Lodge skillets on the stovetop, in the oven, on the grill, and even
				over an open flame. Make everything from eggs and bacon to one-pan
				meals, roasted veggies, tasty desserts, and so much more.
			</p>
		</div> -->
		<carousel :images="result.images" :available="result.available" />
		<div class="wizard-gutters">
			<div class="bottom-info">
				<h2 class="h5">{{ result.name }}</h2>
				<span class="color--primary h7">${{ result.price }}</span>
				<p class="size-small" v-html="result.description"></p>
				<p class="benefits-text h7">Benefits</p>
				<div class="size-small" v-html="result.features"></div>
			</div>
			<div class="product-wizard--buttons">
				<a
					class="btn btn--border btn--arrow btn--primary borderless"
					:href="`/product/${result.masterId}?sku=${result.sku}`"
					>View Product</a
				>
				<AddToCart
					v-if="result.available"
					classes="btn btn--link color--primary"
					:sku="result.sku"
					:quantity="1"
				/>
			</div>
		</div>
		<Arrow class="top-choice-scroll-down" />
	</div>
</template>

<script>
import Carousel from "./Carousel.vue";
import AddToCart from "../../../../lodge-vue/src/AddToCart.vue";
import Arrow from "./UI/BackArrow";

export default {
	components: { Carousel, AddToCart, Arrow },
	props: ["result"],
};
</script>
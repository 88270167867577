//http://lodgemfgode3.prod.acquia-sites.com/api/lodge/salesforce/products
import { validProductProperties, queryParams } from "../../../utils";

function _filteredProducts(selectedFilters, filterableProducts) {
	if (selectedFilters.length == 0) return filterableProducts;
	//const p1 = performance.now();
	const sListHash = selectedFilters.reduce(
		(hash, f) => {
			if (f.includes("Material")) hash.Materials.push(f);
			else if (f.includes("Type")) hash.Types.push(f);
			else if (f.includes("Size")) hash.Sizes.push(f);
			else if (f.includes("Color")) hash.Colors.push(f);
			else if (f.includes("Shape")) hash.Shapes.push(f);
			else if (f.includes("Collection")) hash.Collections.push(f);
			else if (f.includes("Sale")) hash.Sales.push(f);
			return hash;
		},
		{
			Materials: [],
			Types: [],
			Sizes: [],
			Colors: [],
			Shapes: [],
			Collections: [],
			Sales: [],
		},
	);
	//const p2 = performance.now();
	//console.log(`sListHash took ${p2 - p1} milliseconds.`);
	//const pr1 = performance.now();
	const result = filterableProducts.filter(p => {
		//const p3 = performance.now();
		const pListHash = p.filterList.reduce(
			(hash, f) => {
				if (f.indexOf("Material") != -1) hash.Materials.push(f);
				else if (f.indexOf("Type") != -1) hash.Types.push(f);
				else if (f.indexOf("Size") != -1) hash.Sizes.push(f);
				else if (f.indexOf("Color") != -1) hash.Colors.push(f);
				else if (f.indexOf("Shape") != -1) hash.Shapes.push(f);
				else if (f.indexOf("Collection") != -1) hash.Collections.push(f);
				else if (f.indexOf("Sale") != -1) hash.Sales.push(f);
				return hash;
			},
			{
				Materials: [],
				Types: [],
				Sizes: [],
				Colors: [],
				Shapes: [],
				Collections: [],
				Sales: [],
			},
		);
		//const p4 = performance.now();
		//console.log(`pListHash took ${p4 - p3} milliseconds.`);

		// TODO: See if we can move these checks up into above array loops
		const hasMaterial = sListHash.Materials.length
			? sListHash.Materials.some(val => pListHash.Materials.includes(val))
			: true;
		const hasTypes = sListHash.Types.length
			? sListHash.Types.some(val => pListHash.Types.includes(val))
			: true;
		const hasSizes = sListHash.Sizes.length
			? sListHash.Sizes.some(val => pListHash.Sizes.includes(val))
			: true;
		const hasColors = sListHash.Colors.length
			? sListHash.Colors.some(val => pListHash.Colors.includes(val))
			: true;
		const hasShapes = sListHash.Shapes.length
			? sListHash.Shapes.some(val => pListHash.Shapes.includes(val))
			: true;
		const hasCollections = sListHash.Collections.length
			? sListHash.Collections.some(val => pListHash.Collections.includes(val))
			: true;
			const hasSales = sListHash.Sales.length
				? sListHash.Sales.some(val => pListHash.Sales.includes(val))
				: true;
		return (
			hasMaterial &&
			hasTypes &&
			hasCollections &&
			hasSizes &&
			hasColors &&
			hasShapes &&
			hasSales
		);
	});
	//const pr2 = performance.now();
	//console.log(`result took ${pr2 - pr1} milliseconds.`);
	return result;
}

const Getters = {
	filteredProducts(state, getters) {
		return _filteredProducts(state.selectedFilters, getters.filterableProducts);
	},
	filteredProductsProperties(state, getters) {
		if (state.selectedFilters.length == 0)
			return getters.propertyListSelectValues;

		let availableFilters = [...state.selectedFilters];
		getters.propertyListSelectValues.forEach(val => {
			if (availableFilters.includes(val)) return;
			const resultCount = _filteredProducts(
				state.selectedFilters.concat(val),
				getters.filterableProducts,
			).length;

			if (resultCount != 0 && resultCount != getters.filteredProducts.length) {
				availableFilters.push(val);
			}
		});
		return availableFilters;
		const primaryFilters = state.selectedFilters.filter(f =>
			f.includes("Material"),
		);
		const materialFilters = primaryFilters.filter(f => f.includes("Material"));
		const typeFilters = primaryFilters.filter(f => f.includes("Type"));
		const secondaryFilters = state.selectedFilters.filter(
			f => !f.includes("Material"),
		);
		// debugger;
		return Array.from(
			new Set(
				getters.filterableProducts.reduce((filterList, p) => {
					// TODO: clean up
					const hasPrimary = primaryFilters.length
						? primaryFilters.some(f => p.filterList.includes(f))
						: true;
					const hasSecondary = secondaryFilters.length
						? secondaryFilters.some(v => {
								return p.filterList.includes(v);
						  }) || hasPrimary
						: true;
					if (!hasSecondary) return filterList;
					if (primaryFilters.length) {
						const productMaterials = p.filterList.filter(f =>
							f.includes("Material"),
						);
						const productTypes = p.filterList.filter(f => f.includes("Type"));
						const prodHasMaterial = materialFilters.length
							? productMaterials.some(f => materialFilters.includes(f))
							: true;
						const prodHasType = productTypes.some(f => typeFilters.includes(f));
						if (prodHasMaterial || prodHasType) {
							return filterList.concat(p.filterList);
						} else {
							return filterList.concat(
								p.filterList.filter(f => f.includes("Material")),
							);
						}
					}
					if (secondaryFilters.length) {
						const hasSecondary = p.filterList.some(f =>
							secondaryFilters.includes(f),
						);
						return hasSecondary ? filterList.concat(p.filterList) : filterList;
					}
					return filterList.concat(p.filterList);
				}, []),
			),
		);
	},
	filterableProducts(state, getters) {
		return state.products
			.map(p => {

				const hasDiscount = p.variations && p.variations.some(v => (v.list_price && v.promotions));
				return {
					...p,
					filterList: p.properties.reduce((filterList, filter) => {
						return filterList.concat(
							filter.assignments.map(a => `${filter.label}:${a}`),
						)
						// adding 'Sale' | Discounts. Not an original property of the item 
						.concat(
							hasDiscount && filter.assignments.map(a => (filter.label === 'Type') ? `Sale:${a}` : null ),
						);
					}, [])
					.concat(hasDiscount && 'Sale:All Sale Items')
					.filter((a) => a),
				};
			})
			.filter(p => p.filterList.length > 0);
	},

	propertyListSelectValues(state, getters) {
		return Object.keys(getters.propertyList).reduce((acc, key) => {
			return acc.concat(getters.propertyList[key].map(v => `${key}:${v}`));
		}, []);
	},
	propertyList(state, getters) {
		return getters.filterableProducts.reduce((properties, product) => {
			product.properties
				.filter(p => validProductProperties.includes(p.label))
				.forEach(p => {
					if (!(p.label in properties)) properties[p.label] = [];
					properties[p.label] = Array.from(
						new Set([...properties[p.label], ...p.assignments]),
					);
				});
				// adding 'Sale' | Discounts. Not an original property of the item 

				const hasDiscount = product.variations && product.variations.some(v => (v.list_price && v.promotions));
				if (hasDiscount) {
					product.filterList
						.forEach(p => {
						if (!('Sale' in properties)) properties['Sale'] = [];
						properties['Sale'] = Array.from(
							new Set([...properties['Sale'], (p.includes('Sale')) ? p.split(':')[1] : '' ]),
						).filter((a) => a);
					});
				}
			return properties;
		}, {});
	},

	propertyListAvailableValues(state, getters) {
		return Object.keys(getters.propertyListAvailable).reduce((acc, key) => {
			return acc.concat(
				getters.propertyListAvailable[key].map(v => `${key}:${v}`),
			);
		}, []);
	},
	propertyListAvailable(state, getters) {
		return getters.filteredProducts.reduce((properties, product) => {
			product.properties
				.filter(p => validProductProperties.includes(p.label))
				.forEach(p => {
					if (!(p.label in properties)) properties[p.label] = [];
					properties[p.label] = Array.from(
						new Set([...properties[p.label], ...p.assignments]),
					);
				});
			return properties;
		}, {});
	},

	queryValues(state) {
		const filters = state.selectedFilters.reduce((filterHash, curr) => {
			const [type, ...val] = curr.split(":").map(e => encodeURIComponent(e));
			if (!(type in filterHash)) filterHash[type] = [];
			filterHash[type].push(val);
			return filterHash;
		}, {});
		let qv = Object.keys(filters)
			.reduce((queryParts, key) => {
				queryParts.push(`${key}=${filters[key].join(state.querySeparator)}`);
				return queryParts;
			}, [])
			.join("&");
		return `${qv}${qv ? '&' : ''}page=${state.page}${state.navigatedProduct ? `&p=${state.navigatedProduct}`: ''}`;
	},
	getColors(state) {
		return state.colors;
	},
	getQueryParams(state) {
		return queryParams();
	},
	getSelectedFilters(state) {
		return state.selectedFilters;
	},
	getPage(state) {
		return state.page;
	},
	getNavigatedProduct(state) {
		return state.navigatedProduct;
	}
};

export default Getters;

<template>
  <node-wrap :node="node" :required="required">
    <div class="frow">
      <input type="date" v-model="date">
    </div>
  </node-wrap>
</template>

<script>
import dataModel from "./productHelpDataModel";
import mixins from './mixins';
import { mapMutations, mapGetters } from 'vuex';
import NodeWrap from './NodeWrap';

export default {
  name: "DateInput",
  components: {
    NodeWrap
  },
  mixins: [mixins],
  data() {
    return {
      dataModel,
      date: "",
    };
  },
  props: [
    "step",
    "node",
    "answerOnly",
    "required",
  ],
  computed: {
    ...mapGetters({
      order: "productHelp/getOrder"
    }),
  },
  watch: {
    date(answer) {
      if (this.node.meta && this.node.meta.addToNotes) {
        let date = answer;
        answer = {
          text: date,
          addToNotes: this.node.meta.addToNotes
        }
      }
      this.setAnswer({
        id: this.step,
        answer,
      });

      if (!this.answerOnly) {
        this.setFollowingStep();
      }
    },
    order: {
      handler(order) {
        this.date = order.orderCreationDate.split("T")[0];
      },
      deep: true,
    },
    node() {
      this.date = "";
    }
  },
  methods: {
    ...mapMutations({
      setAnswer: "productHelp/setAnswer",
      setFollowingStep: "productHelp/setFollowingStep",
    }),
  },
  created() {
    console.log("created a date input");
  },
  mounted() {
    // if there is an order get the date from the order and continue
    if (this.order && this.order.orderNumber) {
      this.date = this.order.orderCreationDate.split("T")[0];
    }
  },
};
</script>

<style scoped>

</style>

<template>
	<button-page :flexCard="true">
		<template slot="header">
			<span class="subtitle--line secondary">Serving Sizes</span>
			<h1 class="h2">How many people are you planning to cook for?</h1>
			<p
				v-if="error"
				class="error"
			>Please select a serving size.</p>
		</template>
		<template slot="buttons">
			<input
				type="radio"
				id="serving-1"
				name="servings"
				v-model="value"
				value="1"
			/>
			<label
				class="half left"
				for="serving-1"
			>
				<strong>Just myself</strong>
			</label>
			<input
				type="radio"
				id="serving-2"
				name="servings"
				v-model="value"
				value="2"
			/>
			<label
				class="half right"
				for="serving-2"
			>
				<strong>For 2</strong>
			</label>
			<input
				type="radio"
				id="serving-4"
				name="servings"
				v-model="value"
				value="3-4"
			/>
			<label
				class="half left"
				for="serving-4"
			>
				<strong>3-4</strong>
			</label>
			<input
				type="radio"
				id="serving-6"
				name="servings"
				v-model="value"
				value="5-6"
			/>
			<label
				class="half right"
				for="serving-6"
			>
				<strong>5-6</strong>
			</label>
			<input
				type="radio"
				id="serving-100"
				name="servings"
				v-model="value"
				value="party"
			/>
			<label
				class="full"
				for="serving-100"
			>
				<strong>For parties</strong>
			</label>
		</template>
		<template slot="nextButton">
			<a
				:href="nextPageHash"
				:disabled="disabledButton"
				@click="validate"
				class="btn btn--border btn--arrow btn--primary borderless"
			>
				Next
			</a>
		</template>
	</button-page>
</template>

<script>
import ButtonPage from "../ButtonPage.vue";
import { mapMutations, mapGetters, mapState } from "vuex";
import { keyboardAccessibility } from "../utils";
export default {
	props: ["pageData", "pageNumber", "handleFocus", "usesKeyboard"],
	components: {
		ButtonPage,
	},
	data() {
		return {
			error: false,
		};
	},
	computed: {
		...mapState("wizard", ["flavorTier"]),
		...mapGetters({
			getServingsRaw: "wizard/getServings",
			getServings: "wizard/getServings",
			getFlavorKey: "wizard/getFlavorValidation",
		}),
		disabledButton() {
			return !this.value;
		},
		value: {
			set(servings) {
				if (servings) this.error = false;
				this.setServings({ servings });
			},
			get() {
				return this.getServings;
			},
		},
		nextPageHash() {
			return this.getServingsRaw === "party" || this.getServings >= 3
				? "#product-wizard/results"
				: "#product-wizard/leftovers";
		},
	},
	methods: {
		...mapMutations({
			setBackButtonHash: "wizard/setBackButtonHash",
			setServings: "wizard/setServings",
		}),
		validate($event) {
			this.error = false;
			if (!this.value) {
				$event.preventDefault();
				this.error = true;
				return;
			}
			keyboardAccessibility($event, this);
			// this.$emit("nextQuestion", { analytics: this.value });
		},
	},
	mounted() {
		if (!this.getFlavorKey) {
			window.location.hash = "#product-wizard/flavor";
		}
		let hash = "#product-wizard/";
		switch (this.flavorTier) {
			case 1:
				hash = hash += "flavor";
				break;
			case 2:
				hash = hash += "flavorB";
				break;
			case 3:
				hash = hash += "flavorC";
				break;
		}
		this.setBackButtonHash({ hash });
		if (this.usesKeyboard) {
			this.handleFocus();
		}
	},
};
</script>

<template>
	<div
		v-if="results.top && results.alternates"
		class="product-wizard--results pattern--base animatable"
	>
		<div class="product-wizard--results-overlay"></div>
		<div class="product-wizard--top-choice">
			<TopChoice :result="results.top" />
		</div>
		<div class="product-wizard--alternatives wizard-gutters controlled-width animatable-children">
			<div class="alternative-intro">
				<h3>Looking for a different style?</h3>
				<p>
					Choose any of our cast iron skillets for unparalled quality and
					versatile cooking.
				</p>
			</div>
			<div class="product-wizard--alternatives-container">
				<RecommendedItem
					v-for="item in results.alternates"
					:key="item.sku"
					:product="item"
				/>
			</div>
		</div>
	</div>
	<Failure v-else-if="failed" />
</template>

<script>
import ButtonPage from "../ButtonPage.vue";
import RecommendedItem from "../RecommendedItem.vue";
import TopChoice from "../TopChoice.vue";
import AddToCart from "../../../../../lodge-vue/src/AddToCart.vue";
import Failure from "../UI/Failure.vue";

// import AddToCart from "../../../../lodge-vue/src/AddToCart";
import * as anis from "../animations";
import { mapMutations, mapGetters, mapState } from "vuex";
import { lodgeLocalStorage } from "../../../../classes/LodgeStorage";
import { EVENTS, gtmCustomEventPush } from "../../../../analytics";
export default {
	props: ["pageNumber", "handleFocus", "usesKeyboard"],
	components: {
		ButtonPage,
		RecommendedItem,
		TopChoice,
		AddToCart,
		Failure,
	},
	data() {
		return {
			failed: false,
			resultTry: 0,
			animated: false,
			interval: null,
			gtmSent: false,
		};
	},
	computed: {
		...mapState("wizard", ["sawResults"]),
		...mapGetters({
			getFinalSettings: "wizard/getFinalSettings",
			results: "wizard/getResults",
			getAPIDataStatus: "wizard/getAPIDataStatus",
			getServings: "wizard/getServings",
			getUniqueKey: "wizard/getUniqueKey",
		}),
		resultsReady() {
			console.log(this.results);
			return this.getAPIDataStatus.results && this.results;
		},
		hasElement() {
			return !!this.$el;
		},
	},
	methods: {
		...mapMutations({ setBackButtonHash: "wizard/setBackButtonHash" }),
		animateASAP() {
			this.resultTry += 1;
			console.log("Check for results");
			console.log(Object.keys(this.results) > 0, this.interval, this.$el);
			if (Object.keys(this.results).length > 0 && this.interval && this.$el) {
				clearInterval(this.interval);
				anis.resultsIntroAnimation(this.$el);
			} else if (this.resultTry >= 15) {
				console.log("this failed");
				this.failed = true;
				clearInterval(this.interval);
			}
		},
		sendRecommendationsToGTM() {
			console.log(this.results, this.gtmSent);
			if (this.results && this.results.top && !this.gtmSent) {
				this.gtmSent = true;
				const skuList = (this.results.alternates || []).reduce((acc, curr) => {
					return `${acc}|${curr.sku}`;
				}, this.results.top.sku);
				console.log("gtm", {
					event: EVENTS.WIZARD_RECOMMENDATIONS,
					action: skuList,
				});
				gtmCustomEventPush({
					event: EVENTS.WIZARD_RECOMMENDATIONS,
					action: skuList,
				});
			}
		},
	},
	watch: {
		results(val, oldVal) {
			if (val && oldVal !== val) {
				this.sendRecommendationsToGTM();
			}
		},
	},
	mounted() {
		this.setBackButtonHash({
			hash:
				this.getServings === "party" || this.getServings >= 3
					? "#product-wizard/servings"
					: "#product-wizard/leftovers",
		});
		// You might say, "why are you try-catching" this?
		// and the reason is because you know there will be
		// a change that gets tried out
		try {
			if (!this.sawResults) {
				gtmCustomEventPush({
					event: EVENTS.WIZARD_RESULTS,
					category: "Wizard Results",
					action: this.getUniqueKey,
				});
			}
		} catch (err) {
			console.error(err);
		}
		lodgeLocalStorage.set("wizard", this.getFinalSettings);

		// try to send this to GTM, but it might fail if results aren't here
		// so we'll try again there
		this.sendRecommendationsToGTM();
		if (
			Object.keys(this.results).length &&
			this.getAPIDataStatus.result &&
			this.$el
		) {
			console.log(114, this.$el);
			this.animated = true;
			anis.resultsIntroAnimation(this.$el);
		} else {
			console.log(97, "interval the route");
			this.interval = setInterval(this.animateASAP, 100);
		}

		if (this.usesKeyboard) {
			this.handleFocus();
		}
	},
};
</script>
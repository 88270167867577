import axios from "axios";
import {
	domain as sfccDomain,
	controllerPath,
} from "../../../../lodge-vue/src/ocapi";
import { Commit } from "./mutations";

/**
 * @return {import("axios").AxiosPromise<ProductListingAPIResponse>}
 */
function getProds() {
	return axios.get(`${sfccDomain}${controllerPath}API-AllProducts`).then(r => ({
		status: r.status,
		data: r.data,
	}));
}
/**
 * @type {import("vuex").ActionTree<ProductListingState>}
 */
const Actions = {
	getProducts({ commit }) {
		Commit.loadingState(commit, "loading");
		getProds().then(r => {
			if (r.status == 200) {
				Commit.products(commit, r.data.products);
				Commit.colors(commit, r.data.colors);
				Commit.loadingState(commit, "loaded");
			} else {
				Commit.loadingState(commit, "error");
			}
		});
	},
};

export default Actions;

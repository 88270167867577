import gsap from "gsap";
import { paused, ANIMATION_DEFAULTS } from "../../js/animations/config";
import { debounce, TABLET_SIZE, qs, DESKTOP_SIZE, qsa } from "../../js/utils";

class MainMenu {
	constructor(el) {
		if (!el) return;
		this.opened = false;
		this.el = el;
		console.log(document.querySelector("#toolbar-administration"));
		this.hasPromo = document.querySelector(".promo--top") ? true : false;
		if (
			!document.querySelector(".submenu") &&
			!document.querySelector("#toolbar-administration")
		) {
			this.el.classList.add("sticky");
		}
		if (this.el) {
			this.setupEvents();
		}
	}

	isMenuOpened() {
		return this.opened;
	}

	handleClickaway({ target }) {
		if (!target.closest("#site-header") && window.innerWidth >= DESKTOP_SIZE) {
			// this lets us offcenter thing as neede
			const dropdown = document.querySelector(".menu--dropdown-active");
			if (dropdown) {
				document.body.classList.remove("prevent-scrolling");
				console.log(dropdown.classList);
				dropdown.classList.remove("menu--dropdown-active");
				dropdown
					.querySelector('[aria-expanded="true"]')
					.setAttribute("aria-expanded", "false");
				console.log(dropdown.classList);
			}
		}

		// Handle clickaway cart - WOOOOOOOOOOOOOO
		const cart = document.querySelector("#cart-app");
		if (target.id == "cart-app" && cart && cart.classList.contains("active")) {
			cart.classList.remove("active");
			document.body.classList.remove("prevent-scrolling");
		}
	}

	setupEvents() {
		const hamburger = this.el.querySelector(".hamburger");
		document.body.addEventListener("click", this.handleClickaway.bind(this));
		hamburger.addEventListener("click", this.toggleMobileMenu.bind(this));

		const dropdownLinks = this.el.querySelectorAll(".has-dropdown");
		[...dropdownLinks].forEach((wrapper) => {
			const link = wrapper.querySelector("[data-link-title]");
			const dropdown = wrapper.querySelector(".js-menu-dropdown");

			link.addEventListener("click", this.toggleDropdowns.bind(this));
		});

		this.recalculate();
	}

	toggleMobileMenu() {
		const mobileActiveClass = "mobile-menu-opened";
		console.log(this.el);
		const fn = this.el.classList.contains(mobileActiveClass) ? "remove" : "add";
		this.opened = !this.opened;
		this.menuToggleAnimation(this.el, { fn, mobileActiveClass });
	}

	toggleDropdowns(e) {
		
		// for discover submenu
		const subItemsEl = e.target.parentNode.querySelector('.js-sub-items');
		let dropdownEnabled = (subItemsEl && window.getComputedStyle(subItemsEl).display == 'none') ? false : true
		if (dropdownEnabled) e.preventDefault();

		const { target } = e;
		const targetParent = target.parentNode;
		const dropdown = target.parentNode.querySelector(".js-menu-dropdown");
		// const otherDropdowns = this.el.querySelectorAll(".menu--dropdown-active");
		const otherDropdowns = targetParent.parentNode.querySelectorAll(".menu--dropdown-active");

		// this lets us offcenter thing as needed
		const windowSize = window.innerWidth;
		console.log(windowSize >= TABLET_SIZE);
		let promoTopOffset = windowSize >= TABLET_SIZE ? 100 : 0;
		promoTopOffset = this.getOffsetForMenu();

		[...otherDropdowns].forEach((el) => {
			if (el !== targetParent) {
				el.classList.remove("menu--dropdown-active");
				el.querySelector('[aria-expanded="true"]').setAttribute(
					"aria-expanded",
					"false",
				);
			}
		});
		target.parentNode.classList.toggle("menu--dropdown-active");
		const addOrRemove = target.parentNode.classList.contains(
			"menu--dropdown-active",
		)
			? "add"
			: "remove";
		document.body.classList[addOrRemove]("prevent-scrolling");
		target.setAttribute(
			"aria-expanded",
			target.parentNode.classList.contains("menu--dropdown-active"),
		);
		console.log("promo top offset is...", promoTopOffset);
		dropdown.style.top = `${promoTopOffset}px`;
		if (window.outerWidth > 767) {
			dropdown.style.maxHeight = `calc(100vh - ${promoTopOffset}px`;
		} else {
			dropdown.style.maxHeight = "none";
		}
	}

	getOffsetForMenu() {
		// const promoTop = document.querySelector(".promo--top");
		// const bodyTopMargin = parseInt(
		// 	window.getComputedStyle(document.body).paddingTop,
		// 	10,
		// );
		// let offset = 0;
		// if (promoTop && window.pageYOffset <= promoTop.offsetHeight) {
		// 	offset += promoTop.offsetHeight;
		// }
		// offset += bodyTopMargin;
		// return offset;
		const aboveHeader = document.querySelector(".above-header");
		const siteHeader = document.querySelector("#site-header");
		if (!aboveHeader && !siteHeader ) return 0;
		const aboveHeaderRect = aboveHeader.getBoundingClientRect();
		const siteHeaderRect = siteHeader.getBoundingClientRect();
		return (siteHeaderRect.top === 0) 
			? siteHeaderRect.top + siteHeaderRect.height
			: aboveHeaderRect.top + aboveHeaderRect.height + siteHeaderRect.height;
	}

	menuToggleAnimation(el, { fn, mobileActiveClass }) {
		// variables
		const menuOpened = this.isMenuOpened();
		const { hasPromo } = this;
		const { DEFAULT_TIME, DEFAULT_TIME_SHORT, COLORS } = ANIMATION_DEFAULTS;
		const animatedCover = this.el.querySelector(
			".links-holder--animated-cover",
		);
		const allLinks = el.querySelectorAll("header nav > ul > li");
		const search = el.querySelector(".mobile--drupal-search");
		const isRemoving = fn === "remove";
		const linksStartingX = isRemoving ? { x: 0 } : { x: -10 };
		const linksFinishing = isRemoving
			? { autoAlpha: 0, x: -10, stagger: 0.2 }
			: { autoAlpha: 1, x: 0, stagger: 0.2 };
		// open animation
		// 1. Cover with the border
		// 2. Move things around because of promo potentially being there
		// 3. Uncover
		// 4. Slide things in
		console.log("starting");
		const tl = gsap.timeline();
		tl.to(animatedCover, DEFAULT_TIME_SHORT, {
			zIndex: 5,
			borderWidth: "50vw 50vw",
		});
		tl.call(() => {
			this.el.classList[fn](mobileActiveClass);
			if (fn === "add") {
				[...allLinks].forEach((el) => (el.style.opacity = "0"));
			}
		});
		tl.to(animatedCover, DEFAULT_TIME_SHORT, {
			delay: DEFAULT_TIME_SHORT,
			borderWidth: "0",
		});
		if (fn === "add") {
			tl.staggerFromTo(allLinks, DEFAULT_TIME, linksStartingX, linksFinishing);
		}
	}

	// TODO: decide if this is how I want this to behave
	recalculate(el) {
		console.log("this would recalculate all the things");
		window.addEventListener(
			"resize",
			debounce(() => {
				if (window.innerWidth >= TABLET_SIZE) {
					const activeDropdown = document.querySelector(
						".menu--dropdown-active",
					);
					const offset = this.getOffsetForMenu();
					if (activeDropdown) {
						activeDropdown.style = "";
						document.querySelector(".menu--dropdown").style = `top: ${
							offset
						}px; max-height: calc(100vh - ${offset}px)`;
						window.scroll({ top: 0 });
						document.body.classList.add("prevent-scrolling");
					}
				}

				if (window.innerWidth < TABLET_SIZE) {
					document.body.classList.remove("prevent-scrolling");
				}
			}, 200),
		);
	}
}

const nav = document.querySelector("#site-header");
if (!nav) {
	console.warn("Menu isn't found");
}
export default new MainMenu(nav);

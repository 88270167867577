import { gtmCustomEventPush } from "./analytics";
import { callFnIfEnterKeyWasPressed } from "./utils";

(function () {
	// debugger;
	const searchWrapper = document.querySelector(
		".block--exposedformsearchpage-1",
	);
	const searchIcon = document.querySelector(".search-icon");
	if (!searchWrapper || !searchIcon) return;
	const closeElem = document.querySelector(
		".block--exposedformsearchpage-1--button",
	);
	// const searchInput = document.querySelector("#edit-search-api-fulltext");
	const searchInputs = document.querySelectorAll('input[name="search_api_fulltext"]');
	const closeSearch = () => {
		searchWrapper.classList.remove("block--exposedformsearchpage-1--show");
		setTimeout(() => searchIcon.focus(), 16);
	};
	closeElem.addEventListener("click", function () {
		closeSearch();
	});
	closeElem.addEventListener("keydown", (e) => {
		console.log("keydown ", e.key, " on close button?");
		if (e.key === "Enter") {
			closeSearch();
		}
	});

	const openSearch = () => {
		searchWrapper.classList.add("block--exposedformsearchpage-1--show");
		// searchInput.focus();
		searchInputs[0].focus();
	};
	searchIcon.addEventListener("click", function () {
		openSearch();
	});
	searchIcon.addEventListener("keypress", (e) => {
		if (e.key === "Enter") {
			openSearch();
		}
	});

	// gtm analytics stuff because drupal prevents form fires
	// const searchBtn = searchWrapper.querySelector('[type="submit"]');
	// const mobileSearchInput = document.querySelector(
	// 	'.links-holder input[name="search_api_fulltext"]',
	// );
	const fireQueryToGTM = (e) => {
		const query = (e && e.target.querySelector('input')) ? e.target.querySelector('input').value : searchInput.value;
		gtmCustomEventPush({
			event: "SiteSearch",
			category: "Search",
			label: `${query}`,
		});
	};
	const fireMobileQueryToGTM = () => {
		gtmCustomEventPush({
			event: "SiteSearch",
			category: "Search",
			label: `${mobileSearchInput.value}`,
		});
	};
	const forms = document.querySelectorAll('[id*=views-exposed-form-site-search-search], .links-holder input[name=search_api_fulltext]');
	forms.forEach((btn) => btn.addEventListener("submit", (e) => fireQueryToGTM(e)));
	// searchBtn.addEventListener("click", (e) => fireQueryToGTM());
	// searchInput.addEventListener(
	// 	"keydown",
	// 	callFnIfEnterKeyWasPressed(fireQueryToGTM),
	// );
	// mobileSearchInput.addEventListener(
	// 	"keydown",
	// 	callFnIfEnterKeyWasPressed(fireMobileQueryToGTM),
	// );
})();

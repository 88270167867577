<template>
  <div class="order-finder">
    <div v-if="!showCustomProductForm" class="frow">
      <div v-if="order && !order.orderNumber" class="frow justify-between">
        <div class="label col-xs-1-1 col-sm-5-12">
          <label class="subtitle--line brown">Lookup an order
          </label>
          <h1 class="h2 mb-0">Help us find your order.</h1>
        </div>
        <div class="node-wrap col-xs-1-1 col-sm-6-12 frow">
          <order-chooser/>
        </div>
      </div>
      <div v-if="order && order.orderNumber" class="order-result mt-4 w-100">
        <div class="secondary-highlight">Order Number {{ order.orderNumber }}
          <template v-if="trackingNumbers && trackingNumbers.length > 0">
            <template v-for="(trackingNumber, index) in trackingNumbers">
                      | <a class="btn--text-link"
                  tabindex="0tabindex" target="_blank"
                           :href="`https://www.ups.com/track?loc=en_US&Requester=DAN&tracknum=${trackingNumber}&AgreeToTermsAndConditions=yes&WT.z_eCTAid=ct1_eml_Tracking__ct1_eml_tra_sb_upg1`">
                      Track Package #{{ index + 1 }}</a>
                    </template>
          </template>
          <span v-else>
            | Has not shipped yet
          </span>
          | <button @click="removeOrder"
                  class="btn--text-link">Check a different order</button>
        </div>
        <h2 class="h2 col-md-8-12 mb-40">Which product are you having a problem with?</h2>
        <p class="col-md-8-12">Note that you can only choose one product at a time.<br>
          If you need help with an order that is missing (you did not receive any part of it)
          or that you wish to return entirely please call customer service at: <a href="tel:+18335634387">1.833.563.4387</a>.</p>
        <div class="products col-xs-1-1 col-md-8-12">
          <div v-for="(product, index) in products"
               :class="{active: selectedProductIndex === index}"
               class="frow justify-start pt-2 pb-4 mb-4 product">
            <div class="frow justify-between w-100 px-4 items-center">
              <div class="frow justify-start items-center col-xs-1-1 col-sm-7-12">
                <img class="product-image mr-4" :src="product.image" :alt="product.name"/>
                <div><strong>{{ product.name }}</strong><br>
                  <span class="sku">SKU: {{ product.id }}</span>
                </div>
              </div>
              <div class="frow justify-end items-center w-40 col-xs-1-1 col-sm-5-12">
                <div class="product-counter frow justify-start mr-4">
                  <button
                      class="down col-xs-1-3"
                      @click="dec(index)"
                  >
                    -
                  </button>
                  <div class="counter-value col-xs-1-3">
                    {{ productIssueQuantities[index] }}
                  </div>
                  <button
                      class="up col-xs-1-3"
                      @click="inc(index)"
                  >
                    +
                  </button>
                </div>
                <button class="btn btn--primary btn--border borderless w-auto px-4"
                        @click="toggleProduct(product, index)">{{ selectedProductIndex === index ? 'Deselect' : 'Select' }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="frow justify-end">
          <button class="btn--text-link"
                  @click="showCustomProductForm = true"
          >My product isn't listed
          </button>
        </div>
      </div>
    </div>
    <div v-else class="mt-4">
      <div class="frow justify-between">
        <div class="label col-xs-1-1 col-sm-5-12">
          <div class="secondary-highlight subtitle--line brown">Find a product |
            <button class="btn--text-link"
                    @click="showCustomProductForm = false">Search for my order
            </button>
          </div>
          <h1 class="h2 mb-0">Which product are you having a problem with?</h1>
        </div>
        <div class="node-wrap col-xs-1-1 col-sm-6-12 frow">
          <product-chooser :node="node"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { debounce } from "../../../utils";
import mixins from './mixins';
import ProductChooser from './ProductChooser';
import OrderChooser from './OrderChooser';

export default {
  name: "orderFinder",
  components: { OrderChooser, ProductChooser },
  mixins: [mixins],
  props: [
    "node",
  ],
  data() {
    return {
      productIssueQuantities: [],
      missingProductIndex: -1,
      selectedProductIndex: -1,
      showCustomProductForm: false,
    };
  },
  computed: {
    ...mapGetters({
      order: "productHelp/getOrder",
      orderLoadingStatus: "productHelp/getOrderLoadingStatus",
      currentIssueId: "productHelp/getCurrentIssueId",
      csrfToken: "productHelp/getCSRFToken",
      prevIssues: "productHelp/getPrevIssues",
    }),
    products() {
      return this.prevIssues.length > 0
          ? this.order.products.map(product => Object.assign(product, {
            prevIssue: this.prevIssues
                .find(prevIssue => prevIssue.product.id === product.id) || false,
          }))
          : this.order.products;
    },
    trackingNumbers() {
      return this.order && this.order.orderNumber
          && this.order.trackingNumber
          && this.order.trackingNumber.split(",").map(t => t.trim());
    }
  },
  methods: {
    ...mapMutations({
      setAnswer: "productHelp/setAnswer",
      removeAnswer: "productHelp/removeAnswer",
      removeOrder: "productHelp/removeOrder",
      setIssueProduct: "productHelp/setIssueProduct",
      unsetIssueProduct: "productHelp/unsetIssueProduct",
      setFollowingStep: "productHelp/setFollowingStep",
      unsetNextStep: "productHelp/unsetNextStep",
      updateProductIssueQuantity: "productHelp/updateProductIssueQuantity",
    }),
    toggleProduct(product, index, missing) {
      if (this.selectedProductIndex === index) {
        this.selectedProductIndex = -1;
        this.unsetIssueProduct();
        this.removeAnswer({ id: "material" });
        this.removeAnswer({ id: "purchased-from" });
        this.removeAnswer({ id: "gift" });
        this.unsetNextStep();
      } else {
        if (missing) {
          this.missingProductIndex = index;
          this.selectedProductIndex = -1;
        } else {
          this.missingProductIndex = -1;
          this.selectedProductIndex = index;
        }

        this.setIssueProduct({
          product,
          quantity: this.productIssueQuantities[index],
        });
        this.setAnswer({
          id: "material",
          answer: this.getMaterial(product),
        });
        this.setAnswer({
          id: "purchased-from",
          answer: {
            text: "Lodge website or gift",
            value: "website",
          },
        });
        this.setAnswer({
          id: "gift",
          answer: {
            text: "No",
            value: "no",
          },
        });
        if (missing) {
          this.setAnswer({
            id: "select-concern",
            answer: {
              "id": "Missing Order",
              "label": "Missing Order",
              "caseRecordType": "Returns, Exceptions, & Goodwill",
              "type": "Order And Shipment Error",
              "subType": "Missing Order That Has Shipped",
              "cause": "Carrier",
              "response": "No Response: Assign to CC Rep",
              "meta": {
                "used": "no",
                "damaged": "no",
                "reason": ["missing-order"],
                "purchased-from": ["website"],
                "select-concern": "Missing Order",
              },
            },
          });
          this.setAnswer({
            id: "used",
            answer: {
              "value": "no",
              "text": "No",
            },
          });
        } else {
          this.removeAnswer({
            id: "select-concern",
          });
        }
        this.setFollowingStep();
      }
    },
    inc(index) {
      // if (this.productIssueQuantities[index] < this.order.products[index].quantity) {
      this.$set(this.productIssueQuantities, index, this.productIssueQuantities[index] + 1);
      // }
    },
    dec(index) {
      if (this.productIssueQuantities[index] > 1) {
        this.$set(this.productIssueQuantities, index, this.productIssueQuantities[index] - 1);
      }
    },
  },
  watch: {
    products: {
      handler(p) {
        this.productIssueQuantities = p.map(p => 1);
      },
      deep: false,
    },
  },
  mounted() {
    if (this.order && this.order.orderNumber) {
      this.productIssueQuantities = this.order.products.map(p => 1);
    }
  },
};
</script>

<style scoped>

</style>

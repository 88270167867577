import {
    Action,
    Module,
    Mutation,
    RegisterOptions,
    VuexModule,
} from "vuex-class-modules";
import store from "../index";
import EventBus from "../../event-bus";
import {SaveableModule} from "@/store/types";
import {MutationPayload} from "vuex";
import {accountState} from "./types";
import {persistentData} from "../persistState";
import {Basket, Customer, JWTType, Product, ProductDetailsLink, ProductItem} from "@/types";
import {
    addProductsToBasket,
    createBasket,
    getCustomerBasket,
    getJWT,
    getUpdatedProductsForProductItems,
    recreateBasket,
    removeProductFromBasket,
    updateCustomerRecipeList,
    updateProductQuantityInBasket,
    getSession, getUpdatedProductsForBonusItems, addBonusProductToBasket,
} from "../../ocapi";

const name: string = "accountModule";

interface JWTResponse {
    jwt: string;
    customer: Customer;
    jwtExpiration: number;
    customerLoggedIn: boolean;
}

function PreValidateJWTAndBasket(
    target: AccountModule,
    key: string,
    descriptor: any,
) {
    const original = descriptor.value;
    if (typeof original === "function") {
        console.log("prevalidate setup", target, key, descriptor);
        descriptor.value = async function (...args: any[]) {
            console.log("prevalidate run", target, key, descriptor);
            const boundOriginal = original.bind(this);
            const currentJWTValid = this.isJWTValid();
            await this.getValidJWT("prevalidate");
            await this.getValidBasket(currentJWTValid);
            await boundOriginal(...args);
        };
    }
    return descriptor;
}

@Module({generateMutationSetters: true})
export class AccountModule extends VuexModule
    implements accountState, SaveableModule {
    moduleName: string = name;
    jwt: string = "";
    customer: Customer = {} as Customer;
    jwtExpiration: number = 0;
    basket: Basket = {} as Basket;
    products: Product[] = [];
    customerLoggedIn: boolean = false;
    error: any = null;
    expirationTime: number = 30 * 60 * 1000; // 30 minutes
    refreshTimeWindow: number = 5 * 60 * 1000; // 5 minutes
    sessionChecked: boolean = false;
    guestChecked: boolean = false;

    constructor(options: RegisterOptions) {
        super(options);
        persistentData.hydrate(this);
    }

    minBasket(basket: Basket) {

        const bonus_discount_line_items = this.basket.bonus_discount_line_items && this.basket.bonus_discount_line_items.map(bdli => {
            if (!bdli.bonusProductLineItemUUID) {
                // we don't have a bonus product line item id associated so
                //  we look for a product item that hasn't been associated yet and use that id
                const bonusProductLineItemUUID = this.basket.product_items
                    && this.basket.product_items.find(p => {
                        return this.basket.bonus_discount_line_items
                            && this.basket.bonus_discount_line_items
                                .every(b => p.item_id !== b.bonusProductLineItemUUID);
                });
                bdli.bonusProductLineItemUUID = (bonusProductLineItemUUID && bonusProductLineItemUUID.item_id) || "";
            }
            return bdli;
        });

        return {
            basket_id: this.basket.basket_id,
            customer_info: this.basket.customer_info,
            bonus_discount_line_items: bonus_discount_line_items,
            product_items:
                this.basket.product_items &&
                this.basket.product_items
                    .filter(p => !p.bonus_product_line_item)
                    .map(p => ({
                        product_id: p.product_id,
                        quantity: p.quantity,
                        item_id: p.item_id
                    })),
        };
    }

    toJSON(): string {
        return JSON.stringify(this.toObject());
    }

    toObject(): object {
        return {
            jwtExpiration: this.jwtExpiration,
            jwt: this.jwt,
            customer: this.customer,
            basket: this.minBasket(this.basket),
            // products: this.products,
        };
    }

    subscribe(mutation: MutationPayload) {
        if (this.shouldSave(mutation)) {
            console.log("saving", this.basket, mutation);
            this.save();
        }
    }

    shouldSave(mutation: MutationPayload) {
        // We want to save any change to this module
        // So it's basically a truthy value
        return mutation.type.indexOf("account") === 0;
    }

    save() {
        // This passes state jwt and basket
        persistentData.setState(this);
    }

    getJWTExpiration() {
        return Date.now() + this.expirationTime;
    }

    isJWTValid() {
        return !!this.jwt && Date.now() < +this.jwtExpiration;
    }

    /**
     * Scenarios
     * Invalid JWT
     * first visit: (invalid jwt)
     *    try
     *      jwt from session
     *    catch
     *      new guest jwt;
     *    and new basket
     * repeat visit in 60 min from 1st: (invalid jwt)
     *    new guest jwt AND
     *    new basket AND
     *    copy over products
     *
     * Valid JWT
     * repeat visit in 10 min from 1st: (valid jwt)
     *    continue with jwt AND
     *    continue with basket
     * repeat visit between 25 and 30 min from 1st: (valid jwt)
     *    refresh jwt AND
     *    continue with basket
     */

    @Action
    @PreValidateJWTAndBasket
    async initAccount() {
        console.log("initializing Account");
        this.products = await getUpdatedProductsForProductItems(
            this.jwt,
            this.basket,
            this.products,
        );
        EventBus.$emit("sfccAccountReady");
        return this.basket;
    }

    /**
     *   create a flow for getting valid jwt and basket whether that means
     *   from storage or from SFCC
     *   which is used by the PreValidateJWTAndBasket decorator to add to other methods
     */

    async getValidJWT(from: string) {
        // try to restore SFCC session
        // if this fails continue to get a JWT
        console.log(
            "get valid jwt from:",
            from,
            "sessionChecked:",
            this.sessionChecked,
            "guestChecked:",
            this.guestChecked,
        );
        try {
            if (!this.sessionChecked) {
                this.sessionChecked = true;
                let jwt = await this.getJWT({type: "session"});
                console.log("validatejwt: got session", this.jwt);
                return jwt;
            } else {
                throw new Error("Session has been checked and does not exist");
            }
        } catch (e) {
            // if valid jwt use it
            if (this.customer && this.customer.auth_type && this.isJWTValid()) {
                console.log("validatejwt: use existing jwt");
                return this.jwt;
            } else {
                // get a new guest JWT
                try {
                    if (!this.guestChecked) {
                        this.guestChecked = true;
                        let jwt = await this.getJWT({type: "guest"});
                        console.log(
                            "validatejwt: couldn't authenticate session and no JWT to refresh so create a guest JWT:",
                            jwt,
                        );
                        return jwt;
                    } else {
                        return this.jwt;
                    }
                } catch (e) {
                    console.error("Error getting guest JWT");
                    // TODO display error message to the user...
                    throw new Error("Error getting guest JWT");
                }
            }
        }
    }

    /**
     * if there is a jwt in local storage but ut is invalid we need to create a new one
     * otherwise we can just use it
     * also handle refreshing the JWT before it expires
     * @param type {JWTType}
     * @param username {string}
     * @param password {string}
     */
    @Action
    async getJWT(payload: {
        type: JWTType;
        username?: string;
        password?: string;
    }): Promise<string> {
        if (
            payload.type === "credentials" &&
            (!payload.username || !payload.password)
        ) {
            throw new Error(
                "Error getting JWT, credentials type requires username and password",
            );
        }
        try {
            let jwtResponse = await getJWT(
                payload.type,
                payload.username,
                payload.password,
                this.jwt, // for refreshing JWT when payload.type is "refresh"
            );
            this.jwt = jwtResponse.jwt;
            this.customer = jwtResponse.customer;
            console.log("customer", this.customer);
            this.jwtExpiration = this.getJWTExpiration();
            this.customerLoggedIn = this.customer && this.customer.auth_type === "registered";
            return jwtResponse.jwt;
        } catch (e) {
            throw new Error(e); // error already formatted in api call
        }
    }

    /**
     *  if basketStub from cookie
     *    need to know if it's a basketStub vs full basket
     *  check if basket is available for current jwt/customerId
     *    basket: use it
     *    no-basket: get new basket && add items from basketStub to it
     * @param currentJWTValid
     */
    async getValidBasket(currentJWTValid?: boolean) {
        // isJWTValid is always true cause we just got a new when we init
        //  with old jwt - so we need to pass in here an arg that tells
        //  that we need a new basket: currentJWTValid

        if (!this.basket.basket_id) {
            // no basket, need to create one
            // this will try to get a customer basket and if none exists returns a new one
            try {
                this.basket = await getCustomerBasket(
                    this.jwt,
                    this.customer.customer_id,
                );
            } catch (error) {
                this.guestChecked = true;
                await this.getJWT({type: "guest"});
                this.basket = this.basket.product_items
                    ? await recreateBasket(this.jwt, this.basket)
                    : await createBasket(this.jwt);
                console.log(
                    "error getting customer basket with no basket in storage",
                    error,
                );
            }
        } else if (!this.basket.last_modified) {
            // not a real basket so need to try to get or create one
            try {
                // try to get a customer basket in case the user was logged in,
                // but the session is not valid anymore
                console.log("get customer basket");
                this.basket = await getCustomerBasket(
                    this.jwt,
                    this.customer.customer_id,
                    this.basket,
                );
            } catch (e) {
                // didn't get a customer basket because of error so create a new one
                // if we have products recreate them in a new basket
                // otherwise get a fresh basket
                console.log("(re)create basket after failing to get customer basket");
                this.guestChecked = true;
                await this.getJWT({type: "guest"});
                this.basket = this.basket.product_items
                    ? await recreateBasket(this.jwt, this.basket)
                    : await createBasket(this.jwt);
            }
        } else if (currentJWTValid) {
            // we have a real basket and valid jwt so use the basket
            console.log("use current basket");
            return this.basket;
        } else {
            // we have a real basket but the jwt is invalid so we need to recreate it
            // if we have products in it
            // otherwise we can just create a fresh one
            console.log("(re)create basket because jwt is invalid");
            this.basket = await getCustomerBasket(
                this.jwt,
                this.customer.customer_id,
                this.basket,
            );
        }

        return this.basket;
    }

    /**
     * Adds items to basket
     * @param productItems
     */
    @Action
    @PreValidateJWTAndBasket
    async addProductsToBasket(productItems: Array<ProductItem>) {
        this.basket = await addProductsToBasket(
            this.jwt,
            this.basket.basket_id,
            productItems,
        );
        this.products = await getUpdatedProductsForProductItems(
            this.jwt,
            this.basket,
            this.products,
        );
        return this.basket;
    }

    /**
     * Adds bonus items to basket
     * @param productId
     * @param bonusItemId
     * @param qty
     */
    @Action
    @PreValidateJWTAndBasket
    async addBonusProductToBasket({productId, bonusProductLineItemUUID, bonusItemId, qty}: {
        productId: string,
        bonusProductLineItemUUID: string | undefined,
        bonusItemId: string,
        qty: number}) {
        console.log(productId, bonusProductLineItemUUID, bonusItemId, qty);
        this.basket = await addBonusProductToBasket(
            this.jwt,
            this.basket.basket_id,
            productId,
            bonusProductLineItemUUID,
            bonusItemId,
            qty,
        );

        return this.basket;
    }

    /**
     * Get Bonus Product Details
     * @param bonusItems
     */
    @Action
    @PreValidateJWTAndBasket
    async getBonusProductDetails(bonusItems: Array<ProductDetailsLink>) {
        const newBonusItems = bonusItems.filter(b => !this.products.some(p => p.id === b.product_id))
        const products = await getUpdatedProductsForBonusItems(
            this.jwt,
            this.basket,
            newBonusItems,
        );

        this.products = this.products.concat(products);

        return this.basket;
    }

    @Action
    @PreValidateJWTAndBasket
    async updateProductQuantityInBasket(payload: {
        productItem: ProductItem;
        quantity: number;
    }) {
        let productItem: ProductItem | false =
            !!this.basket.product_items &&
            this.basket.product_items.length > 0 &&
            this.basket.product_items
                .filter(p => p.product_id === payload.productItem.product_id
                    && !p.bonus_product_line_item)
                .reduce((a, c) => c);

        if (productItem) {
            this.basket = await updateProductQuantityInBasket(
                this.jwt,
                this.basket.basket_id,
                productItem,
                payload.quantity,
            );
            this.products = await getUpdatedProductsForProductItems(
                this.jwt,
                this.basket,
                this.products,
            );
        }
        return this.basket;
    }

    @Action
    @PreValidateJWTAndBasket
    async removeProductFromBasket(payload: {
        basketId: string;
        productItem: ProductItem;
    }) {
        this.basket = await removeProductFromBasket(
            this.jwt,
            payload.basketId,
            payload.productItem,
        );
        this.products = await getUpdatedProductsForProductItems(
            this.jwt,
            this.basket,
            this.products,
        );
        return this.basket;
    }

    @Action
    @PreValidateJWTAndBasket
    async updateCustomerRecipeList(payload: {
        recipeList: [string];
        env: string;
    }) {
        this.customer = await updateCustomerRecipeList(
            this.jwt,
            this.customer.customer_id,
            payload,
        );
        return this.customer;
    }

    // @Action
    // async loginUser(payload: { type: JWTType, username: string, password: string }) {
    //   if (payload.type === 'credentials' && (!payload.username || !payload.password)) {
    //     throw new Error('Error logging in user, credentials type requires username and password');
    //   }
    //   const jwt = this.getNewJWT(payload.type, payload.username, payload.password);
    //   this.customerLoggedIn = true;
    //   return jwt;
    // }
    //
    // @Action
    // async registerCustomer(payload: { username: string, password: string, lastName: string }) {
    //   const [err, customer] = await to<Customer>(registerCustomer(payload.username, payload.password, payload.lastName));
    //   if (err) {
    //     // TODO handle register errors
    //     console.log("register error", err);
    //     return err;
    //   }
    //   this.customerLoggedIn = true;
    //   return customer;
    // }

    @PreValidateJWTAndBasket
    async getSession() {
        try {
            const session = await getSession(this.jwt);
            console.log(session);
            return session;
        } catch (e) {
            console.log(e);
        }
    }

    @Action
    resetAccount(): boolean {
        try {
            this.jwt = "";
            this.jwtExpiration = Date.now() - 1000;
            this.basket = {} as Basket;
            this.customer = {} as Customer;
            return true;
        } catch (e) {
            return false;
        }
    }

    @PreValidateJWTAndBasket
    get drupalCanUseAccountFunctions(): boolean {
        return !!(this.jwt && this.jwt.length);
    }
}

export const accountModule: AccountModule = new AccountModule({
    store,
    name,
});

store.subscribe(accountModule.subscribe.bind(accountModule));

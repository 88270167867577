export default {
	getActivePage(state) {
		return state.activePage;
	},
	getQuestionWithAnswers(state) {
		return state.questionWithAnswers;
	},
	getCollectedAnswers(state) {
		return state.collectedAnswers;
	},
	getAnswerGroup(state) {
		return state.answerGroup;
	},
	getBackButton(state) {
		return state.backButton;
	}
};

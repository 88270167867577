<template>
	<button-page :flexCard="false">
		<template slot="header">
			<span class="subtitle--line secondary">Flavors</span>
			<h1 class="h2">Just pick one more.</h1>
			<p
				v-if="error"
				class="error"
			>Please select your last option.</p>
		</template>
		<template slot="buttons">
			<div class="product-wizard--selectable tier-3">
				<image-button
					v-for="img in imagesToShow"
					:key="img.id"
					:tier="tier"
					:image="img.image"
					v-model="value"
					:initialValues="value"
					:id="img.id"
					:alt="img.alt"
				/>
			</div>
		</template>
		<template slot="nextButton">
			<a
				:disabled="disabledButton"
				href="#product-wizard/servings"
				@click="validate"
				class="btn btn--border btn--arrow btn--primary borderless"
			>
				Next
			</a>
		</template>
	</button-page>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import ImageButton from "../UI/ImageButton.vue";
import ButtonPage from "../ButtonPage.vue";
import { keyboardAccessibility } from "../utils";

export default {
	components: {
		ImageButton,
		ButtonPage,
	},
	props: ["pageData", "pageNumber", "handleFocus", "usesKeyboard"],
	data() {
		return {
			error: false,
			tier: 3,
		};
	},
	computed: {
		...mapState("wizard", ["collection"]),
		...mapGetters({
			getCollection: "wizard/getCollection",
			getFlavors: "wizard/getFlavorsC",
			getFlavorsByTier: "wizard/getFlavorsByTier",
		}),
		disabledButton() {
			return this.value.length !== 1;
		},
		imagesToShow() {
			return this.getFlavorsByTier(this.getCollection, this.tier);
		},
		value: {
			get() {
				return this.getFlavors;
			},
			set(flavors) {
				if (flavors.length) this.error = false;
				this.setFlavors({ flavors, type: "flavorsC" });
			},
		},
	},
	methods: {
		...mapMutations({
			setBackButtonHash: "wizard/setBackButtonHash",
			setFlavors: "wizard/setFlavors",
			setFlavorTier: "wizard/setFlavorTier",
		}),
		validate($event) {
			if (this.value.length !== 1) {
				this.error = true;
				return;
			}
			this.error = false;
			keyboardAccessibility($event, this);
			// this.$emit("nextQuestion", { analytics: this.value.join("|") });
		},
	},
	created() {
		this.setFlavorTier({ tier: 3 });
		console.log(`values on creation`, this.values);
	},
	mounted() {
		if (!this.collection) {
			window.location.hash = "#product-wizard/collection";
		}
		this.setBackButtonHash({ hash: "#product-wizard/flavorB" });
		if (this.usesKeyboard) {
			this.handleFocus();
		}
	},
};
</script>

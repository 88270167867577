import dataModel from './productHelpDataModel';
import { mapGetters, mapMutations } from 'vuex';

export default {
	data() {
		return {
			isReplaying: false,
		};
	},
	created: function () {
		window.scroll({
			left: 0,
			top: 100,
			behavior: 'smooth',
		});
	},
	computed: {
		...mapGetters({
			answers: "productHelp/getAnswers",
			currentStep: "productHelp/getCurrentStep",
			nextStep: "productHelp/getNextStep",
			path: "productHelp/getPath",
			branch: "productHelp/getBranch",
			flowComplete: "productHelp/getFlowComplete",
			tree: "productHelp/getTree",
			nextFocus: "productHelp/getNextFocus",
			materialOptions: "productHelp/getMaterialOptions",
		}),
	},
	methods: {
		...mapMutations({
			setAnswer: "productHelp/setAnswer",
			setCurrentStep: "productHelp/setCurrentStep",
			setNextStep: "productHelp/setNextStep",
			createIssue: "productHelp/createIssue",
			addPathStep: "productHelp/addPathStep",
			setCSRFToken: "productHelp/setCSRFToken",
			removePathStep: "productHelp/removePathStep",
			setTree: "productHelp/setTree",
			setUndoPoint: "productHelp/setUndoPoint",
			setNextFocus: "productHelp/setNextFocus",
			setResolvedFlag: "productHelp/setResolvedFlag"
		}),
		validate(data, re) {
			let ok = re.test(data);
			return ok;
		},
		getMaterial(product) {
			return this.materialOptions.find(m => {
				return product.categories && product.categories.includes(m);
			}) || product.material;
		},
		parseTree(tree) {
			// returns a new parsed tree instead of modifying the passed in one
			let newTree = JSON.parse(JSON.stringify(tree));

			if (typeof newTree === "string") {
				let common = this.parseTree(dataModel.tree.common[newTree]);
				newTree = JSON.parse(JSON.stringify(common));
			} else if (typeof newTree.children === "string" && dataModel.tree.common[newTree.children]) {
				let common = this.parseTree(dataModel.tree.common[newTree.children]);
				newTree.children = {
					[newTree.children]: JSON.parse(JSON.stringify(common)),
				};
			} else if (typeof newTree.children === "object") {
				if (newTree.children.node) {
					// handles a node object used directly without a key
					newTree.children = {
						[newTree.children.node]: {
							node: newTree.children.node,
							children: Object.keys(newTree.children.children)
								.reduce((acc, c) => {
									acc[c] = this.parseTree(newTree.children.children[c]);
									return acc;
								}, {})
						}
					};
				} else {
					// handles an object with multiple nodes
					Object.keys(newTree.children).forEach(c =>
						newTree.children[c] = this.parseTree(newTree.children[c]));
				}
			} else if (newTree.end) {
				// this is the end leaf nothing to do here
			} else {
				// should not happen
				throw new Error("children should be a string reference to common branch or an object");
			}
			return newTree;
		},
		getPreviousStep(path) {
			return path.reduce((step, p) => {
				return step.children[p];
			}, this.tree);
		},
		getCurrentStep(branch, nextStep) {
			if (typeof branch.children[nextStep] === "object") {
				// should always be an object
				return branch.children[nextStep].node;
			} else if (typeof branch.children[nextStep] === "string") {
				// TODO check to see if this can ever happen???
				return dataModel.common[branch.children[nextStep]];
			} else {
				throw new Error("children should be a string reference to common branch or an object");
			}
		},
		goToPreviousStep(path) {
			this.undo("productHelp");
			this.setNextStep({
				step: "",
			});

		},
		checkForAnsweredStep() {
			// skip next step if answered already
			let answer = this.answers[this.branch.children[this.nextStep].node];
			if (this.branch.children[this.nextStep].node === "select-concern" && answer) {
				if (answer.meta && answer.meta.reason
					&& this.branch.children[this.nextStep].children[answer.meta.reason[0]]) {
					this.setCurrentStep({
						step: this.getCurrentStep(this.branch, this.nextStep),
					});
					this.addPathStep({
						step: this.nextStep,
						tree: this.tree,
					});
					this.setNextStep({
						step: answer.meta.reason[0],
					});
					this.checkForAnsweredStep();
				} else if (answer.meta && answer.meta["purchased-from"]) {
					this.setCurrentStep({
						step: this.getCurrentStep(this.branch, this.nextStep),
					});
					this.addPathStep({
						step: this.nextStep,
						tree: this.tree,
					});
					this.setNextStep({
						step: "need-purchase-location",
					});
				} else {
					this.setFollowingStep();
				}
				this.checkForAnsweredStep();
			} else if (answer) {
				let value = typeof answer === "string"
					? answer
					: answer.value || answer.id;
				if (this.branch.children[this.nextStep].children[value]) {
					// mark as duplicate step so we can reverse it later
					answer.repeat = true;
					this.setCurrentStep({
						step: this.getCurrentStep(this.branch, this.nextStep),
					});
					this.addPathStep({
						step: this.nextStep,
						tree: this.tree,
					});
					this.setNextStep({
						step: value,
					});
					this.checkForAnsweredStep();
				}
			}
		},
		checkForFilteredStep() {
			// skip next step if filter is applicable
			let node = dataModel.nodes[this.currentStep];
			if (node.meta && node.meta.filters) {
				if (Object.keys(node.meta.filters).every(key => {
					let answer = typeof this.answers[key] === "string"
						? this.answers[key]
						: this.answers[key].value || this.answers[key].id;
					return node.meta.filters[key].includes(answer);
				})) {
					// dont' skip step
				} else {
					// skip step
					this.setAnswer({
						id: this.currentStep,
						answer: node.meta.filteredAnswer,
					});
					this.setNextStep({
						step: node.meta.filteredAnswer.value,
					});
					this.moveToNextStep();
				}
			}

		},
		moveToNextStep() {
			this.setCurrentStep({
				step: this.getCurrentStep(this.branch, this.nextStep),
			});
			this.addPathStep({
				step: this.nextStep,
				tree: this.tree,
			});
			this.setNextStep({
				step: "",
			});
			this.setNextFocus({
				focus: false,
			});
		},
		goToNextStep() {
			this.setUndoPoint();

			// driven by the tree
			// the current answer and the current node of the tree tells us what is the next node
			this.checkForAnsweredStep();

			this.moveToNextStep();

			this.checkForFilteredStep();

		},
		goToSpecificStep(steps) {
			this.setUndoPoint();
			steps.forEach((step, i) => {
				// need to set the answer if repeating
				if (i < steps.length - 1) {
					this.setAnswer({
						id: step,
						answer: dataModel.nodes[step].options
							.find(option => option.value === steps[i + 1]) // get answer from dataModel based on the following step
					});
				}
				this.setNextStep({
					step: step,
				});
				this.addPathStep({
					step: step,
					tree: this.tree,
				});
			});
			this.setCurrentStep({
				step: steps[steps.length - 1],
			});
			this.setNextStep({
				step: "",
			});
		},
		goToEnd(resolved) {
			this.setUndoPoint();
			this.setResolvedFlag({
				resolved: resolved
			})
			this.setCurrentStep({
				step: "submit",
			});
			this.addPathStep({
				step: "submit",
				tree: this.tree,
			});
			this.setNextStep({
				step: "",
			});
		}
	},
};

import { lodgeLocalStorage } from "../../../classes/LodgeStorage";

export default {
	// sku will end up being a getter, i think
	sku: "",
	quantity: 1,

	// from drupal
	type: "",
	options: {},
	colors: [],
	products: [],
	associatedProducts: {},
	freeProducts:{},
	// these are all the attributes
	// that get selected by the user
	selected: {
		attribute_color: null,
		attribute_size: null,
		attribute_quantity: null,
		attribute_default: null,
		attribute_variationName: null,
	},

	recommendations: [],
	recentlyViewed: lodgeLocalStorage.get("recently_viewed"),
	masterInfo: null,
};

<template>
	<div class="quiz--title">
		<span class="color--secondary">{{ subtitle }}</span>
		<h4>{{ title }}</h4>
		<p v-if="desc">{{ desc }}</p>
	</div>
</template>

<script>
export default {
	props: {
		page: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		title() {
			return this.page.title;
		},
		desc() {
			return this.page.description;
		},
		subtitle() {
			return this.page.subtitle;
		},
	},
};
</script>
import Vue from "vue";
import dataModel from '../../components/ProductHelp/productHelpDataModel';
import initState from "./state";

const freshState = JSON.parse(JSON.stringify(initState));

export default {
	emptyState(state) {
		Object.assign(state, freshState);
	},
	setUndoPoint() {
		// only here to have a mutation to go back to
	},
	setAnswer(state, { id, answer }) {
		// has to be done this way for reactivity in multi-node component to work
		// can't use Vue.set
		state.issues[state.currentIssueId].answers = {
			...state.issues[state.currentIssueId].answers,
			[id]: answer,
		};
	},
	removeAnswer(state, { id }) {
		delete state.issues[state.currentIssueId].answers[id];
	},
	setRemedy(state, { id, remedy }) {
		state.issues[state.currentIssueId].requestedRemedy = remedy;
	},
	setGift(state, { gift }) {
		console.log("gift", gift);
		state.order.gift = gift;
	},
	setCurrentStep(state, { step }) {
		state.currentStep = step;
	},
	setNextStep(state, { step }) {
		state.nextStep = step;
	},
	setFollowingStep(state) {
		let key = Object.keys(state.branch.children)[0];
		state.nextStep = state.branch.children[key].node;
	},
	unsetNextStep(state) {
		state.nextStep = "";
	},
	setProducts(state, { products }) {
		state.products = products;
	},
	setProductsLoadingStatus(state, { status }) {
		state.productsLoadingStatus = status;
	},
	setOrderLoadingStatus(state, { status }) {
		state.orderLoadingStatus = status;
	},
	setIssuesResponseLoadingStatus(state, { status }) {
		state.issuesResponseLoadingStatus = status;
	},
	setOrder(state, { order }) {
		state.order = Object.assign(state.order, order);
	},
	removeOrder(state) {
		state.order = JSON.parse(JSON.stringify(freshState.order));
	},
	createIssue(state, { id }) {
		let issue = JSON.parse(JSON.stringify(state.issueTemplate));
		issue.requestNumber = id;
		Vue.set(state.issues, id, issue);
		state.currentIssueId = id;
	},
	setCurrentIssueId(state, { id }) {
		state.currentIssueId = id;
	},
	addIssuePhotos(state, { photos }) {
		state.issues[state.currentIssueId].photos = state.issues[state.currentIssueId].photos.concat(photos);
	},
	removeIssuePhotos(state, { photos }) {
		state.issues[state.currentIssueId].photos = state.issues[state.currentIssueId].photos.filter(p => {
			return !photos.includes(p);
		});
	},
	setIssuePhotos(state, { photos }) {
		state.issues[state.currentIssueId].photos = photos;
	},
	setIssueProduct(state, { product, quantity }) {
		state.issues[state.currentIssueId].product = product;
		state.issues[state.currentIssueId].product.quantity = quantity || 1;
	},
	unsetIssueProduct(state) {
		const freshState = JSON.parse(JSON.stringify(initState));
		state.issues[state.currentIssueId].product = freshState.issueTemplate.product;
	},
	updateMissingParts(state, { parts }) {
		state.issues[state.currentIssueId].missingParts = parts;
	},
	setNotes(state, { text }) {
		state.issues[state.currentIssueId].notes = text;
	},
	setUploadsComplete(state, complete) {
		state.uploadsComplete = complete;
	},
	addPathStep(state, { step, tree }) {
		state.path = [...state.path, step];
		state.branch = state.path.reduce((tree, step) => {
			return tree.children[step];
		}, tree);
	},
	removePathStep(state, { tree }) {
		// remove the answer for the step being removed
		let answers = state.issues[state.currentIssueId].answers;
		answers[state.currentStep] && (answers[state.currentStep].repeatUndone || !answers[state.currentStep].repeat) && (delete answers[state.currentStep]);

		// handle removing multi-node answers
		let node = state.path.reduce((tree, step) => {
			return tree.children[step];
		}, tree).node;
		if (dataModel.nodes[node].type === "multi-node") {
			dataModel.nodes[node].nodes.forEach(answer => {
				delete answers[answer];
			});
		}
		// handle removing the product...
		if (state.currentStep === "which-product") {
			state.issues[state.currentIssueId].answers["material"] && (delete state.issues[state.currentIssueId].answers["material"]);
			state.issues[state.currentIssueId].product = "";
		}
		// trim the path
		state.path = state.path.slice(0, -1);
		// reset the branch
		state.branch = state.path.reduce((tree, step) => {
			return tree.children[step];
		}, tree);
	},
	setCSRFToken(state, { csrfToken }) {
		state.csrfToken = csrfToken;
	},
	updateRequest(state) {
		// modify the state.request object

		// deep clone the issues
		let issues = JSON.parse(JSON.stringify(state.issues));
		state.request.order = JSON.parse(JSON.stringify(state.order));
		delete state.request.order.products;

		state.request.issues = Object.keys(issues).map(issueKey => {
			let issue = issues[issueKey];

			let concerns = dataModel.concerns.filter(concern => {
				if (!concern.type) {
					return false;
				}
				return Object.keys(concern.meta)
					.every(key => {
						if (issue.answers[key] !== undefined) {
							let answer = typeof issue.answers[key] === "string"
								? issue.answers[key]
								: issue.answers[key].value || issue.answers[key].id;
							return concern.meta[key].includes("Any") || concern.meta[key].includes(answer);
						} else {
							return true;
						}
					});
			});
			// TODO if there are no matches pick a default
			let concern = concerns.reduce((acc, curr) => curr, {});

			console.log("concern for submission", concern);
			// compile answers into notes field
			let additionalNotes = Object.keys(issue.answers).filter(key => {
				return (typeof issue.answers[key] === "object")
					&& issue.answers[key].addToNotes;
			}).map(key => {
				return `${dataModel.nodes[key].label}: ${issue.answers[key].text}`;
			}).join("\n\n");

			issue.notes = `Comments: ${issue.notes} \n\n ${additionalNotes}`;
			const dropped = issue.answers.dropped && issue.answers.dropped.value === "yes";

			issue.resolvedFlag = dropped ? true : issue.resolvedFlag;

			const hasResponse = (typeof concern.finalResponse === "object"
					? (dropped ? !!concern.finalResponse.dropped : !!concern.finalResponse.default)
					: concern.hasResponse === "yes");

			const response = typeof concern.finalResponse === "object"
				? (dropped ? concern.finalResponse.dropped : concern.finalResponse.default)
				: concern.finalResponse || (hasResponse ? concern.response : "");

			const raResponse = concern.RAResponse;
			const material = issue.answers.material === ""
				? "Accessories"
				: issue.answers.material;

			const type = typeof concern.type === "string"
				? concern.type
				: concern.type[material] || material;

			const cause = typeof concern.cause === "object"
				? (dropped ? concern.cause.dropped : concern.cause.default)
				: concern.cause;

			if (issue.answers["purchased-from"]) {
				issue.purchaseLocation = issue.answers["purchased-from"].text;
			}

			issue.used = issue.answers["used"].value === "yes";
			issue.damaged = issue.answers["damaged"].value === "yes";

			issue.caseRecordType = concern.caseRecordType.trim();
			// this is the concern label
			issue.concern = concern.id;
			issue.type = type.trim();
			issue.subType = concern.subType.trim();
			issue.hasResponse = hasResponse;
			issue.response = response;
			issue.raResponse = raResponse;
			issue.cause = cause.trim();

			// for refunds get product value if there is an order
			// TODO until we have a proper algorithm to determine refund amount we are not going to send it
			// if (issue.requestedRemedy === "refund" && state.request.order.orderNumber) {
			// 	issue.remedyAmount = issue.product.unitPrice * issue.product.quantity;
			// }

			// set a default remedy of help for all issues
			// that don't have a specific remedy and are not resolved
			// e.g. customer still wants help for an issue with standard response
			if (concern.meta.requestedRemedy) {
				issue.requestedRemedy = concern.meta.requestedRemedy
			} else if (!issue.resolvedFlag && !issue.requestedRemedy) {
				issue.requestedRemedy = "help";
			} else if (issue.resolvedFlag || !issue.requestedRemedy) {
				issue.requestedRemedy = "none";
			}

			// remove answers for clarity
			delete issue.answers;
			// remove accessories from product for clarity
			delete issue.product.parts;
			return issue;
		});
		state.request.captchaToken = state.captchaToken;
		// contact information is set by setContactInformation

	},
	setIssuesResponse(state, { response }) {
		state.issuesResponse = response;
	},
	setContactInformation(state, {
		name,
		address1,
		address2,
		city,
		state: st,
		zip,
		country,
		email,
		phone,
		updateContact,
	}) {
		state.request.name = name;
		state.request.address1 = address1;
		state.request.address2 = address2;
		state.request.city = city;
		state.request.state = st;
		state.request.zipcode = zip;
		state.request.country = country;
		state.request.email = email;
		state.request.phone = phone;
		state.request.updateContact = updateContact;
	},
	setTree(state, { tree }) {
		state.tree = tree;
	},
	resetFlow(state) {
		state.currentStep = Object.keys(state.tree.children)[0];
		state.path = [state.currentStep];
		state.branch = state.path.reduce((tree, step) => {
			return tree.children[step];
		}, state.tree);
	},
	setFlowComplete(state) {
		state.flowComplete = true;
	},
	setNextFocus(state, { focus }) {
		state.nextFocus = focus;
	},
	setResolvedFlag(state, { resolved }) {
		state.issues[state.currentIssueId].resolvedFlag = resolved;
	}
};

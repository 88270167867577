import Vue from "vue";
import gsap from "gsap";

export const wizardIntroAnimation = (el) => {
	const tl = gsap.timeline({
		/*paused: true*/
	});
	const wizard = el;
	const actionBar = el.querySelector(".product-wizard--action-bar");
	const content = el.querySelector(".product-wizard--content");

	tl.call(function () {
		this.shown = true;
		wizard.classList.add("enabled");
	});
	// tl.set(wizard, { delay: 0.4, className: "+=animate-in" });
	tl.set(wizard, { delay: 0.4 });
	tl.call(function () {
		wizard.classList.add("animate-in");
	});
	tl.fromTo(
		[content, actionBar],
		0.4,
		{ delay: 0.2, autoAlpha: 0 },
		{ autoAlpha: 1 },
	);

	return tl;
};

export const wizardOutroAnimation = (el) => {
	const tl = gsap.timeline();
	const wizard = el;
	const actionBar = el.querySelector(".product-wizard--action-bar");
	const content = el.querySelector(".product-wizard--content");

	tl.to([content, actionBar], 0.4, { autoAlpha: 0 }, { autoAlpha: 1 });
	tl.call(() => {
		wizard.classList.remove("animate-in");
	});
	// tl.set(wizard, { delay: 0.4, className: "-=enabled" });
	tl.set(wizard, { delay: 0.4 });
	tl.call(() => {
		wizard.classList.remove("enabled");
	});

	return tl;
};

export const resultsIntroAnimation = (el) => {
	const wizard = el.closest(".product-wizard");
	const topChoice = el.querySelector(".product-wizard--top-choice");
	const topChoiceContent = el.querySelector(
		".product-wizard--top-choice-content",
	);
	const alternativeDivs = el.querySelectorAll(
		".product-wizard--alternatives > div",
	);
	const tl = gsap.timeline();
	// tl.delay(1);
	// tl.set(el, { autoAlpha: 0 });
	tl.call(() => {
		wizard.classList.add("results");
	});
	// tl.set(wizard, { className: "+=results" });
	tl.to(el, 0.2, { delay: 0.4, autoAlpha: 1 });
	tl.call(() => {
		topChoice.classList.add("animate-in");
	});
	// tl.set(topChoice, { className: "+=animate-in" }, 0.2);
	tl.fromTo(
		[topChoiceContent, ...alternativeDivs],
		0.5,
		{ autoAlpha: 0, y: -100 },
		{ delay: 0.3, autoAlpha: 1, y: 0 },
	);
	return tl;
};

// In this context, el is the outermost Wizard
export const resultsOutroAnimation = (el) => {
	const topChoice = el.querySelector(".product-wizard--top-choice");
	const topChoiceContent = el.querySelector(
		".product-wizard--top-choice-content",
	);
	const alternativeDivs = el.querySelectorAll(
		".product-wizard--alternatives > div",
	);
	const tl = gsap.timeline({ paused: true });
	tl.fromTo(
		[topChoiceContent, ...alternativeDivs],
		0.5,
		{ autoAlpha: 1, y: 0 },
		{ autoAlpha: 0, y: -100 },
	);
	tl.call(() => {
		topChoice.classList.remove("animate-in");
		el.classList.remove("results");
	});
	return tl;
};

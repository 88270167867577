import axios from "axios";
import {chunk} from "../../../js/utils";
import {Basket, Customer, JWTType, Product, ProductDetailsLink, ProductItem} from "@/types";

const ds = (window as any).drupalSettings;

export const domain = (ds && ds.lodge && ds.lodge.sfcc) || "";
export const ocapiBase = `${domain}/s/lodge/`;
export const ocapiClientId = "db2801c7-2c67-4baa-8e65-8d48d8c348fe";
export const ocapiVersion = "v19_5";
export const controllerPath = "/on/demandware.store/Sites-lodge-Site/default/";

interface AuthResponse {
	jwt: string;
	customer: Customer;
}
let autoRefreshTimer: number | null = null;
export function setAutoRefreshTimer(timer: number | null) {
	autoRefreshTimer = timer;
}

/**
 * get JWT from OCAPI
 * @param type {string} - denotes the type of jwt call
 * @param username {string} - needed if type is "credentials"
 * @param password {string} - needed if type is "credentials"
 */
export async function getJWT(
	type: JWTType,
	username?: string,
	password?: string,
	jwt?: string,
): Promise<AuthResponse> {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/customers/auth?client_id=${ocapiClientId}`;
	console.log(url, type);
	let post;
	switch (type) {
		case "credentials": {
			if (!username || !password) {
				throw new Error(
					"username and password are required to get JWT with credentials",
				);
			}
			post = axios.post(
				url,
				{
					type,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Basic ${btoa(`${username}:${password}`)}`,
					},
				},
			);
			break;
		}
		case "refresh": {
			post = axios.post(
				url,
				{
					type,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: jwt,
					},
				},
			);
			break;
		}

		case "session": {
			post = axios.post(
				url,
				{
					type,
				},
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
					},
				},
			);
			break;
		}
		case "guest":
		default: {
			post = axios.post(url, {
				type,
			});
			break;
		}
	}

	return post
		.then(r => {
			console.log("auth", r);
			return r;
		})
		.then(r => ({
			customer: r.data,
			jwt: r.headers.authorization,
		}))
		.catch(e => {
			if (type !== "refresh") {
				let error = `Error getting JWT. Type: ${type}. Error: ${JSON.stringify(
					e,
				)}`;
				console.log(error);
				throw new Error(error);
			} else {
				let error = `Error getting JWT. Type: ${type}. Error: ${JSON.stringify(
					e,
				)}`;
				console.log(error);
				// show error message about session being expired
				let message = document.createElement("div");
				message.classList.add("session-expired");
				message.append(
					"Your session has expired.",
					document.createElement("br"),
					"Please refresh the page to continue shopping.",
				);
				document.body.appendChild(message);
				document.body.style.overflow = "hidden";
				autoRefreshTimer && clearInterval(autoRefreshTimer);
				return e;
			}
		});
}

/**
 * Generates the session cookies needed to transition from Drupal to SFCC site
 */
export async function getSession(jwt: string) {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/sessions?client_id=${ocapiClientId}`;
	return axios
		.post(url, null, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		})
		.then(r => r.data)
		.catch(e => {
			let error = `Error creating session. JWT: ${jwt}. Error: ${JSON.stringify(
				e,
			)}`;
			console.log(error);
			throw new Error(error);
		});
}

/**
 * creates a new basket on Salesforce using OCAPI
 */
export async function getCustomerBasket(
	jwt: string,
	customerId: string,
	basket?: Basket,
): Promise<Basket> {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/customers/${customerId}/baskets?client_id=${ocapiClientId}`;
	return axios
		.get(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		})
		.then(r => {
			console.log("basket results", r);
			if (r.data.total > 0) {
				return r.data.baskets[0];
			} else {
				return basket && basket.product_items
					? recreateBasket(jwt, basket)
					: createBasket(jwt);
			}
		})
		.catch(e => {
			let error = `Error getting customer baskets. 
      JWT: ${jwt}. 
      Customer ID: ${customerId}.
      Error: ${JSON.stringify(e)}`;
			console.log(error);
			throw new Error(error);
		});
}

export async function getBasket(
	jwt: string,
	basketId: string,
): Promise<Basket> {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/baskets/${basketId}?client_id=${ocapiClientId}`;
	return axios
		.get(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		})
		.then(r => r.data)
		.catch(e => {
			let error = `Error getting Basket. JWT: ${jwt}. BasketId: ${basketId}. Error: ${JSON.stringify(
				e,
			)}`;
			console.log(error);
			throw new Error(error);
		});
}

/**
 * get an existing basket on Salesforce using OCAPI
 */
export async function createBasket(jwt: string): Promise<Basket> {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/baskets?client_id=${ocapiClientId}`;
	return axios
		.post(url, null, {
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		})
		.then(r => r.data)
		.catch(e => {
			if (
				e.response.data.fault.type === "CustomerBasketsQuotaExceededException"
			) {
				return getBasket(jwt, e.response.data.fault.arguments.basketIds);
			} else {
				let error = `Error creating Basket. JWT: ${jwt}. Error: ${JSON.stringify(
					e,
				)}`;
				console.log(error);
				throw new Error(error);
			}
		});
}

export async function getBasketById(
	jwt: string,
	basketId: string,
): Promise<Basket> {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/baskets/${basketId}?client_id=${ocapiClientId}`;
	return axios
		.get(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		})
		.then(r => r.data)
		.catch(e => {
			let error = `Error getting Basket. Basket ID: ${basketId}. Error: ${JSON.stringify(
				e,
			)}`;
			console.log(error);
			throw new Error(error);
		});
}

/**
 *
 * @param basketId {string | undefined}
 * @param productItems {Array<ProductItem>}
 */
export async function addProductsToBasket(
	jwt: string,
	basketId: string,
	productItems: ProductItem[],
): Promise<Basket> {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/baskets/${basketId}/items?client_id=${ocapiClientId}`;
	return axios
		.post(url, productItems, {
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		})
		.then(r => r.data)
		.catch(e => {
			let error = `Error adding products to Basket. JWT: ${jwt}.
       Basket Id: ${basketId}.
       Products: ${JSON.stringify(productItems)}.
       Error: ${JSON.stringify(e)}`;
			console.log(error);
			throw new Error(error);
		});
}

/**
 * Add bonus product to basket
 * @param jwt
 * @param basketId
 * @param productId
 * @param bonusItemId
 * @param qty
 */
export async function addBonusProductToBasket(
	jwt: string,
	basketId: string,
	productId: string,
	bonusProductLineItemUUID: string | undefined,
	bonusItemId: string,
	qty: number,
): Promise<Basket> {
	// Cart-AddBonusProducts
	// ?pids={"bonusProducts":[{"pid":"008885004519M","qty":1,"options":[null]}],"totalQty":1}
	// &uuid=0a6c45ca93022dbae29b885b51
	// &pliuuid=41b75ecab2a69ae62ff205f384
	// const url = `${domain}${controllerPath}Cart-AddBonusProducts?`;
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/baskets/${basketId}/items?client_id=${ocapiClientId}`;
	return axios
		.post(url, [{
			product_id: productId,
			bonus_discount_line_item_id: bonusItemId,
			c_bonusProductLineItemUUID: bonusProductLineItemUUID,
			quantity: qty,
		}], {
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		})
		.then(r => r.data)
		.catch(e => {
			let error = `Error adding bonus product to Basket. JWT: ${jwt}.
       Basket Id: ${basketId}.
       Products: ${bonusItemId}.
       Error: ${JSON.stringify(e)}`;
			console.log(error);
			throw new Error(error);
		});
}

/**
 *
 * @param basketId {string | undefined}
 * @param productItem {Array<ProductItem>}
 */
export async function removeProductFromBasket(
	jwt: string,
	basketId: string,
	productItem: ProductItem,
): Promise<Basket> {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/baskets/${basketId}/items/${productItem.item_id}?client_id=${ocapiClientId}`;
	return axios
		.delete(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		})
		.then(r => r.data)
		.catch(e => {
			let error = `Error removing product from Basket. JWT: ${jwt}.
       Basket Id: ${basketId}.
       Products: ${JSON.stringify(productItem)}.
       Error: ${JSON.stringify(e)}`;
			console.log(error);
			throw new Error(error);
		});
}

export async function updateProductQuantityInBasket(
	jwt: string,
	basketId: string,
	productItem: ProductItem,
	quantity: number,
): Promise<Basket> {
	console.log(basketId, productItem);
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/baskets/${basketId}/items/${productItem.item_id}?client_id=${ocapiClientId}`;
	return axios
		.patch(
			url,
			{
				product_id: productItem.product_id,
				quantity,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			},
		)
		.then(r => r.data)
		.catch(e => {
			let error = `Error updating product quantity in Basket. JWT: ${jwt}.
       Basket Id: ${basketId}.
       Products: ${JSON.stringify(productItem)}.
       Quantity: ${quantity}
       Error: ${JSON.stringify(e)}`;
			console.log(error);
			throw new Error(error);
		});
}

/**
 * creates a new basket on Salesforce using OCAPI and populates it with existing products
 * @param jwt {string}
 * @param basket {Basket}
 */
export async function recreateBasket(
	jwt: string,
	basket: Basket,
): Promise<Basket> {
	let newBasket = await createBasket(jwt);
	return await addProductsToBasket(
		jwt,
		newBasket.basket_id,
		basket.product_items || ([] as ProductItem[]),
	);
}

/**
 * Register a new customer
 * @param username
 * @param password
 * @param lastName
 */
export async function registerCustomer(
	jwt: string,
	username: string,
	password: string,
	lastName: string,
) {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/customers?client_id=${ocapiClientId}`;
	return axios
		.post(
			url,
			{
				customer: {
					login: username,
					email: username,
					last_name: lastName,
				},
				password: password,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			},
		)
		.then(r => r.data)
		.catch(e => {
			let error = `Error registering customer. JWT: ${jwt}.
				Error: ${JSON.stringify(e)}`;
			console.log(error);
			throw new Error(error);
		});
}

const fetchProductDetails = (jwt: string) => (ids:Array<string>) => {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/products/(${ids.join(',')})?expand=images,availability,variations&client_id=${ocapiClientId}`;
	return axios
		.get(url)
		.then(r => r.data.data as Product[])
		.catch(e => {
			let error = `Error getting Image groups for ${ids.join(', ')}.
      					JWT: ${jwt}.
								Error: ${JSON.stringify(e)}`;
			console.log(error);
			throw new Error(error);
		});
}

/**
 * Get image groups object for a product by its ID
 * @param productId {string}
 */
export async function getUpdatedProductsForProductItems(
	jwt: string,
	basket: Basket,
	currentProducts: Product[],
): Promise<Array<Product>> {
	let newProducts, productItems;
	if (!basket.product_items) {
		return [] as Product[];
	} else {
		productItems = basket.product_items;
	}
	try {
		const productIds = chunk(productItems
			.filter(p => {
				return !currentProducts.some(
					(storedProduct: Product) => storedProduct.id === p.product_id,
				);
			})
			.map(p => p.product_id), 24); // the OCAPI endpoint can only return results for 24 IDs at a time

		newProducts = await Promise.all(productIds.map(fetchProductDetails(jwt)));

		newProducts = newProducts.flat();

	} catch (e) {
		newProducts = [] as Product[];
	}
	return currentProducts.concat(newProducts as Product[]);
}

/**
 * Get image groups object for a product by its ID
 * @param productId {string}
 */
export async function getUpdatedProductsForBonusItems(
	jwt: string,
	basket: Basket,
	bonusProducts: ProductDetailsLink[],
): Promise<Array<Product>> {
	let newProducts, productItems;
	try {
		const productIds = chunk(bonusProducts.map(p => p.product_id), 24); // the OCAPI endpoint can only return results for 24 IDs at a time

		newProducts = await Promise.all(productIds.map(fetchProductDetails(jwt)));
		newProducts = newProducts.flat();

	} catch (e) {
		newProducts = [] as Product[];
	}
	return newProducts as Product[];
}

/**
 * Get explicit recommendations for a product by its ID
 * Optionally filtered by recommendation type
 * @param productId {string}
 * @param recommendationType {number}
 */
export async function getProductRecommendations(
	jwt: string,
	productId: string,
	recommendationType?: number,
) {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/products/${productId}/recommendations?${
		recommendationType ? `recommendation_type=${recommendationType}&` : ""
	}client_id=${ocapiClientId}`;
	return axios
		.get(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		})
		.then(r => r.data)
		.catch(e => {
			let error = `Error getting product recommendations. JWT: ${jwt}.
       Product ID: ${productId}.
       Error: ${JSON.stringify(e)}`;
			console.log(error);
			throw new Error(error);
		});
}

/**
 * Updates a customer's recipe list (aka cookbook)
 * @param jwt {string}
 * @param customerId {string}
 */
export async function updateCustomerRecipeList(
	jwt: string,
	customerId: string,
	payload: { recipeList: [string]; env?: string },
) {
	const url = `${ocapiBase}dw/shop/${ocapiVersion}/customers/${customerId}?client_id=${ocapiClientId}`;
	return axios
		.patch(
			url,
			{
				c_cookbookRecipes: payload.recipeList,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			},
		)
		.then(r => r.data)
		.catch(e => {
			console.log("error updating recipes", e.response);
			let error = {
				error: `Error updating customer recipe list. JWT: ${jwt}.
       Customer ID: ${customerId}.
       Error: ${JSON.stringify(e.response)}`,
				response: e.response.data,
			};
			console.log(error);
			throw error;
		});
}

<template>
  <div class="summary">
    <!--

    TODO if New / Arrived Broken / Lodge / No Gift / No damage to box / Replace - Auto create an order

    UPS Claim : Lodge / New /
      Missing Order || Product Arrived Damaged || Missing Product with Shipping Box Damaged
      Whole box missing - missing part in its own shipment - deal with in the future - for now just send to CC
    -->
    <div class="frow justify-between">
      <div v-if="resolvedFlag || requestedRemedies.every(r => r === 'none')" class="label col-xs-1-1 col-sm-5-12">
        <p v-if="node.subtitle" class="subtitle--line brown">{{ node.subtitle }}</p>
        <h1 class="h2 mb-0">Can we help you with anything else today?</h1>
        <p class="mt-30" v-if="node.copy">Start another product return or shop our products</p>
        <div class="frow inline">
          <a href="https://www.lodgecastiron.com/shop" class="btn btn--black btn--border borderless w-auto px-4">Shop our products</a>
          <button @click="reload" class="ml-2 btn btn--border btn--arrow btn--primary borderless w-auto">Start
            another return
          </button>
        </div>
      </div>
      <div v-else class="label col-xs-1-1 col-sm-5-12">
        <p v-if="node.subtitle" class="subtitle--line brown">{{ node.subtitle }}</p>
        <h1 class="h2 mb-0">{{ node.label }}</h1>
        <p class="mt-30" v-if="node.copy" v-html="node.copy"></p>
        <div class="frow inline">
          <a href="https://www.lodgecastiron.com/shop" class="btn btn--black btn--border borderless w-auto px-4">Shop our products</a>
          <button @click="reload" class="ml-2 btn btn--border btn--arrow btn--primary borderless w-auto">Start
            another return
          </button>
        </div>
      </div>
      <div v-if="issuesResponse && !resolvedFlag && !requestedRemedies.every(r => r === 'none')" class="node-wrap col-xs-1-1 col-sm-6-12 frow">
        <div v-if="issuesResponse.every(i => i.status === 'success' && !i.error)">
          <div v-for="(issue, index) in request.issues" class="frow products">
            <div class="product frow justify-between w-100 px-4 items-center">
              <div class="frow justify-start items-center">
                <img v-if="issue.product.image" class="product-image mr-4"
                     :src="typeof issue.product.image === 'object' ? issue.product.image.url : issue.product.image"
                     :alt="issue.product.image.alt || `Image of ${issue.product.name}`"/>
                <div class="product-detail">
                  <strong>{{ issue.product.name }}</strong><br>
                  <span class="sku">SKU: {{ issue.product.id || "N/A" }}</span>
                </div>
                <div v-if="issuesResponse[index] && issuesResponse[index]['caseStatus'] !== 'Resolved'" class="mt-4">
                  <p>Thank you for sending us your case!</p>
                  <p>Your case number is <strong>{{ issuesResponse[index].caseNumber }}</strong>
                    <copy-to-clipboard
                        :clipboard-text="issuesResponse[index].caseNumber"
                        title="click to copy the case number">
                    </copy-to-clipboard>, and a support agent will be in touch with you soon. </p>
                  <p v-if="issuesResponse[index].RA">Your Return Authorization Number is: <strong>{{ issuesResponse[index].RA }}</strong>
                    <copy-to-clipboard
                        :clipboard-text="issuesResponse[index].RA"
                        title="click to copy the RA number">
                    </copy-to-clipboard></p>
                </div>
                <div class="w-100">
                  <p><strong>Problem:</strong> {{ issue.type }}: {{ issue.subType }}</p>
                  <p v-if="issuesResponse[index].RA && issue.raResponse"><strong>Solution:</strong> <span v-html="issue.raResponse"></span></p>
                  <p v-else-if="issue.response && issue.hasResponse"><strong>Solution:</strong> <span v-html="issue.response"></span></p>
                </div>
                <template v-if="issue.missingParts.length > 0" >
                  <div class="w-100 mr-2"><strong>Missing Part{{ issue.missingParts.length > 1 ? "s" : "" }}:</strong></div>
                  <div class="w-100 frow justify-start items-center" v-for="part in issue.missingParts">
                    <img v-if="part.image" class="product-image mr-4"
                         :src="typeof part.image === 'object' ? part.image.url : part.image"
                         :alt="part.image.alt || `Image of ${part.name}`"/>
                    <div class="product-detail">
                      <strong>{{ part.name }}</strong><br>
                      <span v-if="part.id" class="sku">SKU: {{ part.id || "N/A" }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="error">
            Sorry there was an error submitting your request.
          </div>
          <div>
            You can send the information you just gave us to customer support via email instead.
            <a class="btn btn--primary w-auto p-4 mt-2"
               :href="`mailto:info@lodgecastiron.com?subject=Product%20Issue&body=${emailFormattedRequest}`"
               target="_blank">Email Customer Support</a>
          </div>
        </div>
      </div>
      <div v-else-if="requestedRemedies.every(r => r === 'none')" class="node-wrap col-xs-1-1 col-sm-6-12 frow">
        <div v-for="(issue, index) in request.issues" class="frow products">
          <div class="product frow justify-between w-100 px-4 items-center">
            <div class="frow justify-start items-center">
              <img v-if="issue.product.image" class="product-image mr-4"
                   :src="typeof issue.product.image === 'object' ? issue.product.image.url : issue.product.image"
                   :alt="issue.product.image.alt || `Image of ${issue.product.name}`"/>
              <div class="product-detail">
                <strong>{{ issue.product.name }}</strong><br>
                <span class="sku">SKU: {{ issue.product.id || "N/A" }}</span>
              </div>
              <div class="w-100">
                <p><strong>Problem:</strong> {{ issue.type }}: {{ issue.subType }}</p>
                <p v-if="issue.response && issue.hasResponse"><strong>Solution:</strong> <span v-html="issue.response"></span></p>
              </div>
              <template v-if="issue.missingParts.length > 0" >
                <div class="w-100 mr-2"><strong>Missing Part{{ issue.missingParts.length > 1 ? "s" : "" }}:</strong></div>
                <div class="w-100 frow justify-start items-center" v-for="part in issue.missingParts">
                  <img v-if="part.image" class="product-image mr-4"
                       :src="typeof part.image === 'object' ? part.image.url : part.image"
                       :alt="part.image.alt || `Image of ${part.name}`"/>
                  <div class="product-detail">
                    <strong>{{ part.name }}</strong><br>
                    <span v-if="part.id" class="sku">SKU: {{ part.id || "N/A" }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!resolvedFlag" class="node-wrap col-xs-1-1 col-sm-6-12 frow centered">
        Hang on while we submit your request. <div class="lds-ring black"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import mixins from './mixins';
import dataModel from './productHelpDataModel';
import CopyToClipboard from './copyToClipboard';

export default {
  name: "Submit",
  mixins: [mixins],
  components: { CopyToClipboard },
  props: [
    "step",
    "node",
  ],
  computed: {
    ...mapGetters({
      issuesResponse: "productHelp/getIssuesResponse",
      request: "productHelp/getRequest",
      answers: "productHelp/getAnswers",
      csrfToken: "productHelp/getCSRFToken",
      resolvedFlag: "productHelp/getResolvedFlag",
      requestedRemedies: "productHelp/getRequestedRemedies",
    }),
    concerns() {
      return dataModel.concerns.filter(concern => {
        // use meta to match against answers
        let caseRecordTypeFilters = concern.meta.caseRecordTypeFilters;
        let concernFilterKeys = caseRecordTypeFilters ? Object.keys(caseRecordTypeFilters) : [];
        return concernFilterKeys.length > 0 && concernFilterKeys.every(key => {
          if (this.answers[key]) {
            let answer = typeof this.answers[key] === "string"
                ? this.answers[key]
                : this.answers[key].value || this.answers[key].id;
            return caseRecordTypeFilters[key].includes(answer);
          } else {
            return false;
          }
        });
      });
    },
    parsedRequest() {
      return this.request && this.objToHTML(this.request, 11);
    },
    emailFormattedRequest() {
      let orderInfo = Object.keys(this.request.order).map(k => {
        return `${k}: ${this.request.order[k]}`
      }).join("");
      let issuesInfo = this.request.issues.map(i => {
        return `
  Case Record Type: ${i.caseRecordType}
  Type: ${i.type}
  SubType: ${i.subType}
  Cause: ${i.cause}
  Purchase Location: ${i.purchaseLocation}
  Requested Remedy: ${i.requestedRemedy}
  Remedy Amount: ${i.remedyAmount}
  Product: ${i.product.id} - ${i.product.name}
  ${i.missingParts ? `Missing: ${i.missingParts}`: ''}
  ${i.photos.length ? i.photos.join(",") : ''}
  Response: ${i.response}
  Notes: ${i.notes.split("\n").map(n => `   ${n}`).join("\n")}`
      }).join("");

      return this.request && encodeURIComponent(`Name: ${this.request.name}
Address: ${this.request.address1}, ${this.request.address2}, ${this.request.city} ${this.request.state} ${this.request.zipcode} ${this.request.country}
Phone: ${this.request.phone}
${this.request.order.orderNumber ? `Order: ${orderInfo}`: ''}
Issues: ${issuesInfo}`);
    },
  },
  methods: {
    ...mapMutations({
      updateRequest: "productHelp/updateRequest",
      setAnswer: "productHelp/setAnswer",
    }),
    ...mapActions({
      submitIssues: "productHelp/submitIssues",
    }),
    objToHTML(obj, indent = 0) {
      const nbsps = Array.from({ length: indent }, () => "&nbsp;").join("");
      return JSON.stringify(obj, null, 2)
          .replace(/\\n/gi, `<br>${nbsps}`);
    },
    // goToStart() {
    //   this.setAnswer({
    //     id: "submit",
    //     answer: {
    //       text: "No",
    //       value: "no",
    //     },
    //   });
    //   this.setNextStep({
    //     step: "no",
    //   });
    //   this.goToNextStep();
    // },
    reload() {
      window.location.reload(false);
    }
  },
  mounted() {
    this.updateRequest();
    this.submitIssues({
      request: this.request,
      csrfToken: this.csrfToken,
    });
  },
};
</script>

<style scoped>

</style>

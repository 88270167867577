import axios from "axios";
import {
	domain as sfccDomain,
	controllerPath,
	getProductRecommendations,
} from "../../../../lodge-vue/src/ocapi";
import { DRUPAL_API } from "../../../utils";
import regeneratorRuntime from "regenerator-runtime";
import { gtmCustomEventPush, EVENTS } from "../../../analytics";

const getProductDataFromDrupal = async (jwt, sku, type) => {
	const recommendationData = await getProductRecommendations(jwt, sku, type);
	if (recommendationData.recommendations) {
		const { recommendations } = recommendationData;
		const skus = recommendations.reduce((acc, curr) => {
			const sku = curr.recommended_item_id;
			return (acc += acc.length > 0 ? `,${sku}` : `${sku}`);
		}, "");
		const variantData = await axios
			// .get(`${DRUPAL_API}/salesforce/variant?skus=${skus}`)
			.get(`${sfccDomain}${controllerPath}API-VariantContent?skus=${skus}`)
			.then(res => res.data.variants)
			.then(data =>
				data.map(p => {
					p.content.sku = p.sku;
					return Object.assign({}, { sku: p.sku }, p.content);
				}),
			);

		return variantData;
	}

	return [];
};
export default {
	async fetchRelatedItems({ commit }, { sku, jwt, type }) {
		try {
			console.log(33, "fetch related items", sku);
			const data = await getProductDataFromDrupal(jwt, sku, type);
			commit("setAssociatedProducts", { sku, data });
		} catch (err) {
			gtmCustomEventPush({
				event: EVENTS.ASSOCIATED_PRODUCT_FAILURE,
				extraData: {
					sku,
					type,
				},
			});
			commit("setAssociatedProducts", { sku, data: [] });
		}
	},
	async fetchFreeItems({ commit }, { id }) {
		try {
			const data = await axios
			.get(`${sfccDomain}${controllerPath}API-GiftProductDetails?productId=${id}`)
			.then(res => res.data.product)
			commit("setFreeProducts", { data } );
			console.log(data);
		} catch (err) {
			commit("setFreeProducts", { data  :{}});
		}

	},
	async fetchCustomerRecommendations({ commit }, { jwt, sku, type }) {
		try {
			console.log("customer recommendations", sku);
			const data = await getProductDataFromDrupal(jwt, sku, type);
			commit("setRecommendations", { data });
		} catch (err) {
			gtmCustomEventPush({
				event: EVENTS.CUSTOMER_RECOMMENDATION_FAILURE,
				extraData: {
					sku,
					type,
				},
			});
			commit("setRecommendations", { data: [] });
		}
	},
};

<template>
  <div id="product-help">
    <div class="frow centered frow-container" id="product-help-wrapper">
      <div class="col-xs-12 col-sm-10-12 col-md-11-12">
        <div v-if="answers && debug" class="current-cases frow column-start mb-4 summary">
          <div v-for="(answer, key) in answers">
            {{ key }} : {{ answer.value || answer.id || answer }}
          </div>
        </div>
        <div v-if="prevIssues.length > 0 && currentStep !== 'submit'"
             class="current-cases frow column-start mb-4 summary">
          <p>Cases you have created so far:</p>
          <div v-for="issue in prevIssues" class="frow column-start">
            <div><strong>Problem:</strong> <span>{{ issue.subType }}</span></div>
            <div class="frow row-start product mb-3">
              <div><img :src="issue.product.image.url || issue.product.image"
                        :alt="issue.product.image.alt || issue.product.name"></div>
              <div class="detail">
                <strong>{{ issue.product.id }}</strong>
                <br>{{ issue.product.name }}
              </div>
            </div>
          </div>
        </div>
        <div :class="{replaying: replaying}">
          <form
              class="product-help-form"
              action="#"
              method="get"
              id="product-help-form"
              accept-charset="UTF-8"
              @submit.prevent
          >
            <div>
              <transition name="fade" mode="out-in">
                <component
                    :step="currentStep"
                    :node="dataModel.nodes[currentStep]"
                    :is="dataModel.nodes[currentStep].type"
                ></component>
              </transition>
            </div>
            <div class="frow justify-end">
              <div class="col-xs-6-12 button-wrapper frow justify-end">
                <template v-if="currentStep !== 'submit'">
                  <div class="col-xs-1-1 frow justify-end mb-4"
                       v-if="selectedConcernHasResponse">
                    <button ref="next" class="btn--text-link"
                            :disabled="!nextStep"
                            @click.enter="setIssueUnsolved">Still Need Help?
                    </button>
                  </div>
                  <div class="">
                    <button ref="back" class="btn btn--border btn--primary borderless back" :disabled="path.length < 3"
                            @click.enter="goToPreviousStep">
                      Back
                    </button>
                  </div>
                  <div class="">
                    <button v-if="selectedConcernHasResponse"
                            ref="done" class="btn btn--border btn--arrow btn--primary borderless done mr-4"
                            @click.enter="setIssueSolved">Issue Solved
                    </button>
                    <button v-else ref="next" class="btn btn--border btn--arrow btn--primary borderless next"
                            :disabled="!nextStep"
                            @click.enter="goToNextStep">Next
                    </button>
                  </div>
                </template>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Start from "./ProductHelp/Start";
import Question from "./ProductHelp/Question";
import DateInput from "./ProductHelp/DateInput";
import TextInput from "./ProductHelp/TextInput";
import MultiNode from "./ProductHelp/MultiNode";
import Reason from "./ProductHelp/Reason";
import SelectConcern from "./ProductHelp/SelectConcern";
import Information from "./ProductHelp/Information";
import PhotoUploader from "./ProductHelp/PhotoUploader";
import ContactInformation from "./ProductHelp/ContactInformation";
import ProductFinder from "./ProductHelp/ProductFinder";
import OrderFinder from "./ProductHelp/OrderFinder";
import PartsMissing from "./ProductHelp/PartsMissing";
import Submit from "./ProductHelp/Submit";
import End from "./ProductHelp/End";
import dataModel from "./ProductHelp/productHelpDataModel";
import { mapMutations, mapGetters } from "vuex";
import NodeWrap from './ProductHelp/NodeWrap';
import mixins from "./ProductHelp/mixins";

export default {
  name: "ProductHelp",
  components: {
    NodeWrap,
    Start,
    Question,
    DateInput,
    TextInput,
    MultiNode,
    Reason,
    SelectConcern,
    Information,
    PhotoUploader,
    ContactInformation,
    ProductFinder,
    OrderFinder,
    PartsMissing,
    Submit,
    End,
  },
  props: [
    "csrfToken",
  ],
  mixins: [mixins],
  data() {
    return {
      dataModel,
      debug: false,
    };
  },
  computed: {
    ...mapGetters({
      currentStep: "productHelp/getCurrentStep",
      nextFocus: "productHelp/getNextFocus",
      prevIssues: "productHelp/getPrevIssues",
      answers: "productHelp/getAnswers",
      autoContinue: "productHelp/getAutoContinue",
    }),
    selectedConcernHasResponse() {
      return this.answers
          && this.answers["select-concern"]
          && this.answers["select-concern"].hasResponse === "yes"
          && !this.answers["select-concern"].finalResponse
          && this.currentStep === "select-concern";
    },
  },
  methods: {
    // mutations used below are pulled in via mixins
    ...mapMutations({
      setRemedy: "productHelp/setRemedy",
      setNextFocus: "productHelp/setNextFocus",
    }),
    setIssueSolved() {
      this.setRemedy({
        remedy: "none"
      });
      this.goToEnd(true);
    },
    setIssueUnsolved() {
      this.setResolvedFlag({
        resolved: false
      });
      this.goToNextStep()
    }
  },
  watch: {
    nextStep(val, oldVal) {
      let autoContinue = this.dataModel.nodes[this.currentStep].meta
          && this.dataModel.nodes[this.currentStep].meta.autoContinue;
      console.log("is replaying", this.replaying);
      if (val && autoContinue && !this.replaying) {
        this.goToNextStep();
      }
    },
    nextFocus(focus) {
      if (focus) {
        console.log("focus the 'next' button", this.$refs.next);
        this.$refs.next.focus();
        this.setNextFocus({
          focus: false,
        });
        this.goToNextStep();
      }
    },
  },
  created() {
    let tree = this.parseTree(this.dataModel.tree);
    this.setCSRFToken({
      csrfToken: this.csrfToken,
    });
    this.setCurrentStep({
      step: Object.keys(tree.children)[0],
    });
    // adds the first step to the path
    this.addPathStep({
      step: this.currentStep,
      tree,
    });
    this.setTree({
      tree,
    });

    window.scroll({
      left: 0,
      top: 100,
      behavior: 'smooth',
    });
  },
  mounted() {
    this.debug = window.location.hash.includes("debug");
    window.onhashchange = () => {
      this.debug = window.location.hash.includes("debug");
    };
  },
};
</script>

<style scoped>

</style>

<template>
	<div v-if="!quizTaken" class="quiz component">
		<QuizContainer
			@finished="quizIsFinished"
			v-if="JSONQuizData.pages.length"
			:quiz="JSONQuizData"
			v-slot="quizData"
		>
			<div>
				<Pagination
					:show="quizData.pages[quizData.current].type !== 'result'"
					:total="quizData.pages.length"
					:current="quizData.current"
					:back="quizData.movement.prev"
				/>
				<Page
					v-for="(page, idx) in quizData.pages"
					:key="`page-${idx}`"
					:page="page"
					:movement="quizData.movement"
					:choices="quizData.choices"
					:choicesResults="quizData.choicesResults"
					:class="{
						active: idx === quizData.current,
						results: idx === quizData.pages.length - 1,
					}"
				/>
			</div>
		</QuizContainer>
	</div>
</template>

<script>
import quizData from "./Quiz/quizData";
import Page from "./Quiz/Page.vue";
import QuizContainer from "./Quiz/QuizContainer.vue";
import Pagination from "./Quiz/Pagination.vue";
import { lodgeLocalStorage } from "../../classes/LodgeStorage";

export default {
	components: {
		QuizContainer,
		Page,
		Pagination,
	},
	data() {
		return {
			JSONQuizData: quizData,
			quizTaken: false,
		};
	},
	methods: {
		quizIsFinished() {
			lodgeLocalStorage.set("quiz", true);
		},
	},
	mounted() {
		if (lodgeLocalStorage.get("quiz")) {
			this.quizTaken = true;
			this.$el.closest("section").style.display = "none";
		}
	},
};
</script>
import * as Sentry from "@sentry/browser";
import { Vue as SentryVueIntegration } from "@sentry/integrations";
import "objectFitPolyfill";
import { gtmCustomEventPush, EVENTS } from "./analytics";
import Bowser from "bowser";

export const DESKTOP_SIZE = 992; // >= 992
export const TABLET_SIZE = 768; // =< 991
export const getSentryEnv = () => {
	switch (location.hostname) {
		case "www.lodgecastiron.com":
			return "production";
		case "stage.acquia.lodgecastiron.com":
			return "staging";
		case "dev.acquia.lodgecastiron.com":
			return "dev";
		default:
			return "unknown";
	}
};
let sentryHasInititated = false;
export const initSentry = (Vue) => {
	let environment = getSentryEnv();
	if (environment == "unknown") {
		return console.info(
			`Unknown Sentry environment, ${location.hostname}, not initiating Sentry reporting.`,
		);
	}
	if (sentryHasInititated) {
		return console.error("Sentry as already been initiated.");
	}
	sentryHasInititated = true;
	Sentry.init({
		dsn: "https://a3f5d17337794d00a2fcec782bf2dc59@sentry.io/3826867",
		integrations: !!Vue
			? [new SentryVueIntegration({ Vue, attachProps: true, logErrors: false })]
			: [],
		environment,
	});
	console.log(`Sentry initiated on ${getSentryEnv()}`);
};
export const isLayoutPage = !!document.querySelector(
	"#layout-builder",
	".node-page-layout-builder-form",
);
export const THEME_PATH = "/themes/custom/tombras";
export const DRUPAL_API = "/api/lodge";
export const GOOGLE_MAPS_KEY = `<KEY_IS_NEEDED>`;
/**
 * Helps keep track of component versions
 * @param {string} component
 * @param {string} version
 */
export const componentVersion = (component, version = "WIP") =>
	console.log(`${component} - ${version}`);

/**
 * Limits the invocations of a function in a given time frame.
 *
 * The debounce function wrapper should be used sparingly. One clear use case
 * is limiting the invocation of a callback attached to the window resize event.
 *
 * Before using the debounce function wrapper, consider first whether the
 * callback could be attached to an event that fires less frequently or if the
 * function can be written in such a way that it is only invoked under specific
 * conditions.
 *
 * @param {function} func
 *   The function to be invoked.
 * @param {number} wait
 *   The time period within which the callback function should only be
 *   invoked once. For example if the wait period is 250ms, then the callback
 *   will only be called at most 4 times per second.
 * @param {boolean} [immediate]
 *   Whether we wait at the beginning or end to execute the function.
 *
 * @return {function}
 *   The debounced function.
 */
export const debounce = (func, wait, immediate) => {
	let timeout;
	let result;
	return function (...args) {
		const context = this;
		const later = function () {
			timeout = null;
			if (!immediate) {
				result = func.apply(context, args);
			}
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			result = func.apply(context, args);
		}
		return result;
	};
};

/**
 * throttle func is called once per duration
 * @param func
 * @param duration
 * @returns {(function(...[*]=): void)|*}
 */
export const throttle = (func, duration) => {
	let shouldWait = false;
	return function (...args) {
		const context = this;
		if (!shouldWait) {
			console.log("running leading function", args);
			func.apply(context, args)
			shouldWait = true
			setTimeout(function () {
				shouldWait = false;
				console.log("running trailing function", args);
				func.apply(context, args);
			}, duration)
		}
	}
}

/**
 * Various Bowser utils
 */
const parser = Bowser.getParser(window.navigator.userAgent);
export const isMobile = () => {
	const OS = parser.getOS().name;
	console.log(OS);
	return ["android", "ios", "ipados"].indexOf(OS.toLowerCase()) >= 0;
};
export const isIE = () => {
	const browser = parser.getResult();
	console.log("browser name", browser);
	return browser.browser.name.toLowerCase() === "internet explorer";
};

export const qs = (selector, el = document) => el.querySelector(selector);
export const qsa = (selector, el = document) => el.querySelectorAll(selector);
export const LEFT = "LEFT";
export const RIGHT = "RIGHT";

export const callFnIfEnterKeyWasPressed = (fn) => (e) => {
	if (e.keyCode === 13) fn();
};

export function getUrlParameter(name) {
	name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
	var results = regex.exec(location.search);
	return results === null
		? ""
		: decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const queryParams = (locationSearch = window.location.search) =>
	locationSearch
		.substr(1)
		.split("&")
		.reduce(
			(acc, curr) =>
				Object.assign(
					acc,
					(curr.indexOf("=") !== -1 ? curr : `${curr}=`)
						.split("=")
						.reduce((acc, curr) => ({ [acc]: decodeURIComponent(curr) })),
				),
			{},
		);
export const createComponent = (selector, theClass) => {
	return [...qsa(selector)].map((el) => new theClass(el));
};

export const handleBackButtons = (selector) => {
	[...qsa(selector)].forEach((el) => {
		el.addEventListener("click", history.back);
		el.addEventListener("keydown", callFnIfEnterKeyWasPressed(history.back));
	});
};

export const handlePrintButtons = (selector) => {
	[...qsa(selector)].forEach((el) => {
		el.addEventListener("click", () => {
			window.print();
		});
		el.addEventListener(
			"keydown",
			callFnIfEnterKeyWasPressed(() => {
				window.print();
			}),
		);
	});
};

export const validProductProperties = [
	"Material",
	"Type",
	"Color",
	"Size",
	"Shape",
	"Collection",
	"Sale",
];

export const objectFitForIE = () => {
	if (isIE()) {
		const images = [
			".fifty-fifty-carousel img",
			".hero-block .image-holder img",
			".hero-block video",
			".product-viewer .image-holder img",
			".call-to-action img",
			// ".author-credits img",
			".parallax-image .image-wrapper img",
			// ".half-large-image--imagery-area .special-image.image-holder img",
			".half-large-image--imagery-area .card .image-holder img",
			".object-fit-cover",
			// ".menu--dropdown .promo img",
		];
		objectFitPolyfill(document.querySelectorAll(images.join(",")));
	}
};

export const chatNowBtn = () => {
	const chats = document.querySelectorAll(".btn-chat-now");
	[...chats].forEach((el) =>
		el.addEventListener("click", (e) => {
			e.preventDefault();

			if (embedded_svc) {
				embedded_svc.onHelpButtonClick();
				gtmCustomEventPush({
					event: EVENTS.STANDALONE_CHAT_NOW,
				});
			} else {
				gtmCustomEventPush({
					event: EVENTS.STANDALONE_CHAT_NOW,
					value: "Not loaded",
				});
			}
		}),
	);
};

export const luma = (hex) => {
	// source: https://stackoverflow.com/a/12043228
	const c = hex.substring(1); // strip #
	const rgb = parseInt(c, 16); // convert rrggbb to decimal
	const r = (rgb >> 16) & 0xff; // extract red
	const g = (rgb >> 8) & 0xff; // extract green
	const b = (rgb >> 0) & 0xff; // extract blue
	return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
};

export const getSwatchOrColor = (color) => {
	return !color.swatch ? color.hex : `url(${color.swatch})`;
};

export const getProductColorBorderColor = (color, defaultColor = "#ccc") => {
	return color.indexOf("#") !== 0
		? defaultColor
		: luma(color) < 205
		? color
		: "#ccc";
};

export const playMobileFullscreenHelper = (
	stream,
	identifier = "unidentified stream",
) => {
	console.log("playing mobile fullscreen", this.stream);
	stream.play();
	// try {
	// 	stream.webkitEnterFullScreen();
	// } catch (err) {
	// 	console.warn(`${identifier} - stream:`, err);
	// 	try {
	// 		const innerVideo = stream.querySelector("video");
	// 		console.log(innerVideo);
	// 		innerVideo.webkitEnterFullscreen();
	// 	} catch (err) {
	// 		console.warn(`${identifier} - direct 2 video:`, err);
	// 		stream.pause();
	// 	}
	// }
};

export const addNewBadgeToRecipes = () => {
	const createdDates = [...document.querySelectorAll("[data-created-date]")];
	const today = new Date();
	createdDates.forEach((el) => {
		const daysSincePosting =
			(today - new Date(el.getAttribute("data-created-date"))) /
			1000 /
			60 /
			60 /
			24;
		if (daysSincePosting < 14) {
			const inner = el.querySelector("span") || el;
			inner.innerHTML = "New Recipe!";
			el.removeAttribute("aria-hidden");
			el.classList.remove("visually-hidden");
		}
	});
};

export const copyBlockID = (e) => {
	const uuid = e.target.getAttribute("data-copy-uuid");

	navigator.clipboard
		.writeText(uuid)
		.then(() => {
			e.target.innerText = "Copied!";
		})
		.catch(() => {
			e.target.innerText = "Click again";
		});
};

/**
 *
 * @param items {Array}
 * @param size {Number}
 */
export function chunk (items, size) {
	const chunks = []
	items = [].concat(...items)

	while (items.length) {
		chunks.push(
			items.splice(0, size)
		)
	}

	return chunks
}

/**
 * Set equal heights of components
 * @param src string | class name
 */
export const equalHeights = (() => {
	const _self = {};
	let _elements = [];

  const resetHeight = () => {
		return new Promise((resolve) => {
			_elements.slice(0).forEach((el) => {
				el.setAttribute('style', 'position: relative; height: auto');
			});
			resolve();
		});
  }

  const setHeight = (hgt) => {
    _elements.slice(0).forEach((el) => {
      el.setAttribute('style', 'position: relative; height: 100%; min-height: ' + hgt + 'px');
    });
  }

  const getTallestElement = () => {
		return new Promise((resolve) => {
			const tallest = _elements.slice(0).map((el) => el.offsetHeight)
			.reduce((previousHgt, currentHgt) => (currentHgt > previousHgt) ? currentHgt : previousHgt, 0);
			resolve(tallest);
		});
  };

  _self.reset = async (src) => {
    _elements = [...document.querySelectorAll(src)];
		await resetHeight();
  };

  _self.set = async (src) => {
    _elements = [...document.querySelectorAll(src)];
		await resetHeight();
		const tallest = await getTallestElement();
    setHeight(tallest);
  };

	return _self;
})();

<template>
  <div>
    <div class="frow">
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import mixins from './mixins';

export default {
  name: "Start",
  mixins: [mixins],
  props: [
      "step",
      "node"
  ],
  computed: {
    ...mapGetters({
      currentIssueId: "productHelp/getCurrentIssueId",
      order: "productHelp/getOrder",
    })
  },
  methods: {
    ...mapMutations({
      setCurrentStep: "productHelp/setCurrentStep",
      addPathStep: "productHelp/addPathStep",
      setFollowingStep: "productHelp/setFollowingStep",
      resetFlow: "productHelp/resetFlow",
    }),
  },
  created() {
    console.log("start");
    const nextIssueId = this.currentIssueId + 1;
    this.createIssue({ id: nextIssueId });
    // sets the current step as the first in the tree
    // reset the branch and path
    if (nextIssueId > 1) {
      this.resetFlow();
    }

    // depending on whether the user was doing
    // a product an order or first time set the next step automatically
    if (this.currentIssueId === 1) {
      // first time go to product-order
      this.setFollowingStep();
      this.goToNextStep();
    } else if (this.order && this.order.orderNumber) {
      // order loop go to find-order
      this.goToSpecificStep(["product-order", "which-order"]);
    } else {
      // product loop go to product finder
      this.goToSpecificStep(["product-order", "which-product"]);
    }
    //this.setFollowingStep();
  }
};
</script>

<style scoped>

</style>

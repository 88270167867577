<template>
  <div>
    <p>Please upload one picture of the area of concern and one picture of the Lodge logo (this is usually on the bottom of our cookware).</p>

    <div v-show="!hide" ref="dropArea" class="image-uploader-drop-area">
      <div class="my-form" data-vue-updateable="true">
        <p>
          Upload up-to {{ maxImages }} image files (.jpg or .png) with the file
          dialog or by dragging and dropping images onto the dashed region.
        </p>
        <p>
          <strong>Please attach one photograph of the damaged area and one photograph that clearly shows the Lodge
            logo.</strong>
        </p>
        <p>Please select your image(s) and then click the "Upload Files" button.</p>
        <input
            type="file"
            class="image-uploader-drop-area--file-elem"
            id="fileElem"
            multiple
            accept="image/*"
            @change="handleFiles($event)"
        />
        <label
            tabindex="0"
            ref="select-images"
            class="btn btn--arrow"
            for="fileElem"
            v-if="fileListPreview.length < 2"
            @keyup.enter.prevent="openFileDialog"
        >Select image(s)</label>
      </div>
      <transition name="fade">
        <progress
            v-show="isUploading"
            ref="progressBar"
            class="progress-bar"
            max="100"
            value="0"
        ></progress>
      </transition>
      <div
          class="frow direction-row items-center justify-start gutters mt-10"
          data-vue-updateable="true"
      >
        <div
            class="col-xs-1-2"
            v-for="(f, index) in fileListPreview"
            :key="index"
        >
          <div class="image-uploader-drop-area--image-container">
            <div aria-label="Close" role="button" class="btn--close" tabindex="0" @click="removeFile(index)">
              <div class="btn--close-slices">
                <span role="presentation"></span>
                <span role="presentation"></span>
              </div>
            </div>
            <div class="status">{{ f.id ? "Uploaded" : "Ready to upload" }}</div>
            <img :src="f.image" alt="Preview of uploaded file"/>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-5 col-md-4 col-lg-3">
      <button :disabled="!fileListPreview.length || isUploading || fileListPreview.every(f => f.id)" class="btn btn-primary" @click.enter="submitFiles">Upload Files</button>
    </div>
    <div class="frow mt-4 direction-column">
      <p>Comments (add any details you want customer care to know about):</p>
      <textarea :value="notes" @input="updateNotes" name="notes" id="notes" cols="30" rows="5"></textarea>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueRecaptcha from "vue-recaptcha";
import { mapMutations, mapGetters } from "vuex";
import mixins from './mixins';
import { domain as sfccDomain } from '../../../../lodge-vue/src/ocapi';

export default {
  name: "PhotoUploader",
  mixins: [mixins],
  components: { VueRecaptcha },
  data() {
    return {
      uploadProgress: [],
      fileListPreview: [],
      isUploading: false,
      maxImages: 2,
      form: null,
    };
  },
  computed: {
    ...mapGetters({
      uploadsComplete: "productHelp/getUploadsComplete",
      issue: "productHelp/getIssue",
      notes: "productHelp/getNotes",
      csrfToken: "productHelp/getCSRFToken",
    }),
    hide() {
      return this.maxImages == 0;
    },
  },
  methods: {
    ...mapMutations({
      setUploadsComplete: "productHelp/setUploadsComplete",
      addIssuePhotos: "productHelp/addIssuePhotos",
      removeIssuePhotos: "productHelp/removeIssuePhotos",
      setIssuePhotos: "productHelp/setIssuePhotos",
      setFollowingStep: "productHelp/setFollowingStep",
      unsetNextStep: "productHelp/unsetNextStep",
      setNotes: "productHelp/setNotes",
    }),
    openFileDialog() {
      this.$refs["select-images"].click();
    },
    updateNotes(e) {
      this.setNotes({ text: e.target.value })
    },
    preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    highlight(e) {
      this.$refs.dropArea.classList.add("highlight");
    },
    unhighlight(e) {
      this.$refs.dropArea.classList.remove("active");
    },
    removeFile(index) {
      const removedFile = this.fileListPreview.splice(index, 1);
      this.removeIssuePhotos({
        photos: removedFile.map(f => f.id)
      });
    },
    handleDrop(e) {
      var dt = e.dataTransfer;
      var files = dt.files;
      this.handleFiles(files);
    },
    handleFiles(files) {
      const fileList = ("currentTarget" in files
              ? [...files.currentTarget.files]
              : [...files]
      )
          .filter(this.validFile)
          .slice(0, this.maxImages);
      fileList.forEach(this.previewFile);
    },
    uploadFiles() {
      if (!this.fileListPreview.length) {
        this.setUploadsComplete(true);
        return Promise.resolve([]);
      }
      this.isUploading = true;
      this.initializeProgress();
      return Promise.all(
          // only upload files that don't have a SF id yet
          this.fileListPreview.filter(f => !f.id).map((f, i) => this.uploadFile(f, i)),
      )
          .then((responses) => responses)
          .catch((err) => {
            alert(err);
            return Promise.reject();
          });
    },
    initializeProgress() {
      this.$refs.progressBar.value = 0;
      this.uploadProgress = [];
      for (let i = this.fileListPreview.filter(f => !f.id).length; i > 0; i--) {
        this.uploadProgress.push(0);
      }
    },
    updateProgress(fileNumber, percent) {
      console.log("update progress", fileNumber, percent);
      this.uploadProgress[fileNumber] = percent;
      let total =
          this.uploadProgress.reduce((tot, curr) => tot + curr, 0) /
          this.uploadProgress.length;
      this.$refs.progressBar.value = total;
    },
    validFile(file) {
      return /png|jpg|jpeg|jpg/gi.test(file.type);
    },
    previewFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.fileListPreview.push({
          type: file.type,
          image: reader.result,
          name: file.name,
        });
      };
    },
    uploadFile(file, i) {
      return new Promise((resolve, reject) => {
        var url = `${sfccDomain}/on/demandware.store/Sites-lodge-Site/default/API-UploadImageToSalesforce?csrf_token=${this.csrfToken}`;
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open("POST", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        // Update progress (can be used to show progress indicator)
        xhr.upload.addEventListener("progress", (e) => {
          this.updateProgress(i, (e.loaded * 100.0) / e.total || 100);
        });

        xhr.addEventListener("readystatechange", (e) => {
          if (xhr.readyState == 4 && xhr.status == 200) {
            this.updateProgress(i, 100); // <- Add this
            try {
              var response = JSON.parse(e.currentTarget.response);
              if (response.success) {
                // store the id in the file preview object to use for removal later
                file.id = response.id;
                resolve(response.id);
              }
              else {
                reject("File upload error.");
              }
            } catch (err) {
            }
          } else if (xhr.readyState == 4 && xhr.status != 200) {
            reject("File upload error.");
          }
        });
        xhr.send(
            JSON.stringify({
              image: file.image,
              type: file.type,
              name: file.name || "unknown-file-name",
            }),
        );
      });
    },
    submitFiles(e) {
      if (this.fileListPreview.length) {
        this.preventDefaults(e);
        if (!this.isUploading) {
          this.uploadFiles().then((data) => {
            data.forEach((id, index) => {
              console.log("uploaded photo", id);
              // store the image ID in the issue
              this.addIssuePhotos({
                photos: id
              });
            });

            this.isUploading = false;
            this.setUploadsComplete(true);
          });
        }
      } else {
        this.setUploadsComplete(true);
      }
    },
  },
  watch: {
    fileListPreview: {
      handler (val) {
        if (val.every(f => f.id)) {
          this.setFollowingStep();
        } else {
          this.unsetNextStep();
        }
      },
    },
    isUploading(val, oldVal) {
      if (val != oldVal) {
        if (val) {
          this.unsetNextStep();
        } else {
          this.setFollowingStep();
        }
      }
    }
  },
  mounted() {

    // TODO set whether images are required and how many and what kind

    this.setUploadsComplete(false);
    const dropArea = this.$refs.dropArea;
    // Prevent default drag behaviors
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, this.preventDefaults, false);
      window.document.body.addEventListener(
          eventName,
          this.preventDefaults,
          false,
      );
    });

    // Highlight drop area when item is dragged over it
    ["dragenter", "dragover"].forEach((eventName) => {
      dropArea.addEventListener(eventName, this.highlight, false);
    });
    ["dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, this.unhighlight, false);
    });

    dropArea.addEventListener("drop", this.handleDrop, false);

    this.setFollowingStep();

    this.$refs["select-images"].focus();
  },
};
</script>

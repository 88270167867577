export default {
	setActivePage(state, data) {
		state.activePage = data.value;
	},
	setQuestionWithAnswers(state, data) {
		state.questionWithAnswers = data.value;
	},
	setCollectedAnswers(state, data) {
		state.collectedAnswers.splice((state.activePage - 1), 1, data.value);
		// state.collectedAnswers.push(data.value);
	},
	setAnswerGroup(state, data) {
		state.answerGroup = data.value;
	},
	restart(state) {
		state.activePage = 1;
		state.questionWithAnswers = {};
		state.collectedAnswers = [];
		state.answerGroup = {};
		state.backButton = 1;
	},
	setBackButton(state, data) {
		state.backButton = data.value;
	},
};

import Vue from "vue";

export default {
	setFetchingData(state, { fetchingData }) {
		state.fetchingData = fetchingData;
	},
	setProducts(state, { products }) {
		state.products = products;
	},
	setLoadingState(state, { loadingState }) {
		state.loadingState = loadingState;
	},
};

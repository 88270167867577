<template>
  <node-wrap :node="node">
    <product-chooser :node="node" />
  </node-wrap>
</template>

<script>
import mixins from './mixins';
import NodeWrap from './NodeWrap';
import ProductChooser from './ProductChooser';

export default {
  name: "ProductFinder",
  components: {
    ProductChooser,
    NodeWrap,
  },
  mixins: [mixins],
  props: [
    "node",
  ],
  data() {
    return {

    };
  },
};
</script>

<style scoped>

</style>

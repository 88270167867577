//http://lodgemfgode3.prod.acquia-sites.com/api/lodge/salesforce/products

import { queryParams } from "../../../utils.js";
import { validProductProperties } from "../../../utils.js";
function validFilterKey(key) {
	return validProductProperties.includes(key);
}

/**
 * @type {RecipeListingState}
 */
let qp = queryParams();
const State = {
	loadingState: "idle",
	recipes: [],
	filterText: (qp && qp["text"]) || "",
	pageMax: 18,
	querySeparator: "|",
	page: (qp && qp["page"] && parseInt(qp["page"], 10)) || 1,
	navigatedRecipe: (qp && qp["r"]) || "",
	recipeTotal: 0
};

export default State;

//http://lodgemfgode3.prod.acquia-sites.com/api/lodge/salesforce/products

/**
 * @type {import("vuex").MutationTree<ProductListingState>}
 */
const Mutations = {
	/**
	 * @param {Product[]} payload
	 */
	products(state, payload) {
		state.products = payload;
	},
	/**
	 * @param {Color[]} payload
	 */
	colors(state, payload) {
		state.colors = payload;
	},
	/**
	 * @param {LoadingState} payload
	 */
	loadingState(state, payload) {
		state.loadingState = payload;
	},

	/**
	 *
	 * @param {String[]} payload
	 */
	// selectedFilters(state, payload) {
	// 	state.selectedFilters = payload;
	// },
	clearFilterItems(state) {
		state.selectedFilters = [];
	},
	selectedFilters(state, payload) {
		state.selectedFilters = payload;
	},
	addFilterItem(state, payload) {
		state.selectedFilters = [...state.selectedFilters, payload];
	},
	removeFilterItem(state, payload) {
		const indx = state.selectedFilters.indexOf(payload);
		// state.selectedFilters = [...state.selectedFilters].splice(indx, 1);
		state.selectedFilters = state.selectedFilters.filter(f => f !== payload);
		// state.selectedFilters.splice(indx, 1);
	},
	incrementPage(state) {
		state.page = state.page + 1;
	},
	setPage(state, payload) {
		state.page = payload;
	},
	resetPage(state) {
		state.page = 1;
	},
	setNavigatedProduct(state, payload ) {
		state.navigatedProduct = payload;
	}
};

export default Mutations;
export const Commit = {
	/**
	 *
	 * @param {import("vuex").Commit} commit
	 * @param {Product[]} payload
	 */
	products(commit, payload) {
		commit("products", payload);
	},

	/**
	 *
	 * @param {import("vuex").Commit} commit
	 * @param {Color[]} payload
	 */
	colors(commit, payload) {
		commit("colors", payload);
	},

	/**
	 *
	 * @param {import("vuex").Commit} commit
	 * @param {LoadingState} payload
	 */
	loadingState(commit, payload) {
		commit("loadingState", payload);
	},

	/**
	 *
	 * @param {import("vuex").Commit} commit
	 * @param {String[]} payload
	 */
	selectedFilters(commit, payload) {
		commit("selectedFilters", payload);
	},
	/**
	 * @param {import("vuex").Commit} commit
	 */
	incrementPage(commit) {
		commit("incrementPage");
	}
};

import axios from "axios";
import { domain as sfccDomain } from "../../../../lodge-vue/src/ocapi";
import { lodgeSessionStorage } from "../../../classes/LodgeStorage";

export default {
	loadAnswerData({ commit }) {
		// commit("setAnswersLoadingS	tatus", { status: "ANSWERS_LOADING" });
		const localAnswers = false; //lodgeSessionStorage.get("wizard-answers");
		if (localAnswers) {
			commit("setAnswersLoadingStatus", {
				status: "ANSWERS_LOADED_FROM_CACHE",
			});
			commit("setAnswers", { answers: localAnswers });
		} else {
			return axios
				.get(
					`${sfccDomain}/on/demandware.store/Sites-lodge-Site/default/API-ProductWizard?categoryId=product-wizard `,
				)
				.then((r) => r.data)
				.then((data) => {
					console.log("Wizard Data", data);
					const wizardData = {
						answers: data.results.answers,
						products: data.results.products,
					};
					// lodgeSessionStorage.set("wizard-answers", wizardData);
					commit("setAnswers", wizardData);
					commit("setAnswersLoadingStatus", {
						status: "ANSWERS_LOADED_FROM_SF",
					});
				})
				.catch((err) => {
					console.error(err);
					commit("setAnswersLoadingStatus", { status: "ANSWERS_FAILED" });
				});
		}
	},
	loadPictureData({ commit }) {
		// commit("setPictures", <ADD PICTURES IF NEEDED>);
		return axios
			.get(`/themes/custom/tombras/images/product-wizard/pictures.json`)
			.then((r) => r.data)
			.then((data) => {
				commit("setPictures", data);
			})
			.catch(e => {
				console.log("Error getting wizard pictures", e);
			});
	},
};

import regeneratorRuntime from "regenerator-runtime";

import { getUrlParameter, queryParams } from "../utils";
import axios from "axios";

import {
	domain as sfccDomain,
	controllerPath,
} from "../../lodge-vue/src/ocapi";

export const createVariantKey = attributes => {
	const keys = [...Object.keys(attributes)].sort();
	let vue_key = "";
	keys.map(key => {
		if (attributes[key]) {
			vue_key += `${key}:${attributes[key]};`;
		}
	});
	return vue_key;
};

export const convertBetweenAttributes = (obj, addAttribute = true) => {
	const keys = Object.keys(obj);
	const swappedKeys = keys.map(key =>
		addAttribute
			? `attribute_${key}`
			: key
					.split("attribute_")
					.filter(i => i)
					.reduce((acc, curr) => curr, key),
	);

	const retObj = {};
	keys.forEach((k, idx) => {
		retObj[swappedKeys[idx]] = obj[k];
	});
	return retObj;
};

export const createQueryParamsForProduct = sku => {
	const qp = queryParams();
	const params = Object.keys(qp)
		.filter(k => k !== "sku" && k !== '')
		.reduce((acc, k) => acc + `&${k}${qp[k] ? `=${qp[k]}` : ""}`, "");
	return `?sku=${sku}${params}`;
};

export const getAttributesFromQueryParam = () => {
	const sku = getUrlParameter("sku");
	return sku.length ? sku : null;
};

export const getProductDetails = async productId => {
	const data = await axios
		.get(
			`${sfccDomain}${controllerPath}API-ProductDetails?productId=${productId}`,
			{
				withCredentials: true,
			},
		)
		.then(res => res.data)
		.then(data => data.product)
		.catch(err => {
			console.log(err);
			return {};
		});
	return data;
};

export const getColorData = async () => {
	const data = await axios
		.get(`${sfccDomain}${controllerPath}API-ColorData`, {
			withCredentials: true,
		})
		.then(res => res.data)
		.then(data => data.colors)
		.catch(err => {
			console.log(err);
			return {};
		});
	return data;
};

export const getProductData = async skus => {
	console.log("skus", skus);
	const data = await axios
		.get(`${sfccDomain}${controllerPath}API-ProductCardInfo?skus=${skus}`, {
			withCredentials: true,
		})
		.then(res => res.data)
		.then(data => data.products)
		.catch(err => {
			console.log(err);
			return {};
		});
	return data;
};

export const getCategoryProducts = async categoryId => {
	const data = await axios
		.get(
			`${sfccDomain}${controllerPath}API-CategoryProducts?categoryId=${categoryId}`,
			{
				withCredentials: true,
			},
		)
		.then(res => res.data)
		.then(data => ({
			categoryInfo: data.categoryInfo,
			products: data.products,
		}))
		.catch(err => {
			console.log(err);
			return {};
		});
	return data;
};

export const getProductsSearchResults = async searchText => {
	const data = await axios
		.get(
			`${sfccDomain}${controllerPath}API-ProductsSearch?searchText=${searchText}`,
			{
				withCredentials: true,
			},
		)
		.then(res => res.data)
		.then(data => data.products)
		.catch(err => {
			console.log(err);
			return {};
		});
	return data;
};

export const addSkuToNotificationList = async (email, sku) => {
	const data = await axios
		.get(
			`${sfccDomain}${controllerPath}API-AddToNotificationListByEmail?email=${email}&sku=${sku}`,
			{
				withCredentials: true,
			},
		)
		.then(res => res.data)
		.then(data => ({
			skus: data.skus,
			email: data.email,
			error: data.error,
		}))
		.catch(err => {
			console.log(err);
			return {
				error: err,
			};
		});
	return data;
};

export const SIDEBAR_STEPS = {
	START: 0,
	LIST: 1,
	STORE: 2,
};

export const PRODUCT_OFFSET = (function() {
	if (
		window.drupalSettings &&
		window.drupalSettings.lodge &&
		window.drupalSettings.lodge.productCountOffset
	) {
		return window.drupalSettings.lodge.productCountOffset;
	}
	return 21;
})();

import BaseLodgeIntent from "../BaseLodgeIntent";
import { CLASSIFIER_TYPES } from "../classifierUtils";

class PowerBuyer extends BaseLodgeIntent {
	constructor(options) {
		super(options);

		this.AVERAGE_UNITS_PER_PURCHASE = 2.5;
		this.SEEN_N_PAGES = 3;
		this.SHOP_PAGE_URL = "shop";

		this.boughtOverAverage = false;
		this.seenByNPages = false;
	}

	classify(data) {
		// no reason to keep reclassifying
		if (this.seenByNPages && this.boughtOverAverage) return;

		// handle updating this if we saw a product
		if (!this.seenByNPages) {
			console.log("hasnt been seen by n pages");
			let hasSeenEarly = false;
			const loopLength = Math.min(data.pages.length, this.SEEN_N_PAGES);
			for (let i = 0; i < loopLength; i++) {
				const p = data.pages[i].path.split("/")[1];
				console.log(
					p,
					data.viewedProducts,
					data.viewedProducts.indexOf(p) >= 0,
					p === this.SHOP_PAGE_URL,
				);
				if (data.viewedProducts.indexOf(p) >= 0 || p === this.SHOP_PAGE_URL) {
					console.log("product has been viewed early");
					hasSeenEarly = true;
					break;
				}
			}
			this.seenByNPages = hasSeenEarly;
		}

		// check to see if they bought over average
		if (!this.boughtOverAverage) {
			this.boughtOverAverage = data.purchases.reduce((acc, curr) => {
				console.log(34, curr.units, this.AVERAGE_UNITS_PER_PURCHASE);
				return acc || curr.units > this.AVERAGE_UNITS_PER_PURCHASE;
			}, false);
		}
	}

	isMatch() {
		return this.boughtOverAverage && this.seenByNPages;
	}
}

export default new PowerBuyer({
	prettyName: "PowerBuyer",
	intentName: "USER_INTENT_2",
	subscribesTo: [
		CLASSIFIER_TYPES.PAGE_VIEW,
		CLASSIFIER_TYPES.ECOM_PURCHASE,
		CLASSIFIER_TYPES.PRODUCT_VIEW,
	],
	negatedBy: [],
});

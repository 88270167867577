<template>
  <div class="concern">
    <div class="frow justify-start">
      <p
        v-if="node.subtitle"
        class="subtitle--line brown"
      >
        {{ node.subtitle }}
      </p>
      <h1 class="h2">{{ node.label }}</h1>
    </div>
    <div class="frow justify-start">
      <div class="frow col-xs-1-1 col-sm-1-2">
        <div class="frow gutters justify-start concern-items">
          <div
            class="col-xs-1-1 col-sm-1-2"
            :class="{'mt-3': index !== 0 && index !== 1, 'mt-3-mobile': index === 1}"
            v-for="(concern, index) in concerns"
            :key="concern.id"
          >
            <div
              class="option-card"
              tabindex="0"
              :ref="`concern-${index+1}`"
              :class="{active: selectedConcern === concern}"
              @click="setConcern(concern, index)"
              @keyup.enter.prevent="setConcern(concern, index)"
            >
              <div class="option-card--wrapper">
                <img
                  :src="concern.image ? `${drupalRoot}${concern.image}` : `${drupalRoot}/themes/custom/tombras/images/product-help/generic.jpg`"
                  :alt="`Visual representation of ${concern.label}`"
                />
                <p class="h7">{{ concern.label }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="frow col-xs-1-1 col-sm-1-2 justify-center items-start">
        <div v-if="selectedConcern" class="bg-white col-xs-1-1 col-sm-10-12 rounded concern-explanation">
          <div
            role="presentation"
            class="concern-explanation--image"
            :style="{backgroundImage: `url(${ selectedConcern.image ? `${drupalRoot}${selectedConcern.image}` : `${drupalRoot}/themes/custom/tombras/images/product-help/generic.jpg`})`}"
          ></div>
          <div
            v-if="selectedConcern.response"
            v-html="response"
            class="p-4"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataModel from "./productHelpDataModel";
import mixins from "./mixins";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "SelectConcern",
  mixins: [mixins],
  data() {
    return {
      drupalRoot: window.drupalRoot || "",
      dataModel,
      selectedConcern: null,
    };
  },
  props: ["step", "node"],
  computed: {
    ...mapGetters({
      getAnswer: "productHelp/getAnswer",
      answers: "productHelp/getAnswers",
      material: "productHelp/getMaterial",
    }),
    response() {
      if (
        this.selectedConcern.response &&
        !this.selectedConcern.response.includes("No Response: Assign to CC Rep")
      ) {
        return this.selectedConcern.response;
      } else {
        return (
          this.selectedConcern.response +
          " - <b><i>Need better copy here</i></b>"
        );
      }
    },
    concerns() {
      let used = this.answers.used.value;
      let material = this.answers.material;

      // show default versions of these issues
      let purchasedFrom = "website";
      let partsMissing = false;
      return dataModel.concerns.filter((concern) => {
        // filter out duplicate missing parts concerns
        if (
          !partsMissing &&
          used === "no" &&
          concern.meta &&
          concern.meta.reason &&
          concern.meta.reason.includes("parts-missing")
        ) {
          partsMissing = true;
          return true;
        } else if (
          concern.meta &&
          concern.meta.reason &&
          concern.meta.reason.includes("parts-missing")
        ) {
          return false;
        } else {
          let filters = concern.meta;
          return (
            concern.meta.used.includes(used) &&
            (concern.meta.material
              ? concern.meta.material.includes("Any") ||
                concern.meta.material.includes(material)
              : true) &&
            concern.meta["purchased-from"].includes(purchasedFrom)
          );
        }
      });
    },
  },
  watch: {
    selectedConcern(answer) {
      this.setAnswer({
        id: this.step,
        answer,
      });

      if (answer.meta && answer.meta.damaged) {
        this.setAnswer({
          id: "damaged",
          answer: {
            text: answer.meta.damaged,
            value: answer.meta.damaged,
          },
        });
      }
      if (answer.meta && answer.meta["still-need-help"]) {
        this.setAnswer({
          id: "still-need-help",
          answer: {
            text: answer.meta["still-need-help"],
            value: answer.meta["still-need-help"],
          },
        });
      }
      if (answer.meta && answer.meta.reason) {
        this.setAnswer({
          id: "reason",
          answer: {
            text: answer.meta.reason[0],
            value: answer.meta.reason[0],
          },
        });
      } else {
        this.setAnswer({
          id: "reason",
          answer: {
            text: "Other",
            value: "other",
          },
        });
      }

      if (answer.meta && answer.meta["purchased-from"]) {
        this.setNextStep({
          step: "need-purchase-location",
        });
      } else {
        this.setFollowingStep();
      }
    },
  },
  methods: {
    ...mapMutations({
      setAnswer: "productHelp/setAnswer",
      setNextStep: "productHelp/setNextStep",
      setFollowingStep: "productHelp/setFollowingStep",
    }),
    setConcern(concern) {
      this.selectedConcern = concern;
    },
  },
  mounted() {
    this.$refs["concern-1"][0].focus();
  },
};
</script>

<style scoped>
</style>

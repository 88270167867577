import axios from "axios";
import { Commit } from "./mutations";

/**
 * @return {import("axios").AxiosPromise<RecipeListingAPIResponse>}
 */
function getRecipes(filterText, page, pageMax) {
	const filters = `${filterText ? `&keyword_search=${filterText}`: ''}`;
	return axios.get(`/lodge-content-api/recipes?page_max=${pageMax}&page_offset=${page - 1}${filters}`)
		.then(r => ({
			status: r.status,
			data: r.data.data.nodes,
			total: parseInt(r.data.data.total, 10)
		}));
}

/**
 * @type {import("vuex").ActionTree<RecipeListingState>}
 */
const Actions = {
	getRecipes({ commit, state }, { addToList = false } = {}) {
		Commit.loadingState(commit, "loading");
		let page = state.page;
		let max = state.pageMax;
		// if there are no recipes yet fill up the results
		// with state.page's worth of recipes in one request
		if (state.recipes.length === 0) {
			page = 1;
			max = state.pageMax * state.page;
		}
		getRecipes(state.filterText, page, max).then(r => {
			if (r.status == 200) {
				console.log("get recipes", r);
				if (addToList) {
					Commit.recipes(commit, {
						recipes: state.recipes.concat(r.data),
						total: r.total
					});
				} else {
					Commit.recipes(commit, {
						recipes: r.data,
						total: r.total
					});
				}
				Commit.loadingState(commit, "loaded");
			} else {
				Commit.loadingState(commit, "error");
			}
		});
	},
};

export default Actions;

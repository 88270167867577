import BaseLodgeIntent from "../BaseLodgeIntent";
import { CLASSIFIER_TYPES } from "../classifierUtils";

class InfluencedMogul extends BaseLodgeIntent {
	constructor(options) {
		super(options);

		this.broughtBySocial = false;
		this.madePurchase = false;
	}

	classify(data) {
		// no reason to keep reclassifying
		if (this.broughtBySocial && this.madePurchase) return;

		// handle updating this if we saw a product
		if (!this.broughtBySocial) {
			const introPage = data.pages[0];
			this.broughtBySocial =
				introPage.queryString.indexOf("facebook") >= 0 ||
				introPage.queryString.indexOf("instagram") >= 0 ||
				introPage.queryString.indexOf("twitter") >= 0;
		}

		if (!this.madePurchase) {
			this.madePurchase = data.purchases.length > 0;
		}
	}

	isMatch() {
		return this.madePurchase && this.broughtBySocial;
	}
}

export default new InfluencedMogul({
	prettyName: "InfluencedMogul",
	intentName: "USER_INTENT_3",
	subscribesTo: [CLASSIFIER_TYPES.PAGE_VIEW, CLASSIFIER_TYPES.ECOM_PURCHASE],
	negatedBy: [],
});

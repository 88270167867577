import { lodgeLocalStorage } from "../../../classes/LodgeStorage";
const oldState = lodgeLocalStorage.get("wizard") || {};
console.log("OLD STATE FOR WIZARD", oldState);
const defaultState = {
	sawResults: false,
	activePage: 0,
	skill: "",
	collection: "",
	flavors: [],
	flavorsB: [],
	flavorsC: [],
	flavorTier: 1,
	flavorKey: "",
	servings: "",
	leftovers: "",
	answersLoadingStatus: "ANSWERS_LOADING",
	answers: {},
	products: {},
	flavorPictures: {},
	flavorPicturesLoaded: false,
	backButtonHash: "#product-wizard/close",
};

export default Object.assign({}, defaultState, oldState);

<template>
  <span role="button" tabindex="0" title="Your Cart" class="menu-icon salesforce-cart icon-cart"
        @click="openMenu"
  >
    <span v-if="cartNumber || sfraCartNumber" class="salesforce-cart-qty">{{ sfraCartNumber || cartNumber }}</span>
    <span class="sr-only">Your Cart</span>
  </span>
</template>

<script>
import { qs, qsa } from '../../utils';
import { accountModule } from '../../../lodge-vue/src/store/account';
import { mapGetters } from 'vuex';

export default {
  name: "CartIcon",
  data() {
    return {
      sfraCartNumber: null,
    };
  },
  methods: {
    openMenu: (e) => {
      if (!!qs(".lodge-sfra")) {
        window.location.href = "/cart";
      } else {
        qs("#cart-app").classList.toggle("active");
        document.body.classList.add("prevent-scrolling");
        window.returnFocusTo = e.target;
        setTimeout(() => {
          qs("#cart-app")
              .querySelector('button[title="Closes cart modal"]')
              .focus();
        }, 100);
      }
    },
  },
  computed: {
    cartNumber() {
      return accountModule.basket
          && accountModule.basket.product_items
          && accountModule.basket.product_items.length
          && accountModule.basket.product_items.reduce((acc, p) => {
            return p.quantity + acc;
          }, 0);
    },
  },
  mounted() {
    if (!!qs(".lodge-sfra")) {
      const products = qs("#products");
      if (products) {
        products.addEventListener("change", (e) => {
          if (e.target.classList.contains("quantity")) {
            let newCartNumber = 0;
            qsa("#products select.quantity").forEach(el => {
              console.log(el, el.value);
              newCartNumber += parseInt(el.value, 10);
            });
            qsa("#products .line-item-quantity .line-item-quantity").forEach(el => {
              let num = parseInt(el.innerText);
              if (num) {
                newCartNumber += num;
              }
            });
            console.log("new cart number", newCartNumber);
            this.sfraCartNumber = newCartNumber;
          }
        });
      }
    }
  },
};

</script>

<style scoped>

</style>

import { createVariantKey } from "../../utils";
import { THEME_PATH } from "../../../utils";
export default {
	getProducts(state) {
		return state.products;
	},
	getColors(state) {
		return state.colors;
	},
	getMasterInfo(state) {
		return state.masterInfo;
	},
	getAssociatedProducts(state, getters) {
		return state.associatedProducts[getters.getCurrentVariant.sku];
	},

	getFreeProducts(state) {
		return state.freeProducts;
	},
	getCurrentVariant(state) {
		const key = createVariantKey(state.selected);
		return state.products
			.filter((item) => {
				return item.vue_key === key;
			})
			.reduce((acc, curr) => curr, {});
	},
	getQuantity(state) {
		return state.quantity;
	},
	getDefaultAttribute(state, getters) {
		console.log(state.type);
		if (state.type !== "variationName") return [];
		return state.products.map((v) => {
			return {
				label: v.title,
				name: v.sku,
			};
		});
	},
	getVariantMedia(state, getters) {
		return getters.getCurrentVariant.images;
	},
	getOptions(state) {
		return state.options;
	},
	getAvailableColorsBySize(state, getters) {
		if (!state.selected.attribute_color) {
			return true;
		}

		if (state.type === "color") {
			return true;
		}

		const availableColorsBySize = {};

		state.products.forEach((p) => {
			if (!availableColorsBySize[p.attributes.attribute_size]) {
				availableColorsBySize[p.attributes.attribute_size] = new Set();
			}

			availableColorsBySize[p.attributes.attribute_size].add(
				p.attributes.attribute_color,
			);
		});

		return availableColorsBySize;
		/*
			'3.5"': [ 'blue', 'red'],
			'4.5 qt': ['oyster']
		*/
	},
	getSelectedOptions(state) {
		return {
			selected_size: state.selected.attribute_size,
			selected_color: state.selected.attribute_color,
			selected_quantity: state.selected.attribute_quantity,
		};
	},
	getImages(state, getters) {
		const noImagesArray = [`${THEME_PATH}/images/no-product-image.png`];
		const images =
			getters.getCurrentVariant &&
			(getters.getCurrentVariant.parent_images ||
				getters.getCurrentVariant.images);
		if (!images) {
			return noImagesArray;
		}
		if (images.large.length) return images.large;
		else if (images.medium.length) return images.medium;
		return noImagesArray;
	},
	getVideos(state, getters) {
		const noVideosArray = [];
		const videos = getters.getCurrentVariant && getters.getCurrentVariant.videos;
		if (!videos) {
			return noVideosArray;
		}
		if (videos.length) return videos;
		return noVideosArray;
	},
};

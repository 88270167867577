<template>
	<div class="quiz--buttons">
		<div class="quiz--buttons-container" :class="{ column: isFlexedColumn }">
			<QuizButton
				@selected="onSelection"
				v-for="button in buttons"
				:key="`button-${button.id}`"
				:btn="button"
			/>
		</div>
		<div
			style="text-align: center;"
			v-if="multiselect && selections.length > 0"
		>
			<a
				@click.prevent="onMultiselectFinish"
				@keyup.enter="onMultiselectFinish"
				class="btn btn--link color--secondary"
				href="#"
				>Continue</a
			>
		</div>
	</div>
</template>

<script>
import QuizButton from "./QuizButton";
export default {
	components: {
		QuizButton,
	},
	props: {
		buttons: {
			type: Array,
			default: () => [],
		},
		multiselect: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selections: [],
		};
	},
	computed: {
		isFlexedColumn() {
			return this.buttons.length <= 3 ? "column" : "";
		},
	},
	methods: {
		onSelection(data) {
			if (!this.multiselect) {
				this.selections = [data.btn];
				const { selections } = this;
				this.$emit("nextWithData", {
					selections,
				});
			} else {
				const idx = this.selections.findIndex(d => d.id === data.btn.id);
				if (idx >= 0) {
					const filtered = this.selections.filter(d => d.id !== data.btn.id);
					this.selections = filtered;
				} else {
					this.selections.push(data.btn);
				}
			}
		},
		onMultiselectFinish() {
			const { selections } = this;
			this.$emit("nextWithData", {
				selections,
			});
		},
		buttonKey(button) {
			return `${button.label.toLowerCase().replace(" ", "_")}`;
		},
	},
};
</script>
import { invertHash } from "../../components/Wizard/utils";

const lookupResults = ({
	flavorKey,
	collection,
	servings,
	leftovers,
	answers,
}) => {
	console.log(
		`PWR LOOKUP INFO: ${flavorKey} ${collection}, ${servings}, ${leftovers}`,
	);
	// If on page 5 THEN:
	// 1. Get collection
	// console.log("PWR: Check flavor key");
	let _results = answers[flavorKey];
	// console.log("PWR: Post flavor key", _results);
	// 2. If collection is an array, immediately return
	// TODO: Strip out items that the user already owns
	// console.log("PWR: Check for array", _results);
	if (Array.isArray(_results)) return _results;

	// 3. Get serving sizes
	// console.log("PWR: Get servings", servings, _results);
	_results = _results[servings];

	// console.log("results post servings", _results);
	// 4A. If it's an array, do nothing here. If
	// it's an object, then add in the leftovers
	// console.log("PWR: Check leftovers", leftovers);
	if (!Array.isArray(_results)) {
		// console.log("here we are", _results[leftovers]);
		_results = _results[leftovers || "leftovers"];
	}

	// 5A. If a user owns a product, then replace it with the next size up or one of it's alternates
	// 5B. Check if next size up has leftovers and if not select immediately from array
	// TODO: Check against user owned products
	// console.log("PWR: Return results", _results);
	return _results;
};

const checkForSuitableReplacement = ({
	flavorKey,
	collection,
	servings,
	leftovers,
	answers,
	products,
	idx,
	givenProducts = null,
}) => {
	let replacementProds = [];
	replacementProds = lookupResults({
		collection,
		servings,
		leftovers,
		flavorKey,
		answers,
	});
	// console.log("prods", products, "replacement", replacementProds);
	const replacementProduct = products[replacementProds[idx]];
	// console.log(
	// 	"[WIZ] replacement prod",
	// 	replacementProduct,
	// 	replacementProds[idx],
	// );
	if (replacementProduct.available) {
		return [replacementProds, replacementProds[idx]];
	}
	return [replacementProds, null];
};

export default {
	getSkill(state) {
		return state.skill;
	},
	getCollection(state) {
		return state.collection;
	},
	getServings(state) {
		const { servings } = state;
		if (servings === "party") return servings;
		else return parseInt(state.servings, 10);
	},
	getServingsRaw(state) {
		return state.servings;
	},
	// TODO: decide if it'd be easier to do this as one computed w/ param
	// but also decided it's worth having it cached
	getFlavors(state) {
		return state.flavors;
	},
	getFlavorsB(state) {
		return state.flavorsB;
	},
	getFlavorsC(state) {
		return state.flavorsC;
	},
	getFlavorStatus(state) {
		return (id) => {
			return state.flavors.indexOf(id);
		};
	},
	getFlavorTier(state) {
		return state.flavorTier;
	},
	getFlavorPictures(state) {
		// Return an empty bit
		if (!state.collection || !state.flavorPictures[state.collection])
			return { 1: [], 2: [], 3: [] };
		return state.flavorPictures[state.collection];
	},
	getLeftovers(state) {
		return state.leftovers;
	},
	getActivePage(state) {
		return state.activePage;
	},
	getResults(state, getters) {
		// HOW TO GET RESULTS
		// If not on page 5, it doesn't matter and return []
		console.log("PWR: Check page");

		const { collection, servings, leftovers, answers } = state;
		const flavorKey = getters.getFlavorValidation;
		console.log(
			"PWR:",
			window.location.hash.indexOf("results") < 0,
			state.answersLoadingStatus.indexOf("LOADED") < 0,
			!collection,
			!servings,
			!flavorKey,
		);
		console.log("PWR: on 56");
		if (
			window.location.hash.indexOf("results") < 0 ||
			state.answersLoadingStatus.indexOf("LOADED") < 0 ||
			!collection ||
			!servings ||
			!flavorKey
		) {
			return {};
		}

		let _results = [];
		_results = lookupResults({
			collection,
			servings,
			leftovers,
			flavorKey,
			answers,
		});

		const SERVING_SIZES = ["1", "2", "3-4", "5-6", "party"];
		const CSI = SERVING_SIZES.indexOf(servings);
		const checks = {
			up: null,
			down: null,
		};

		_results = _results.map((sku, idx) => {
			const product = state.products[sku];
			console.log(state.products[sku]);
			if (!product.available) {
				console.log(`${product.name} WAS NOT AVAILABLE, HERE WE GO.`);
				// BUSINESS LOGIC: Check for upsell first
				if (CSI < SERVING_SIZES.length - 1) {
					console.log("look up for replacement");
					const [upProds, upSku] = checkForSuitableReplacement({
						collection,
						servings: SERVING_SIZES[CSI + 1],
						leftovers,
						flavorKey,
						answers,
						products: state.products,
						idx,
						givenProducts: checks.up,
					});
					console.log("[wiz] 180", upProds, upSku);
					if (upProds) checks.up = upProds;
					if (upSku) return upSku;
				}

				// BUSINESS LOGIC: Check down afterwards
				if (CSI > 0) {
					console.log("look down for replacement");
					const [downProds, downSku] = checkForSuitableReplacement({
						collection,
						servings: SERVING_SIZES[CSI - 1],
						leftovers,
						flavorKey,
						answers,
						products: state.products,
						idx,
						givenProducts: checks.down,
					});
					if (downProds) checks.down = downProds;
					if (downSku) return downSku;
				}
			}

			return sku;
		});

		// we have to see if we're going to be able to show results or not here
		const filterUnavailable = _results.filter((p) => {
			return state.products[p].available;
		});
		if (filterUnavailable.length) {
			// we have results to show
			_results = filterUnavailable;
		} else {
			console.log(
				"[WIZARD]: No results would have been available if they were all filtered",
			);
		}

		return {
			top: Object.assign({}, state.products[_results[0]], { sku: _results[0] }),
			alternates: _results
				.slice(1, 3)
				.map((sku) => Object.assign({}, state.products[sku], { sku })),
		};
	},
	getFinalSettings(state) {
		return {
			sawResults: true,
			skill: state.skill,
			servings: state.servings,
			flavorKey: state.flavorKey,
			flavors: state.flavors,
			flavorsB: state.flavorsB,
			flavorsC: state.flavorsC,
			flavorTier: state.flavorTier,
			leftovers: state.leftovers,
			collection: state.collection,
		};
	},
	getSawResults(state) {
		return state.sawResults;
	},
	getFlavorsByTier: (state, getters) => (collection, tier) => {
		if (!collection || !tier) return [];
		if (
			!state.flavorPictures ||
			!state.flavorPictures[collection] ||
			!state.flavorPictures[collection][tier]
		) {
			return [];
		}

		const pictures = state.flavorPictures[collection][tier];
		// this is simple, just show whatever is inside of there currently
		if (collection == "basic" || (collection != "basic" && tier !== 3)) {
			return pictures;
		}

		const flavorsByType = getters.getFlavorsByType(["flavorsB"]).byType;
		console.log(getters, flavorsByType);
		const invertedHash = invertHash(flavorsByType);
		const sortedKeys = Object.keys(invertedHash).sort();
		const highestValue = sortedKeys[sortedKeys.length - 1];
		const typesArray = invertedHash[highestValue];
		console.log(typesArray, invertedHash, invertedHash[highestValue]);
		const builtPicturesArray = [];

		for (let i = 0; i < pictures.length; i++) {
			const [type] = pictures[i].id.split("|");
			if (typesArray.indexOf(type) >= 0) {
				builtPicturesArray.push(pictures[i]);
			}
		}

		return builtPicturesArray;
	},
	getFlavorsByType: (state) => (tiers = []) => {
		let total = 0;
		let combinedTiers = [...state.flavors];
		console.log(state.flavors, state.flavorsB, state.flavorsC);
		// Do this so we only grab tiers as we actually need to look at them
		for (let i = 0; i < tiers.length; i++) {
			console.log(state[tiers[i]]);
			combinedTiers = combinedTiers.concat(state[tiers[i]]);
		}
		console.log(combinedTiers);
		const byType = combinedTiers.reduce((acc, curr) => {
			console.log(curr);
			const [type, typeId] = curr.split("|");
			total++;
			if (acc[type]) acc[type] = acc[type] + 1;
			else acc[type] = 1;
			return acc;
		}, {});
		console.log(`STATS:`, { byType, total });
		return {
			byType,
			total,
		};
	},
	getAPIDataStatus(state) {
		return {
			flavors: state.flavorPicturesLoaded,
			results: state.answersLoadingStatus.indexOf("LOADED") >= 0,
		};
	},
	getFlavorValidation(state, getters /*{ flavorsByType, collection, tier }*/) {
		console.log("flavor validation!");
		let returnedKey = null;
		const collection = state.collection;
		console.log("FV: 194");
		if (!collection) return returnedKey;
		const tier = state.flavorTier;
		console.log("FV: 197");
		const seenTiers = [];
		if (tier >= 2) seenTiers.push("flavorsB");
		if (tier === 3) seenTiers.push("flavorsC");
		const flavorsByType = getters.getFlavorsByType(seenTiers);

		if (collection === "basic") {
			console.log("FV: basic");
			const percentages = {
				1: 80,
				2: 60,
				3: 51,
			};

			const chosen = {
				1: 5,
				2: 3,
				3: 1,
			};

			const percentage = percentages[tier] / 100;
			const { byType } = flavorsByType;
			const total = chosen[tier];

			const _type = Object.keys(byType);
			for (let i = 0; i < _type.length; i++) {
				console.log("FV: 222");
				console.log(
					`FV: VALIDATION NUMBERS for ${_type[i]}`,
					byType[_type[i]],
					total,
					byType[_type[i]] / total,
					percentage,
				);
				console.log(byType[_type[i]] / total >= percentage);
				if (byType[_type[i]] / total >= percentage) {
					console.log(_type[i]);
					returnedKey = _type[i];
					break;
				}
			}
		} else {
			console.log("FV: 236");
			const invertedHash = invertHash(flavorsByType.byType);
			const sortedKeys = Object.keys(invertedHash).sort();
			const highestValue = sortedKeys[sortedKeys.length - 1];
			if (highestValue >= 4 && invertedHash[highestValue].length === 1)
				return invertedHash[highestValue][0];

			// ADDED TO FIX LDG-690: if flavor related picking breaks, look here.
			// If they've gotten here and the highest one is still just a single item,
			// then we'll pick it because otherwise they'll get a single picture
			console.log(365, tier, invertedHash[highestValue]);
			if (tier === 2) {
				const tierThreeFlavors = getters.getFlavorsByTier(collection, 3);
				if (tierThreeFlavors.length === 1) {
					return tierThreeFlavors[0].id.split("|")[0];
				}
			}

			if (tier == 3 && invertedHash[highestValue].length === 1)
				return invertedHash[highestValue][0];
		}

		return returnedKey;
	},
	getUniqueKey(state, getters) {
		return `${state.skill || "No Skill"}|${
			state.collection || "No Collection"
		}|${getters.getFlavorValidation || "No Flavor"}|${
			state.servings || "No Servings"
		}|${state.leftovers || "No Leftovers"}`;
	},
};

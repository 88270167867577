<template>
  <button class="copy-to-clipboard-wrapper" v-if="canCopyToClipboard">
    <span
        :title="title"
        href="#"
        :class="{'copy-to-clipboard': canCopyToClipboard, copied: clipboardCopied}"
        @click.prevent="copyToClipboard(clipboardText)"
    ></span>
  </button>
</template>

<script>
import regeneratorRuntime from "regenerator-runtime";

export default {
  name: "copyToClipboard",
  data() {
    return {
      clipboardCopied: false,
      clipboardTimeout: 3000,
      canCopyToClipboard: !!navigator.clipboard,
    }
  },
  props: [
      "clipboardText",
      "title"
  ],
  methods: {
    async copyToClipboard(text) {
      if (!this.canCopyToClipboard) {
        return false;
      }
      try {
        await navigator.clipboard.writeText(text);
        this.clipboardCopied = true;
        setTimeout(() => {
          this.clipboardCopied = false;
        }, this.clipboardTimeout);
        return true;
      } catch (err) {
        console.log("Failed to copy text to clipboard:", text);
        return false;
      }
    },
  }
};
</script>

<style scoped>

</style>

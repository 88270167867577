import { lodgeSessionStorage } from "../classes/LodgeStorage";
import { gtmCustomEventPush } from "../analytics";
import Cookies from "universal-cookie";

const CLASSIFICATION_VERSION = 0.1;
const SESSION_KEY = `user_classification_session`;
// const LOCAL_KEY = `user_classification_long_term`;

const cookies = new Cookies();
const cookieDomainMatch = /(\w+\.\w+)$/g.exec(window.location.hostname);
const cookieDomain =
	cookieDomainMatch && cookieDomainMatch[1]
		? `.${cookieDomainMatch[1]}`
		: ".lodgecastiron.com";

class UserClassifier {
	constructor({ state, reducer }) {
		this.intents = {};
		this.dataPoints = state;
		this.intentOutcomes = {};
		this.reducer = reducer;

		this.hydrateUserClassifier();
	}

	/**
	 * Handles loading current state and outcomes
	 */
	hydrateUserClassifier() {
		const dataPointKeys = Object.keys(this.dataPoints);
		dataPointKeys.forEach((key) => {
			let savedData = cookies.get(`${SESSION_KEY}_${key}`);
			if (savedData) {
				// if we have a string, then we're dealing with a boolean
				if (typeof savedData === "string")
					savedData = savedData.toLowerCase() === "true";
				this.dataPoints[key] = savedData;
			}
		});
		const savedIntentOutcomes = cookies.get(`${SESSION_KEY}_intent_outcomes`);
		if (savedIntentOutcomes) {
			this.intentOutcomes = savedIntentOutcomes;
		}

		// Create an ID that we can use for cookies
		// and stashes it immediately
		if (!this.dataPoints.id) {
			this.dataPoints.id = `uc_${new Date().getTime()}`;
		}

		this.sendDataToGTM();
	}

	addDataPoint(dataPoint) {
		const { type } = dataPoint;
		this.dataPoints = this.reducer(dataPoint, this.dataPoints);
		this.publishDataPoint(type);
		this.save();
	}

	publishDataPoint(dataType) {
		const start = performance.now();
		const { dataPoints } = this;
		(Object.keys(this.intents) || []).forEach((intentKey) => {
			if (this.intents[intentKey].subscribesTo.indexOf(dataType) >= 0) {
				const classifyStart = performance.now();
				try {
					this.intents[intentKey].classify(dataPoints, dataType);
				} catch (err) {
					console.error(`CLASSIFIER ERROR: `, err);
					gtmCustomEventPush({
						event: "UserClassifierFailure",
						category: intentKey,
						label: "Failed to classify",
					});
				}
			}
		});
		this.intentOutcomes = this.outcomes();
		this.sendDataToGTM();
		console.log(`TOTAL PERF WITH GTM: ${(performance.now() - start) / 1000}`);
	}

	/**
	 * Handles pushing data to GTM
	 * @return void
	 */
	outcomes() {
		const _outcomes = {};
		Object.keys(this.intents).forEach((key) => {
			const intent = this.intents[key];
			// TODO: figure out why .negatedBy wasn't already an array
			_outcomes[intent.intentName] = (intent.negatedBy || []).reduce(
				(acc, curr) => {
					return this.intents[curr]
						? acc && !this.intents[curr].isMatch()
						: acc;
				},
				intent.isMatch(),
			);
		});
		return _outcomes;
	}

	sendDataToGTM() {
		gtmCustomEventPush({
			event: "UserClassification",
			classifications: {
				intents: this.intentOutcomes,
				version: CLASSIFICATION_VERSION,
			},
		});
	}

	registerIntent(intent) {
		const guard = !!this.intents[intent.intentName];
		if (!guard.length) {
			this.intents[intent.intentName] = intent;

			// We don't want to overwrite if this came from session
			if (!!this.intentOutcomes[intent.intentName]) {
				this.intentOutcomes[intent.intentName] = false;
			}
		}
	}

	/**
	 * Handles registering more than one intent at a time
	 * @param {Array<Intent>} intents
	 */
	registerIntents(intents, options = {}) {
		intents.forEach((intent) => {
			this.registerIntent(intent);
		});

		if (options.debug) {
			intents.forEach((intent) => {
				window[`lodge_uc_${intent.prettyName.toLowerCase()}`] = intent;
			});
		}
	}

	/**
	 * Deletes all mentions of intent
	 * @param {} intentName
	 */
	deregisterIntent(intentName) {
		if (this.intents[intentName]) {
			delete this.intents[intentName];
			delete this.intentOutcomes[intentName];
		}
	}

	save() {
		const cookieOptions = {
			path: "/",
			domain: cookieDomain,
		};

		// Break bits of data points out into separate cookies
		// so we can save more data easily
		const dataPointKeys = Object.keys(this.dataPoints);
		dataPointKeys.forEach((key) => {
			cookies.set(`${SESSION_KEY}_${key}`, this.dataPoints[key], cookieOptions);
		});
		cookies.set(
			`${SESSION_KEY}_intent_outcomes`,
			this.intentOutcomes,
			cookieOptions,
		);
	}
}

export default UserClassifier;

<template>
  <node-wrap :node="node" :required="required">
    <div class="frow">
      <textarea ref="textinput" v-model="text" cols="50" rows="5"></textarea>
    </div>
  </node-wrap>
</template>

<script>
import { debounce } from '../../../utils';
import dataModel from "./productHelpDataModel";
import mixins from './mixins';
import { mapMutations, mapGetters } from 'vuex';
import NodeWrap from './NodeWrap';

export default {
  name: "TextInput",
  components: {
    NodeWrap
  },
  mixins: [mixins],
  data() {
    return {
      dataModel,
      text: "",
    };
  },
  props: [
    "step",
    "node",
    "answerOnly",
    "required",
  ],
  computed: {
    ...mapGetters({
    }),
  },
  watch: {
    node() {
      this.$refs["textinput"].value = "";
    },
    text: function (answer) {
      this.debouncedAnswer(answer);
    }
  },
  methods: {
    ...mapMutations({
      setAnswer: "productHelp/setAnswer",
      setFollowingStep: "productHelp/setFollowingStep",
    }),
    handleText: function (answer) {
      if (this.node.meta && this.node.meta.addToNotes) {
        let text = answer;
        answer = {
          text,
          addToNotes: this.node.meta.addToNotes
        }
      }
      this.setAnswer({
        id: this.step,
        answer,
      });

      if (!this.answerOnly) {
        this.setFollowingStep();
      }
    }
  },
  created() {
    this.debouncedAnswer = debounce(this.handleText, 250);
  }
};
</script>

<style scoped>

</style>

import BaseLodgeIntent from "../BaseLodgeIntent";
import { CLASSIFIER_TYPES } from "../classifierUtils";

class RecipeChef extends BaseLodgeIntent {
	constructor(options) {
		super(options);

		// Constants that could be pulld in by Drupal
		this.FIRST_N_PAGES = 3;
		this.SEEN_N_PAGES = 2;

		this.seenByNPages = false;
		this.visitedNRecipes = false;
	}

	classify(data) {
		// we cannot be unclassified as a recipe chef
		if (this.seenByNPages && this.visitedNRecipes) return;

		const regex = /\/recipe(s?|\/?)/;
		let counter = 0;
		// make the filter do double duty to keep count
		data.pages.forEach((p, idx) => {
			const regexTest = regex.test(p.path);
			if (!this.seenByNPages && idx <= this.FIRST_N_PAGES) {
				this.seenByNPages = this.seenByNPages || regexTest;
			}
			if (regexTest) counter++;
		});

		this.visitedNRecipes = counter >= 2;
	}

	isMatch() {
		return this.seenByNPages && this.visitedNRecipes;
	}
}

export default new RecipeChef({
	prettyName: "RecipeChef",
	intentName: "USER_INTENT_1",
	subscribesTo: [CLASSIFIER_TYPES.PAGE_VIEW],
	negatedBy: [],
});

<template>
	<section
		@keydown="checkForTabDown"
		class="product-wizard"
		:aria-hidden="!shown"
	>
		<div class="product-wizard--action-bar">
			<div>
				<a
					:href="backButtonHash"
					class="back-btn btn size-mini"
				>
					<BackArrow /> {{ backButtonText }}
				</a>
				<a
					v-show="shouldShowResults"
					href="#product-wizard/close"
					class="back-btn close-btn btn size-mini"
				>
					&times; Close</a>
			</div>
			<div>
				<a
					href="#product-wizard/skill/restart"
					class="btn size-mini white-on-desktop"
					v-show="currentPage != '#product-wizard/skill'"
				>
					<RestartIcon />
					Start Over
				</a>
			</div>
		</div>
		<transition
			name="wizard-fade"
			mode="out-in"
		>
			<component
				:pageNumber="`Page${activePage + 1}`"
				:is="currentPageComponent"
				:handleFocus="handleWizardFocus"
				:usesKeyboard="usesKeyboard"
				@usesKeyboard="handleAccessibility"
			/>
		</transition>
	</section>
</template>

<script>
import Vue from "vue";
import * as anis from "./Wizard/animations";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import RestartIcon from "./Wizard/UI/RestartIcon.vue";
import BackArrow from "./Wizard/UI/BackArrow.vue";
// TODO: figure out if we can reimplement this import ActionBar from "./Wizard/UI/ActionBar.vue";
import { gtmCustomEventPush, EVENTS } from "../../analytics";
import { lodgeLocalStorage } from "../../classes/LodgeStorage";
import routes from "./Wizard/routes";
export default {
	components: {
		RestartIcon,
		BackArrow,
	},
	data() {
		return {
			currentPage: "#product-wizard/skill",
			shown: false,
			animatedIn: false,
			usesKeyboard: false,
		};
	},
	computed: {
		...mapState("wizard", ["backButtonHash", "sawResults"]),
		...mapGetters({
			activePage: "wizard/getActivePage",
			servingSize: "wizard/getServings",
			flavorsByType: "wizard/getFlavorsByType",
			collection: "wizard/getCollection",
			flavorTier: "wizard/getFlavorTier",
		}),
		backButtonText() {
			return this.backButtonHash && this.backButtonHash.indexOf("close") >= 0
				? "Close"
				: "Back";
		},
		currentPageComponent() {
			return (
				routes[this.currentPage || "#product-wizard/skill"] ||
				routes["#product-wizard/skill"]
			);
		},
		shouldShowResults() {
			return this.currentPage === "#product-wizard/results";
		},
	},
	methods: {
		...mapMutations({
			setFlavorKey: "wizard/setFlavorKey",
			setFlavors: "wizard/setFlavors",
			setActivePage: "wizard/setActivePage",
			restartWizard: "wizard/restart",
			setBackButtonHash: "wizard/setBackButtonHash",
		}),
		...mapActions({
			getAnswers: "wizard/loadAnswerData",
			getPictures: "wizard/loadPictureData",
		}),
		setCurrentHash(hash) {
			this.currentPage = hash;
		},
		handleWizardFocus() {
			let focusEl = null;
			const selectedEl = this.$el.querySelector("input:checked");
			if (selectedEl) {
				focusEl = selectedEl;
			} else {
				focusEl = this.$el.querySelector(
					"input, [tabindex]:not([tabindex='-1']",
				);
			}

			console.log("focus el is...", focusEl);
			if (focusEl) focusEl.focus();
		},
		showWizard(status) {
			this.firstRun = true;
			const tl = anis.wizardIntroAnimation(this.$el);
			tl.call(() => {
				document.body.classList.add("product-wizard-showing");
				if (this.sawResults) {
					window.location.hash = "#product-wizard/results";
				}
				this.shown = true;
				this.animatedIn = true;
				this.handleWizardFocus();
				this.$el.scrollTop = 0;
			});
			tl.play();
		},
		handleAnalytics(analytics) {
			if (!analytics) return;

			if (analytics.persona) {
				gtmCustomEventPush({
					event: EVENTS.WIZARD_PERSONA,
					category: "Product Wizard",
					action: "Persona Selected",
					label: analytics.persona,
				});
			}
		},
		checkForTabDown(e) {
			const tabPressed = e.key === "Tab" || e.keyCode === 9;
			const active = document.activeElement;
			const focusableEls = this.$el.querySelectorAll(
				'button, [href], input, [tabindex]:not([tabindex="-1"]',
			);
			const lastIdx = focusableEls.length - 1;

			if (!tabPressed) return;
			if (active === focusableEls[lastIdx] && !e.shiftKey) {
				e.preventDefault();
				focusableEls[0].focus();
			} else if (e.shiftKey && active === focusableEls[0]) {
				e.preventDefault();
				focusableEls[lastIdx].focus();
			}
		},
		handleUpdatingHash({ hash }) {
			this.setCurrentHash(hash);
			this.$el.scrollTop = 0;
		},
		handleAccessibility({ usesKeyboard }) {
			// if we're here, then we can disable first run
			this.firstRun = false;
			this.usesKeyboard = usesKeyboard;
		},
		handleRestart() {
			this.restartWizard();
			this.setCurrentHash("#product-wizard/skill");
			this.focusAndScroll();
		},
		handleClosing() {
			this.shown = false;
			const tl = anis.wizardOutroAnimation(this.$el);
			tl.call(() => {
				document.body.classList.remove("product-wizard-showing");
			});
		},
		handlePagination({ hash, isRestart, wasResults }) {
			if (isRestart) {
				lodgeLocalStorage.set("wizard", {});
				if (wasResults) {
					const tl = anis.resultsOutroAnimation(this.$el);
					tl.call(() => this.handleRestart());
					tl.play();
				} else {
					this.handleRestart();
				}
			} else {
				if (wasResults) {
					const tl = anis.resultsOutroAnimation(this.$el);
					tl.call(() => {
						this.handleUpdatingHash({ hash });
					});
					tl.play();
				} else {
					this.handleUpdatingHash({ hash });
				}
			}
		},
	},
	created() {
		const isSfra = !!document.querySelector(".lodge-sfra");
		// if we are on SFRA CC, do nothing
		if (isSfra) return;
		// Immediately start getting answers and other data
		this.getAnswers();
		this.getPictures();
	},
	mounted() {
		console.log("[WIZARD]: mounted");
		this.setBackButtonHash({ hash: "#product-wizard/close" });

		// TODO: clean this bad boy up some
		window.addEventListener("hashchange", (e) => {
			console.log("hash has changed");
			const hash = window.location.hash;
			// if it's not a product-wizard hash, do nothing
			if (hash.indexOf("#product-wizard") < 0) return;

			const isRestart = hash.indexOf("restart") >= 0;
			const isClosing = hash.indexOf("close") >= 0;
			const wasResults = this.currentPage === "#product-wizard/results";

			// If it's not shown, do the animations to show it
			if (hash.indexOf("#product-wizard") < 0) return;
			// if it's not shown, do the animations
			if (!this.shown) this.showWizard();

			gtmCustomEventPush({
				event: EVENTS.WIZARD_PAGE_VIEW,
				category: "Wizard Page View",
				action: hash,
			});
			if (isClosing) {
				this.handleClosing();
			} else {
				this.handlePagination({ hash, isRestart, wasResults });
			}
		});

		if (window.location.hash.indexOf("#product-wizard") >= 0) {
			try {
				if (this.sawResults) {
					console.log("[WIZARD] you saw results!");
					window.location.hash = "#product-wizard/results";
					this.setCurrentHash("#product-wizard/results");
				} else {
					console.log("[WIZARD] you didnt see results!");
					window.location.hash = "#product-wizard/skill";
				}
			} catch (err) {
				console.log("pathing error!", err);
				window.location.hash = "#product-wizard/skill";
			}

			Vue.nextTick(() => this.showWizard());
		}
	},
};
</script>

import BaseLodgeIntent from "../BaseLodgeIntent";
import { CLASSIFIER_TYPES } from "../classifierUtils";

class CaringOwner extends BaseLodgeIntent {
	constructor(options) {
		super(options);

		// Constants that could be pulld in by Drupal
		this.SEEN_N_PAGES = 2;

		this.visitedNHelpArticles = false;
	}

	classify(data) {
		// Cannot unclassify the user
		if (this.visitedNHelpArticles) return;

		const regex = /\/cleaning-and-care/;
		let counter = 0;
		// make the filter do double duty to keep count
		data.pages.forEach((p, idx) => {
			if (regex.test(p.path)) counter++;
		});

		this.visitedNHelpArticles = counter >= 2;
	}

	isMatch() {
		return this.visitedNHelpArticles;
	}
}

export default new CaringOwner({
	prettyName: "CaringOwner",
	intentName: "USER_INTENT_5",
	subscribesTo: [CLASSIFIER_TYPES.PAGE_VIEW],
	negatedBy: [`USER_INTENT_2`, `USER_INTENT_3`, `USER_INTENT_4`],
});

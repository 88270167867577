import Vue from "vue";
import Vuex from "vuex";
import { Basket } from "../types";
import { accountState } from "@/store/account/types";

import likes from "./../../../js/vue/store/likes.js";
import productDetail from "./../../../js/vue/store/product-detail/index.js";
import productListing from "./../../../js/vue/store/product-listing/index.js";
import productCard from "./../../../js/vue/store/product-card/index.js";
import wizard from "./../../../js/vue/store/wizard/index.js";
import productWizard from "./../../../js/vue/store/product-wizard/index.js";
import productHelp from "./../../../js/vue/store/product-help/index.js";
import recipeListing from "./../../../js/vue/store/recipe-listing/index.js";

export interface State {
	accountModule: accountState;
	basket: Basket;
	jwt: string;
	foo: string;
}

Vue.use(Vuex);

const store = new Vuex.Store<State>({
	strict: process.env.NODE_ENV !== "production",
});

store.registerModule("likes", likes, { preserveState: false });
store.registerModule("productDetail", productDetail, { preserveState: false });
store.registerModule("productListing", productListing, {
	preserveState: false,
});
store.registerModule("productCard", productCard, {
	preserveState: false,
});
store.registerModule("wizard", wizard, {
	preserveState: false,
});
store.registerModule("productWizard", productWizard, {
	preserveState: false,
});
store.registerModule("productHelp", productHelp, {
	preserveState: false,
});
store.registerModule("recipeListing", recipeListing, {
	preserveState: false,
});
export default store;

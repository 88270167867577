<template>
	<div class="frow-container quiz--pagination" :class="{ hide: !show }">
		<div class="frow gutters">
			<div class="col-xs-1-2 chevron-holder">
				<span v-show="current > 0" @click="back"
					><div class="chevron"></div
				></span>
			</div>
			<div class="col-xs-1-2" style="text-align: right">
				<div>
					<span>{{ current + 1 }}</span
					>/<span>{{ total - 1 }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["current", "total", "back", "show"],
};
</script>
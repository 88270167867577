<template>
  <div>
    <div v-html="node.information"></div>
    <div class="concerns">
      <div v-for="concern in concerns">
        <h4>{{ concern.label }}</h4>
        <p v-html="concern.response"></p>
      </div>
    </div>
  </div>
</template>

<script>
import dataModel from "./productHelpDataModel";
import mixins from './mixins';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "Information",
  mixins: [mixins],
  data() {
    return {
      dataModel,
    }
  },
  props: [
      "step",
      "node"
  ],
  computed: {
    ...mapGetters({
      answers: "productHelp/getAnswers",
      material: "productHelp/getMaterial"
    }),
    concerns() {
      return dataModel.concerns.filter(concern => {
        // use meta to match against answers
        return concern.meta && concern.meta.every(m => {
          return m.answer.includes(this.answers[m.key]);
        })
      });
    },
  },
  methods: {
    ...mapMutations({
      setFollowingStep: "productHelp/setFollowingStep",
    }),
  },
  mounted() {
    this.setFollowingStep();
    console.log(this);
    this.$parent.$refs["next"].focus();
  }
};
</script>

<style scoped>

</style>

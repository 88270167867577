export function determineFlavorValidation({ flavorsByType, collection, tier }) {
	let returnedKey = null;

	if (collection === "basic") {
		const percentages = {
			1: 80,
			2: 60,
			3: 51,
		};

		const percentage = percentages[tier] / 100;
		const { byType, total } = flavorsByType;

		const _type = Object.keys(byType);
		for (let i = 0; i < _type.length; i++) {
			console.log(
				`VALIDATION NUMBERS for ${_type[i]}`,
				byType[_type[i]],
				total,
				byType[_type[i]] / total,
				percentage,
			);
			console.log(byType[_type[i]] / total >= percentage);
			if (byType[_type[i]] / total >= percentage) {
				returnedKey = _type[i];
				break;
			}
		}
	} else {
		const invertedHash = invertHash(flavorsByType.byType);
		const sortedKeys = Object.keys(invertedHash).sort();
		const highestValue = sortedKeys[sortedKeys.length - 1];
		if (highestValue >= 4 && invertedHash[highestValue].length === 1)
			return invertedHash[highestValue][0];
	}

	return returnedKey;
}

export const invertHash = (hash) => {
	return Object.keys(hash).reduce((acc, curr) => {
		const value = hash[curr];
		if (!acc[value]) acc[value] = [curr];
		else acc[value].push(curr);
		return acc;
	}, {});
};

export const keyboardAccessibility = (e, context) => {
	let usesKeyboard = false;
	if (e.detail === 0) {
		usesKeyboard = true;
	}
	context.$emit("usesKeyboard", { usesKeyboard });
};

import axios from "axios";
import { domain as sfccDomain, ocapiClientId } from "../../../../lodge-vue/src/ocapi";
import dataModel from '../../components/ProductHelp/productHelpDataModel';

const loadProducts = ({ commit }) => {
	commit("setProductsLoadingStatus", { status: "PRODUCTS_LOADING" });
	return axios
		.get(
			`${sfccDomain}/on/demandware.store/Sites-lodge-Site/default/API-ProductHelpProductsList`,
		)
		.then((r) => r.data)
		.then((data) => {
			console.log(data);
			const products = data.products;
			commit("setProducts", { products });
			commit("setProductsLoadingStatus", { status: "PRODUCTS_LOADED" });
		})
		.catch((err) => {
			console.error(err);
			commit("setProductsLoadingStatus", { status: "PRODUCTS_FAILED" });
		});
};

const getOrder = ({ commit }, { orderNumber, email, zip, csrfToken }) => {
	commit("setOrderLoadingStatus", { status: "ORDER_LOADING" });
	return axios
		.get(
			`${sfccDomain}/on/demandware.store/Sites-lodge-Site/default/API-OrderDetails?orderEmail=${email}&orderPostal=${zip}&orderNumber=${orderNumber}&csrf_token=${csrfToken}`,
		)
		.then((r) => r.data)
		.then((data) => {
			console.log(data);
			const order = data.order;
			commit("setOrder", { order });
			commit("setOrderLoadingStatus", { status: "ORDER_LOADED" });
			return data;
		})
		.catch((err) => {
			console.error(err);
			commit("setOrderLoadingStatus", { status: "ORDER_FAILED" });
			return error;
		});
};

const submitIssues = ({ commit }, { request, csrfToken }) => {
	commit("setIssuesResponseLoadingStatus", { status: "RESPONSE_LOADING" });
	return axios
		.post(
			`${sfccDomain}/on/demandware.store/Sites-lodge-Site/default/API-ProductHelpCaseSubmit?csrf_token=${csrfToken}`,
			request
		)
		.then((r) => r.data)
		.then((data) => {
			console.log(data);
			commit("setIssuesResponse", {
				response: data.data.requests
			});
			commit("setIssuesResponseLoadingStatus", { status: "RESPONSE_LOADED" });
		})
		.catch((err) => {
			console.error(err);
			commit("setIssuesResponse", {
				response: [{
					status: "error",
					error: true
				}]
			});
			commit("setIssuesResponseLoadingStatus", { status: "RESPONSE_FAILED" });
		});
};

export default {
	loadProducts,
	getOrder,
	submitIssues,
};

import regeneratorRuntime from "regenerator-runtime";
import "objectFitPolyfill";
import Vue from "vue";
import { store } from "./vue/store";
import { accountModule } from "../lodge-vue/src/store/account";
import { initSentry } from "./utils";
import lazysizes from "lazysizes";
import userClassifier, { CLASSIFIER_TYPES } from "../js/classifier";
import { stripMoneySign } from "../js/classifier/classifierUtils";

initSentry(Vue);
lazysizes.cfg.customMedia = {
	extra_small: "",
	small: "all and (min-width: 480px)",
	medium: "all and (min-width: 768px)",
	wide: "all and (min-width: 992px)",
	extra_wide: "all and (min-width: 1200px)",
};
import "./search.js";

import QuizApp from "./vue/components/Quiz";
import {
	sfraMutationObserverForPaypal,
	sfraQuickAddCartEvents,
	sfraRemoveFromCartEvents,
	sfraUpdateCartQuantity,
} from "./analytics";

import BookmarkApp from "./vue/components/Bookmark";
import "../templates/main-menu";
import { qs, qsa } from "./utils";
import WizardApp from "./vue/components/Wizard";
import ProductHelpApp from "./vue/components/ProductHelp";
import CartIcon from './vue/components/CartIcon';
Vue.config.ignoredElements = ["stream"];

Vue.component("Quiz", QuizApp);
Vue.component("Bookmark", BookmarkApp);
Vue.component("Wizard", WizardApp);
Vue.component("ProductHelp", ProductHelpApp);
Vue.component("CartIcon", CartIcon);

async function init() {
	try {
		await accountModule.initAccount();
		let vueComponents = [...document.querySelectorAll(".vue-component")];
		vueComponents.forEach((c) => {
			new Vue({
				el: c,
				store,
			});
		});
	} catch (_) {
		console.log("failed to init the account");
	}
}

// init the account so that removing recipe bookmarks will work
let initPromise = init();

if (window.location.pathname === "/cart") {
	sfraMutationObserverForPaypal();
	sfraQuickAddCartEvents();
	sfraRemoveFromCartEvents();
	sfraUpdateCartQuantity();
}

if (window.location.pathname === "/checkout") {
	// this event is fired by salesforce checkout js
	// when place order has succeeded and before page redirects
	console.log("setting up account reset");
	window.resetShopSiteAccount = accountModule.resetAccount;
}

// const cartIcon = document.querySelector(".salesforce-cart");
// cartIcon &&
// 	cartIcon.addEventListener("click", (e) => {
// 		window.location.href = "/cart";
// 	});

// Handles all of the classifier functionality
userClassifier.addDataPoint({
	type: CLASSIFIER_TYPES.PAGE_VIEW,
	data: {
		path: location.pathname,
		queryString: location.search,
	},
});
if (window.sfcc && window.sfcc.order) {
	const { order } = window.sfcc;
	userClassifier.addDataPoint({
		type: CLASSIFIER_TYPES.ECOM_PURCHASE,
		data: {
			units: order.itemsTotalQuantity,
			price: stripMoneySign(order.totals.grandTotal),
		},
	});
}

// add 'shown' class to SF cart level promo header
if (qs(".promo--top")) {
	[...qsa(".promo--top p")].forEach((el) => el.classList.add("shown"));
}
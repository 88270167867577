//http://lodgemfgode3.prod.acquia-sites.com/api/lodge/salesforce/products

/**
 * @type {import("vuex").MutationTree<ProductListingState>}
 */
const Mutations = {
	/**
	 * @param {Product[]} payload
	 */
	recipes(state, {recipes, total}) {
		state.recipes = recipes;
		state.recipeTotal = total;
	},
	/**
	 * @param {LoadingState} payload
	 */
	loadingState(state, payload) {
		state.loadingState = payload;
	},

	setFilterText(state, payload) {
		state.filterText = payload.text;
		state.recipes = [];
		state.navigatedRecipe = "";
	},

	/**
	 *
	 * @param {String[]} payload
	 */
	clearFilters(state) {
		state.filterText = "";
	},
	incrementPage(state) {
		state.page = state.page + 1;
	},
	setPage(state, payload) {
		state.page = payload;
	},
	resetPage(state) {
		state.page = 1;
	},
	setNavigatedRecipe(state, payload ) {
		state.navigatedRecipe = payload;
	}
};

export default Mutations;
export const Commit = {
	/**
	 *
	 * @param {import("vuex").Commit} commit
	 * @param {Recipe[]} payload
	 */
	recipes(commit, payload) {
		commit("recipes", payload);
	},
	/**
	 *
	 * @param {import("vuex").Commit} commit
	 * @param {LoadingState} payload
	 */
	loadingState(commit, payload) {
		commit("loadingState", payload);
	},

	/**
	 * @param {import("vuex").Commit} commit
	 */
	incrementPage(commit) {
		commit("incrementPage");
	}
};

export default {
	namespaced: true,
	state: {
		likedIDs: [],
		loggedIn: true,
	},
	mutations: {
		// need to make a mutation to remove from like
		// need to make a mutation to add from like
		// login
		// logout
	},
	actions: {
		// 1. need to make an action to send off the JSON request
		// that will then call the mutation
		// maybe need to save failures in localStorage
		// and retry again on next page load / next request?
		// 2. login stuff
		// utilize whatever Andre has for carting, probably?
	},
};

export default {
	getFetchingData(state) {
		return state.fetchingData;
	},
	getProducts(state) {
		return state.products;
	},
	getLoadingState(state) {
		return state.loadingState;
	},
};

<template>
  <div class="product-finder col-xs-1-1 frow column-start">
    <div v-if="productsLoadingStatus === 'PRODUCTS_LOADING'">
      Loading products...
      <div class="lds-ring black">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="col-xs-12" v-else-if="products.length">
      <div class="frow justify-between column-start items-stretch col-xs-12" v-if="!showCustomProductForm">
        <label for="search">Enter product name or SKU</label>
        <input ref="product-help-product-search" type="text" name="product-help-product-search"
               id="product-help-product-search" v-model="search"
               @input="findProduct"
               @focus="findProduct"
               @keyup.enter="selectHighlighted"
               @keydown.esc.exact="hideDropdown"
               @keydown.shift.tab="hideDropdown"
               @keydown.up.exact.prevent="startArrowKeys"
               @keydown.down.exact.prevent="startArrowKeys"

        />
        <div ref="dropdown" class="product-choices col-xs-1-1 mt-3">
          <template v-for="(productChoice, i) in productChoices">
            <div
                v-if="i === 0"
                :key="productChoice.id"
                @click="chooseProduct(productChoice)"
                tabindex="0"
                @keydown.up.exact.prevent=""
                @keydown.tab.exact="focusNext(false)"
                @keydown.down.exact.prevent="focusNext(true)"
                @keydown.esc.exact="hideDropdown"
                @keyup.enter.prevent="chooseProduct(productChoice, true)"
                class="frow row-start product-choice"
                :class="{active: productChoice.id === product.id}">
              <div><img :src="productChoice.image.url" :alt="productChoice.image.alt || productChoice.name"></div>
              <div class="detail"><strong>{{ productChoice.id }}</strong><br>{{ productChoice.name }}</div>
            </div>
            <div
                v-else-if="i === productChoices.length - 1"
                :key="productChoice.id"
                tabindex="0"
                @click="chooseProduct(productChoice)"
                @keydown.shift.tab="focusPrevious(false)"
                @keydown.up.exact.prevent="focusPrevious(true)"
                @keydown.down.exact.prevent=""
                @keydown.tab.exact="hideDropdown"
                @keydown.esc.exact="hideDropdown"
                @keyup.enter.prevent="chooseProduct(productChoice, true)"
                class="frow row-start product-choice"
                :class="{active: productChoice.id === product.id}">
              <div><img :src="productChoice.image.url" :alt="productChoice.image.alt || productChoice.name"></div>
              <div class="detail"><strong>{{ productChoice.id }}</strong><br>{{ productChoice.name }}</div>
            </div>
            <div
                v-else
                :key="productChoice.id"
                tabindex="0"
                @click="chooseProduct(productChoice)"
                @keydown.tab.exact="focusNext(false)"
                @keydown.shift.tab="focusPrevious(false)"
                @keydown.up.exact.prevent="focusPrevious(true)"
                @keydown.down.exact.prevent="focusNext(true)"
                @keydown.esc.exact="hideDropdown"
                @keyup.enter.prevent="chooseProduct(productChoice, true)"
                class="frow row-start product-choice"
                :class="{active: productChoice.id === product.id}">
              <div><img :src="productChoice.image.url" :alt="productChoice.image.alt || productChoice.name"></div>
              <div class="detail"><strong>{{ productChoice.id }}</strong><br>{{ productChoice.name }}</div>
            </div>
          </template>
        </div>
        <div class="frow col-xs-12 justify-end mt-4" v-show="!showCustomProductForm">
          <button class="btn--text-link" href="#" @click.prevent="showCustomProductForm = true">Don't see your
            product?</button>
        </div>
      </div>
      <div v-show="showCustomProductForm" class="frow column-start items-stretch">
        <p>Enter the name or SKU of your product and select a material</p>
        <div class="floating-label--wrap">
          <input class="floating-label--field" v-model="customProduct" ref="product-chooser-custom-product" type="text" placeholder="Name or SKU">
          <label class="floating-label" for="order-email">Name or SKU (required)</label>
        </div>
        <select v-model="customMaterial" ref="product-chooser-custom-material" class="mt-4" name="material"
                id="material">
          <option value="default">Choose a category (required)</option>
          <option v-for="material in materialOptions" :key="material" :value="material">{{ material }}</option>
        </select>
        <div class="frow col-xs-12 justify-end mt-4">
          <button class="btn--text-link" href="#" @click.prevent="showCustomProductForm = false">Want to search for your
            product?</button>
        </div>
      </div>
    </div>
    <div class="error" v-else>
      Sorry, there was a problem getting the products. Please reload the page and try again.
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { debounce } from "../../../utils";
import mixins from './mixins';
import Vue from 'vue';

export default {
  name: "ProductChooser",
  mixins: [mixins],
  props: [
    "node",
  ],
  data() {
    return {
      product: {},
      productName: "",
      productChoices: [],
      search: "",
      isVisible: false,
      focusedIndex: 0,
      showCustomProductForm: false,
      customProduct: "",
      customMaterial: "default",
    };
  },
  computed: {
    ...mapGetters({
      products: "productHelp/getProducts",
      materialOptions: "productHelp/getMaterialOptions",
      productsLoadingStatus: "productHelp/getProductsLoadingStatus",
      currentIssueId: "productHelp/getCurrentIssueId",
    }),
    customProductChosen() {
      return !!this.customProduct && this.customMaterial !== "default";
    },
  },
  watch: {
    customProductChosen() {
      let p = this.customProduct;
      let m = this.customMaterial;
      this.chooseProduct({
        name: p,
        material: m,
      });
    },
    showCustomProductForm(v) {
      if (v) {
        this.$nextTick(() => this.$refs["product-chooser-custom-product"].focus());
      } else {
        this.$nextTick(() => this.$refs["product-help-product-search"].focus());
      }
    }
  },
  methods: {
    ...mapActions({
      loadProducts: "productHelp/loadProducts",
    }),
    ...mapMutations({
      setAnswer: "productHelp/setAnswer",
      setFollowingStep: "productHelp/setFollowingStep",
      setIssueProduct: "productHelp/setIssueProduct",
      setNextFocus: "productHelp/setNextFocus",
    }),
    findProduct: debounce(function (e) {
      let search = e.target.value.toLowerCase();
      this.productChoices = search.length > 2
          ? this.products.filter(p => {
            return p.name.toLowerCase().includes(search)
                || p.id.toLowerCase().includes(search);
          })
          : [];

    }, 300, false),
    chooseProduct(choice, go) {
      this.product = choice;
      this.setIssueProduct({
        product: choice,
      });
      this.setAnswer({
        id: "material",
        answer: this.getMaterial(choice),
      });
      //this.productChoices = [];
      this.setFollowingStep();

      // this handles the case where user hits enter on the product
      // and moves them forward automatically
      if (go) {
        this.setNextFocus({
          focus: true,
        });
      }

    },
    setCustomProduct(e) {
      let p = this.$refs["product-chooser-custom-product"].value;
      let m = this.$refs["product-chooser-custom-material"].value;
      this.chooseProduct({
        name: p,
        material: m,
      });
    },
    selectHighlighted(e) {
      e.preventDefault();
    },
    // toggleVisibility() {
    //   this.isVisible = !this.isVisible;
    // },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    startArrowKeys() {
      if (this.productChoices.length) {
        console.log("start arrow keys", this.$refs.dropdown.children);
        // this.$refs.account.focus()
        this.$refs.dropdown.children[0].focus();
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex = this.focusedIndex - 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex = this.focusedIndex + 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusItem() {
      this.$refs.dropdown.children[this.focusedIndex].focus();
    },
  },
  mounted() {
    // get products for filter
    // unless already available
    if (this.products.length === 0) {
      this.loadProducts().then(() => {
        this.$refs["product-help-product-search"].focus();
      });
    } else {
      this.$refs["product-help-product-search"].focus();
    }
  },
};
</script>

<style scoped>

</style>

class LodgeStorage {
	constructor(type) {
		this.exists = window[type] ? true : false;
		if (this.exists) {
			this.storage = window[type];
		}
	}

	/**
	 *
	 * @param {string} key
	 */
	get(key) {
		if (this.exists) {
			const item = this.storage.getItem(`lodge_${key}`);
			return item ? JSON.parse(item) : null;
		}
	}

	/**
	 *
	 * @param {key} key has lodge_ prepended to it
	 */
	set(key, data) {
		if (this.exists) {
			this.storage.setItem(`lodge_${key}`, JSON.stringify(data));
		}
	}
}

export const lodgeSessionStorage = new LodgeStorage("sessionStorage");
export const lodgeLocalStorage = new LodgeStorage("localStorage");

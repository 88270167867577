<template>
	<div class="product-wizard--recommended-item">
		<div class="small-imagery">
			<img :src="product.images[0]" :alt="`Image of ${product.name}`"/>
			<span
				v-if="!product.available"
				class="size-small bg-brand-primary font-family-roboto-slab"
				>Out of stock</span
			>
		</div>
		<div class="recommended-content">
			<div>
				<h3 class="h7">{{ product.name }}</h3>
				<span class="color--secondary size-os-large">${{ product.price }}</span>
				<p class="size-small" v-html="product.description"></p>
				<p class="size-small">Benefits</p>
				<div class="size-small" v-html="product.features"></div>
			</div>
			<div>
				<a
					class="btn borderless btn--border btn--arrow btn--primary"
					:href="`/product/${product.masterId}`"
					>View product</a
				>
				<AddToCart
					v-if="product.available"
					classes="btn btn-recommended-cart btn--link color--secondary"
					:sku="product.sku"
					:quantity="1"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import AddToCart from "../../../../lodge-vue/src/AddToCart.vue";
export default {
	components: {
		AddToCart,
	},
	props: {
		topChoice: {
			type: Boolean,
			default: false,
		},
		product: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {},
};
</script>

import Vue from "vue";
import Component from "vue-class-component";

let accountModule = require("../store/account").accountModule;

export function AddAsyncUpdates(property: string) {
	return function(target: Vue, key: string, descriptor: any) {
		const original = descriptor.value;
		if (typeof original === "function") {
			// console.log(target, key, descriptor);
			descriptor.value = async function(...args: any[]) {
				const boundOriginal = original.bind(this);
				console.log("before", key);
				this[property] = true;
				await boundOriginal(...args);
				this[property] = false;
				console.log("after", key);
			};
		}
		return descriptor;
	};
}

/**
 * Method to get value from an object by the path
 * @param obj {Object} an object
 * @param path {string} path to the value in the object
 */
export function getByPath(obj: any, path: string) {
	return path.split(".").reduce((acc, curr) => {
		return acc && acc[curr];
	}, obj);
}

/**
 * Method to dedupe an array of objects using a path to the key to dedupe by
 * @param arr {[Object]} Array of Objects
 * @param path {string} Path to the key to dedupe array of objects by
 */
export function deduplicateObjectsByKey<T>(arr: T[], path: string) {
	var hashTable: any = {};

	return arr.filter(function(el) {
		var key = getByPath(el, path);
		var match = Boolean(hashTable[key]);

		return match ? false : (hashTable[key] = true);
	});
}

// You can declare a mixin as the same style as components.
@Component({
	filters: {
		dollars(p: number) {
			return typeof p === "number" ? `$${p.toFixed(2)}` : "";
		},
		pluralize(s: string, n: number) {
			return `${s}${n === 0 || n > 1 ? "s" : ""}`;
		},
	},
})
export default class Helpers extends Vue {
	accountModule = accountModule;
}

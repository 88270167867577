export default {
	csrfToken: "",
	captchaToken: "",
	uploadsComplete: true,
	nextStep: "",
	currentStep: "",
	products: [],
	materialOptions: [
		"Seasoned Cast Iron",
		"Carbon Steel",
		"Enameled Cast Iron",
		"Accessories"
	],
	order: {
		orderCreationDate: "",
		orderNumber: "",
		orderEmail: "",
		orderPhone: "",
		orderName: "",
		orderAddress1: "",
		orderAddress2: "",
		orderCity: "",
		orderState: "",
		orderZip: "",
		orderCountry: "",
		purchaseLocation: "",
		gift: false,
		products: []
	},
	productsLoadingStatus: "PRODUCTS_LOADING",
	orderLoadingStatus: "",
	issuesResponseLoadingStatus: "RESPONSE_LOADING",
	currentIssueId: 0,
	issues: {},
	path: [],
	tree: {},
	branch: {},
	// request: {},
	issueTemplate: {
		requestNumber: 0,
		caseRecordType: "",
		type: "",
		subType: "",
		requestedRemedy: "",
		remedyAmount: "",
		photos: [],
		product: {
			"sku": "",
			"quantity": 0,
			"material": "",
			"damagedBox": "",
			"used": "",
			"unitPrice": ""
		},
		missingParts: "",
		answers: {},
		notes: "",
		resolvedFlag: false,
	},
	request: {
		"captchaToken": "",
		"name": "",
		"email": "",
		"phone": "",
		"address1": "",
		"address2": "",
		"city": "",
		"state": "",
		"zipcode": "",
		"country": "",
		"updateContact": false,
		"order": {
			"orderNumber": "",
			"orderEmail": "",
			"orderPhone": "",
			"orderName": "",
			"orderAddress1": "",
			"orderAddress2": "",
			"orderCity": "",
			"orderState": "",
			"orderZip": "",
			"orderCountry": "",
			"purchaseLocation": "",
			"gift": false,
		},
		"issues": [
			{
				"requestNumber": 0,
				"caseRecordType": "",
				"type": "",
				"subType": "",
				"requestedRemedy": "",
				"remedyAmount": "",
				"photos": [],
				"product": {
					"sku": "",
					"quantity": 0,
					"material": "",
					"damagedBox": "",
					"used": "",
				},
				"notes": "",
				"resolvedFlag": false,
			},
		],
	},
	issuesResponse: null,
	flowComplete: false,
	nextFocus: false,
	resolvedFlag: false
};

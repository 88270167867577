<template>
	<div>
		<input class="quiz-checkbox" :id="id" v-model="value" type="checkbox" />
		<label
			tabindex="0"
			@keyup.enter="onEnterKeyUp"
			class="btn btn--transparent"
			:for="id"
			>{{ btn.label }}</label
		>
	</div>
</template>

<script>
export default {
	props: ["btn"],
	data() {
		return {
			value: false,
		};
	},
	watch: {
		value(nV, oV) {
			console.log(this.btn, 24);
			this.$emit("selected", { btn: this.btn, newValue: nV });
		},
	},
	methods: {
		onEnterKeyUp() {
			this.value = !this.value;
		},
	},
	computed: {
		id() {
			return this._uid;
		},
	},
};
</script>
<script>
import Vue from "vue";
import { gtmCustomEventPush } from "../../../analytics";

export default {
	props: ["quiz"],
	data() {
		return {
			current: 0,
			totalPages: -1,
			choices: [],
		};
	},
	render() {
		return this.$scopedSlots.default({
			pages: this.quiz.pages,
			current: this.current,
			choices: this.choices,
			choicesResults: this.choicesResults,
			movement: {
				next: this.goNext,
				prev: this.goPrev,
			},
		});
	},
	watch: {
		current(pg) {
			if (pg === this.totalPages - 1) {
				Vue.nextTick(() => {
					this.$emit("finished");
				});
			}
		},
	},
	computed: {
		currentPages() {
			return this.quiz.pages[this.current];
		},
		choicesResults() {
			const { choices } = this;
			let total = 0;
			choices.forEach(pageChoices => {
				console.log(60, pageChoices);
				total += pageChoices.selections.reduce(
					(acc, curr) => (acc += curr.value),
					0,
				);
			});
			console.log(total);
			return total;
		},
	},
	methods: {
		handlePagination(page) {
			console.log(24, page);
			if (page >= 0 && page < this.totalPages) {
				this.current = page;
			}
		},
		updateChoices(data) {
			const choicesLength = this.choices.length;
			if (this.current + 1 >= choicesLength) {
				Vue.set(this.choices, this.current, data);
			} else {
				Vue.set(this.choices, this.current, data);
			}
		},
		handleSelection(data) {},
		goNext(data) {
			console.log("data at quiz container level:", data, 35);
			this.updateChoices(data);
			this.handlePagination(this.current + 1);
			gtmCustomEventPush({
				event: "liftData",
				category: "Interaction",
				label: "Miniwizard Quiz",
				value: this.choicesResults,
				extraData: {
					step: this.current,
				},
			});
		},
		goPrev() {
			this.handlePagination(this.current - 1);
		},
	},
	created() {
		this.$on("next", this.goNext);
		this.$on("prev", this.goBack);
		this.$on("selection", this.handleSelection);
		this.totalPages = this.quiz.pages && this.quiz.pages.length;
	},
};
</script>